import React from "react";
import './homeStyle.css'
import plus from "../../assets/logoAndIcons/plus.png"
import minus from "../../assets/logoAndIcons/minus.png"
import {Form} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
// import DatePicker from 'react-datepicker2';
import momentJalaali from 'moment-jalaali'
import Transaction from "../Transaction/Transaction";
// import CurrencyFormat from 'react-currency-format';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from "react-modern-calendar-datepicker";


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: this.props.token,
            incomeCategories: [],
            expenseCategories: [],
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            loading: false,
            amount: '',
            day: momentJalaali().jDate(),
            month: (momentJalaali().jMonth() + 1),
            year: momentJalaali().jYear(),
            description: '',
            category: '',
            incomeCategory: '',
            expenseCategory: '',
            date:  {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            today:  {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            // persianDate: '',
            amountError: '',
            isIncomeOpen: false,
            isExpenseOpen: false,
            regexp: /^[0-9\b]+$/,
            today_income: 0,
            today_outcome: 0,
        }

    }

    componentDidMount() {
        this.getInfo();
    }

    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }

    getInfo = () => {
        const {token, accessToken} = this.state;
        const api = 'https://api.molino.ir/Site/getInfo?';
        this.setState({loading: true}, () => {
            axios.get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            loading: false,
                        })
                        if (res.data.result === true) {

                            this.setState({
                                incomeCategories: res.data.incom_categories,
                                incomeCategory: res.data.incom_categories[0],
                                expenseCategories: res.data.outcom_categories,
                                expenseCategory: res.data.outcom_categories[0],
                                today_income: res.data.today_incom,
                                today_outcome: res.data.today_outcom,
                            })

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }

                        }
                    })
                // })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            amountError: ''
        });
    }
    validateValue = (value) => {
        // alert(value)
        this.setState({
            amount: value
        })
    }
    submitForm = (e) => {
        e.preventDefault();
    };

    validateForm = () => {
        let isValid = true;
        if (this.state.amount === '') {
            this.setState({
                amountError: "مبلغ را وارد کنید."
            })
            isValid = false;
        }
        return isValid;
    }
    transaction = (e) => {
        const {loading} = this.state;
        if (this.validateForm() && !loading) {

            const {
                accessToken, token, incomeCategory, expenseCategory, description, amount,
                today_income, today_outcome, date, today
            } = this.state;
            const category = e ? incomeCategory : expenseCategory;
            const api = "https://api.molino.ir/Site/AddTransaction";
            let body = {
                isIncom: e,
                category: category,
                description: description,
                value: amount,
                day: date.day,
                month: date.month,
                year: date.year,
            };
            this.setState({loading: true}, () => {
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                loading: false,
                            })
                            if (res.data.result === true) {
                                if (e) {
                                    if (date === today)
                                        this.setState({
                                            today_income: today_income + parseInt(amount),
                                        })
                                } else {
                                    if (date === today)
                                        this.setState({
                                            today_outcome: today_outcome + parseInt(amount)
                                        })
                                }


                            } else {
                                if (res.data.message === "relogin") {
                                    this.handleCallbackReLogin();
                                } else if (res.data.message === "charge") {
                                    this.handleCallbackCharge()
                                }
                                // this.setState({
                                //     message: res.data.message
                                // })
                                alert(res.data.message)
                            }

                        }
                    )
                    .catch(function (error) {
                        console.log(error);
                    });

            })
        }
    }
    incomeTransaction = () => {
        this.transaction(true);
    }
    expenseTransaction = () => {
        this.transaction(false);
    }
    resetForm = () => {
        this.setState({
            amount: '',
            incomeCategory: this.state.incomeCategories[0],
            expenseCategory: this.state.expenseCategories[0],
            description: '',
            date:  {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            day: momentJalaali().jDate(),
            month: (momentJalaali().jMonth() + 1),
            year: momentJalaali().jYear(),
        })
    }
    pageCallbackToMC = (page, incomeCat, expenseCat, isIncome) => {
        this.props.parentCallbackToMC(page, incomeCat, expenseCat, isIncome);

    }

    manageIncomeCat = () => {
        this.pageCallbackToMC("manageCat", this.state.incomeCategories, this.state.expenseCategories, true);
    }
    manageExpenseCat = () => {
        this.pageCallbackToMC("manageCat", this.state.incomeCategories, this.state.expenseCategories, false);
    }

    render() {
        const {loading, amount} = this.state;
        const {today_income, today_outcome} = this.state;
        const {currency} = this.props;

        return (
            <div className={"homeRoot"}>
                <div className={"homeHeader"}>
                    <div className={"homeBox"}>
                        <h4>دریافتی امروز</h4>
                        <p style={{color: 'green'}}>
                            {
                                /*{(repeatedI === 0) ? */
                                today_income.toLocaleString()
                                /*: today_income.toLocaleString()}*/
                            }
                            &nbsp;{currency}</p>
                    </div>

                    <div className={"homeBox"}>
                        <h4>خرج امروز</h4>
                        <p style={{color: 'red'}}>
                            {
                                /*{(repeatedE === 0) ? */
                                today_outcome.toLocaleString()
                                // : today_outcome.toLocaleString()
                            }
                            &nbsp;{currency}</p>
                    </div>

                </div>

                <div className={"homeBody"}>
                    <button className={"add"}
                            onClick={Transaction.open('new-income')}
                    >
                        <img className={"addIcon"} src={plus}/>
                        <p>دریافتی جدید</p>
                    </button>
                    <button className={"minus"}
                            onClick={Transaction.open('new-cost')}
                    >
                        <img className={"minusIcon"} src={minus}/>
                        <p>خرج جدید</p>
                    </button>

                </div>
                <div>
                    {loading ? <div className={"homeSpinner"}>


                            <div className={"spinnerC"}
                                // style={{ marginTop: '50',marginBottom:'auto', padding:'50'}}
                            >
                                <Loader
                                    type="TailSpin" color="#fff" height="100"
                                    width="100"/>
                            </div>
                            {/*<Loader className={"spinnerH"} type="Circles" color="#ecec44" height="100"*/}
                            {/*        width="100"/>*/}
                        </div>
                        : ''}
                </div>

                <Transaction id="new-income" func={this.incomeTransaction}
                             loading={loading} resetFunc={this.resetForm}
                             amount={amount} changeCategories={this.manageIncomeCat}>
                    <p className={"homeTransactionTitle"}>افزودن دریافتی جدید</p>
                    <Form className={"homeForm"} id={"signInForm"}
                          onSubmit={this.submitForm}>
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}
                                        style={{marginTop: "auto", marginBottom: "auto"}}
                            > مبلغ: </Form.Label>

                            <CurrencyInput
                                className={"homeInput"}
                                id="input-example"
                                name="amount"
                                style={{flex: 4, direction: "ltr"}}
                                placeholder={"300,000"}
                                value={amount}
                                onChange={this.validateValue}
                                allowDecimals={false}
                                allowNegativeValue={false}
                            />
                            <p className={"homeLabel currency"}>
                                &nbsp;&nbsp;&nbsp;{currency}</p>


                        </Form.Group>
                        <div className="homeErrorMsg" style={{marginBottom: 5}}>
                            {this.state.amountError}
                        </div>


                        <Form.Group className={'formGroup'}>

                            <Form.Label className={"homeLabel"}>دسته:</Form.Label>

                            <Form.Control className={"homeInput"} as="select"
                                          name="incomeCategory"
                                          onChange={this.handleChange}
                                          value={this.state.incomeCategory}
                                          disabled={loading}
                                          style={{flex: 4}}>
                                {this.state.incomeCategories.map(incomeCategory =>
                                    <option className={"homeOptions"}>{incomeCategory}</option>
                                )}

                            </Form.Control>
                            <button className={"manageButton"}
                                    onClick={Transaction.changeCategories('new-income')}
                            >مدیریت دسته ها
                            </button>
                        </Form.Group>
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}>توضیح:</Form.Label>
                            <Form.Control className={"homeInput textArea"} as="textarea" rows={3}
                                          placeholder={"به عنوان مثال: حقوق ماه مهر یا..."}
                                          name="description"
                                          value={this.state.description}
                                          onChange={this.handleChange}
                                          disabled={loading}
                            />
                        </Form.Group>
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}>تاریخ:</Form.Label>
                            <div className={"date"}>
                                <DatePicker
                                    value={this.state.date}
                                    onChange={date => this.setState({
                                        date: date,
                                        dateError: ''
                                    })}
                                    shouldHighlightWeekends
                                    locale="fa" // add this
                                    calendarClassName="responsive-calendar"
                                    // styles={{zIndex:1000}}
                                />
                                {/*<DatePicker*/}
                                {/*    // styles={myStyle}*/}
                                {/*    calendarClass={"calendarClass"}*/}
                                {/*    className={"homeInput"}*/}
                                {/*    // datePickerClass={"datePickerClass"}*/}
                                {/*    // styles= { {calendarContainer: "width:90%" }}*/}
                                {/*    // calendarContainer={"90%"}*/}
                                {/*    timePicker={false}*/}
                                {/*    onChange={date => this.setState({*/}
                                {/*            year: momentJalaali(date).jYear(),*/}
                                {/*            month: (momentJalaali(date).jMonth() + 1),*/}
                                {/*            day: momentJalaali(date).jDate(),*/}
                                {/*        }*/}
                                {/*    )}*/}
                                {/*    value={this.state.date}*/}
                                {/*    isGregorian={false}/>*/}
                            </div>
                        </Form.Group>

                        <button
                            // disabled={loading}
                            type="submit"
                            onClick={Transaction.confirm('new-income')}
                            className={loading ? "homeButton inactiveHomeButton" : "homeButton"}
                        >
                            تایید
                        </button>
                        <button className={"homeButton backgroundRed"}
                                onClick={Transaction.close('new-income')}>انصراف
                        </button>
                        <br/>

                    </Form>


                </Transaction>


                <Transaction id="new-cost" func={this.expenseTransaction}
                             loading={loading} resetFunc={this.resetForm}
                             amount={amount} changeCategories={this.manageExpenseCat}>
                    <h1>افزودن خرج جدید</h1>
                    <Form className={"homeForm"} id={"signInForm"}
                          onSubmit={this.submitForm}>
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}> مبلغ: </Form.Label>
                            <CurrencyInput
                                className={"homeInput"}
                                id="input-example"
                                name="amount"
                                style={{flex: 4, direction: "ltr"}}
                                placeholder={"300,000"}
                                value={amount}
                                onChange={this.validateValue}
                                allowDecimals={false}
                            />
                            <p className={"homeLabel"}
                               style={{marginRight: "2%"}}>
                                {currency}</p>


                        </Form.Group>
                        <div className="homeErrorMsg">
                            {this.state.amountError}
                        </div>


                        <Form.Group className={'formGroup'}>

                            <Form.Label className={"homeLabel"}>دسته:</Form.Label>

                            <Form.Control className={"homeInput"} as="select"
                                          name="expenseCategory"
                                          value={this.state.expenseCategory}
                                          onChange={this.handleChange}
                                          disabled={loading}>
                                {this.state.expenseCategories.map(expenseCategory =>
                                    <option>{expenseCategory}</option>
                                )}

                            </Form.Control>
                            <button className={"manageButton"}
                                    onClick={Transaction.changeCategories('new-cost')}
                            >مدیریت دسته ها
                            </button>
                        </Form.Group>
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}>توضیحات:</Form.Label>
                            <Form.Control className={"homeInput textArea"} as="textarea" rows={3}
                                          placeholder={"به عنوان مثال: حقوق ماه مهر یا..."}
                                          name="description"
                                          value={this.state.description}
                                          onChange={this.handleChange}
                                          disabled={loading}/>
                        </Form.Group>

                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}>تاریخ:</Form.Label>
                            <div className={"date"}>
                                <DatePicker
                                    value={this.state.date}
                                    onChange={date => this.setState({
                                        date: date,
                                        dateError: ''
                                    })}
                                    shouldHighlightWeekends
                                    locale="fa" // add this
                                    calendarClassName="responsive-calendar"
                                    // styles={{zIndex:1000}}
                                />
                                {/*<DatePicker*/}
                                {/*    className={"homeInput"}*/}
                                {/*    timePicker={false}*/}
                                {/*    onChange={date => this.setState({*/}
                                {/*            year: momentJalaali(date).jYear(),*/}
                                {/*            month: (momentJalaali(date).jMonth() + 1),*/}
                                {/*            day: momentJalaali(date).jDate(),*/}
                                {/*        }*/}
                                {/*    )}*/}
                                {/*    value={this.state.date}*/}
                                {/*    isGregorian={false}/>*/}
                            </div>
                        </Form.Group>

                        <button
                            disabled={loading}
                            type="submit"
                            onClick={Transaction.confirm('new-cost')}
                            className={loading ? "homeButton inactiveHomeButton" : "homeButton"}
                        >
                            تایید
                        </button>
                        <button className={"homeButton backgroundRed"}
                                onClick={Transaction.close('new-cost')}>انصراف
                        </button>
                        <br/>

                    </Form>


                </Transaction>


            </div>
        )
    }

}

export default Home;