import React from 'react';
import './signBoxStyle.css';
import 'react-bootstrap';
import NameForm from "../NameForm/NameForm";
import PhoneNumForm from "../PhoneNumForm/PhoneNumForm";
import CheckCodeForm from "../CheckCodeForm/CheckCodeForm";

class SignBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneNum: '',
            form: 1,
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            token: '',

        }

    };

    handleCallbackPhoneNum = (childData1,childData2) =>{
        this.setState({
            phoneNum: childData1,
            form: childData2
        })
    }
    handleCallbackChCode = (childData1,childData2) =>{
        this.setState({
            token: childData1,
            form: childData2
        })
    }

    render() {
        return (
            <div className={"signBoxRoot"}>

                <div
                    className={"text1"}> ورود/ثبت نام:
                </div>

                <div>
                    {this.state.form === 1 ? <PhoneNumForm
                        accessToken={this.state.accessToken}
                        parentCallbackPhoneNum={this.handleCallbackPhoneNum}
                    /> : ''}
                </div>

                <div>
                    {this.state.form === 2 ? <CheckCodeForm
                        accessToken={this.state.accessToken}
                        parentCallbackChCode ={this.handleCallbackChCode}
                        phoneNum={this.state.phoneNum}
                        history={this.props.history}
                    /> : ''}
                </div>

                <div>
                    {this.state.form === 3 ? <NameForm
                        accessToken={this.state.accessToken}
                        token={this.state.token}
                        history={this.props.history}
                    /> : ''}
                </div>
            </div>
        )
    }
}

export default SignBox;
