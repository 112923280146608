import React from 'react';
import './checkCodeFormStyle.css';
import 'react-bootstrap';
import {Form} from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";
import Timer from "react-compound-timer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const initTime=59000;

class CheckCodeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkCode: '',
            checkCodeError: '',
            token: '',
            currency: '',
            loading: false,
            open: false,
            form: 1,
            time:initTime,
            restTimer:false,
            timer:true,
            regexp: /^[0-9\b]+$/

        }
    };


    submitForm = (e) => {
        e.preventDefault();
    };

    handleChange = (e) => {
       let newCode = e.target.value;
        if (newCode==='' || this.state.regexp.test(newCode)) {
            this.setState({
                [e.target.name]:newCode,
                checkCodeError: ''
            })
        }
    }


    //CheckCode
    validateCheckForm = () => {
        let formIsValid = true;
        let checkCodeError = '';
        const {checkCode} = this.state;
        if (checkCode === '') {
            checkCodeError = "لطفا کد را وارد کنید";
            formIsValid = false;
        }else if (checkCode.length < 4) {
            formIsValid = false;
            checkCodeError = "لطفا کد را به درستی وارد کنید";
        }

        if (/[a-zA-z]/.test(checkCode)) {
            formIsValid = false;
            checkCodeError = "لطفا کد را به درستی وارد کنید";
        }
        if (!formIsValid) {
            this.setState(
                {
                    checkCode: '',
                    checkCodeError: checkCodeError

                }
            )
        }
        return formIsValid;
    }
    checkCode = () => {
        const {loading} = this.state;

        if (this.validateCheckForm() && !loading) {
            const {phoneNum, accessToken} = this.props;
            const {checkCode} = this.state;
            const apiCheckCode = "https://api.molino.ir/Site/checkCode?phone=" + phoneNum + "&code=" + checkCode;
            this.setState({loading: true, timer:false}, () => {
                axios.get(apiCheckCode,
                    {
                        headers: {
                            'accessToken': accessToken
                        }
                    })
                    .then(
                        res => {
                            // alert(res.data.result + " ," + res.data.message);
                            this.setState({
                                loading: false,
                                timer:true
                            })
                            if (res.data.result === true) {
                                this.setState({
                                    token: res.data.token,
                                    currency: res.data.currency

                                })
                                localStorage.setItem('token', this.state.token);
                                localStorage.setItem('currency', this.state.currency);
                                if (res.data.message.includes('login')) {
                                    this.props.history.replace({
                                            pathname: '/profile'
                                        }
                                    )
                                } else {
                                    this.setState({
                                        form: 3
                                    })
                                    this.CallbackChCode();
                                }
                            } else {
                                this.setState({
                                    checkCode: '',
                                    checkCodeError: res.data.message
                                })

                            }
                        })

                    .catch(function (error) {
                        console.log(error);
                    });
            })


        }

    }

    CallbackChCode = () => {
        this.props.parentCallbackChCode(this.state.token, this.state.form);
        // e.preventDefault();
    }


    handleClickOpen = () => {
        this.setState({
            open: true
        })

    };
    handleClose = () => {

        // this.setState({
        //     open: false
        // })
        this.CallbackChCode();
    };
    resetTime=()=>{
        this.setState({
            // open: false,
            time: initTime,
        })
    }
    resendCode = () => {

        this.setState({
            open: false,
            // time: 20000,
            resetTimer: true
        })
        // this.resetTime()


        //////
        const {phoneNum, accessToken} = this.props;
        const api = "https://api.molino.ir/Site/sendCode?phone=" + phoneNum;
        // this.setState({loading: true}, () => {
            axios.get(api,
                {
                    headers: {
                        'accessToken': accessToken
                    }
                })
                .then(
                    res => {
                        // this.setState({
                        //     loading: false
                        // })
                        // alert(res.data.result + " ," + res.data.message);
                        if (res.data.result === true) {
                            // if (res.data.message.length > 20) {
                                this.setState({
                                    // resetTimer: true,
                                    // open:false,
                                    // form:2
                                })
                            // }
                            // alert(this.state.time);
                            ///end
                            // this.phoneNumCallback();
                        } else {
                            // this.setState({
                            //     phoneNumError: res.data.message
                            // })
                        }
                    })
                // })
                .catch(function (error) {
                    console.log(error);
                });
        // })
        // this.CallbackChCode();
    }
    setFalse=()=>{
        this.setState({
            // open: false,
            // time: 20000,
            resetTimer: false
        })
    }
    render() {
        const {loading, open, time, resetTimer,timer} = this.state;
        // alert(resetTimer)
        return (
            <div>
                <Form
                    className={"signInBox"}
                    id={"signInForm"}
                    onSubmit={this.submitForm}>

                    <br/>
                    <Form.Label className={"label"}> :کد ارسال شده را وارد کنید </Form.Label>
                    <br/>
                    <Form.Control className={"inputBox"} type="text" placeholder={"----"}
                                  name="checkCode"
                                  value={this.state.checkCode}
                                  onChange={this.handleChange}
                                  maxLength="4"
                                  disabled={loading}
                    />

                    <div className="errorMsg">{this.state.checkCodeError}</div>

                    <div className={"spinnerContainer"} style={{width: 1000, height: 50}}>
                        {loading ? <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="100"
                                           width="100"/> : ''}
                    </div>
                    <div style={timer?{color: 'red', direction: 'rtl'}:{display: 'none'}}>
                        <Timer
                            // onReset={()=>this.resetTime}
                            initialTime={time}
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 0,
                                    callback: () => this.handleClickOpen(),

                                },

                                // {
                                //     time:5000,
                                //     callback: ()=> this.setState({
                                //         resetTimer:false
                                //     })
                                // }
                            ]}


                    >
                            {({reset}) => (
                                <React.Fragment>
                                    {/*<div>*/}
                                    <Timer.Seconds/>&nbsp;ثانیه
                                    {/*{alert("resetTimer "+resetTimer)}*/}
                                    {/*<div>{reset}</div>*/}
                                    {/*<div>{resetTimer?this.setFalse():''}</div>*/}
                                {/*</div>*/}

                                    {/*<button onClick={reset()}>reset</button>*/}


                                </React.Fragment>
                            )}
                        </Timer>

                    </div>
                    <button disabled={loading}
                            type="submit"
                        // className={"signButton"}
                            className={loading ? "signButton inactiveSignButton" : "signButton"}
                            onClick={this.checkCode}>
                        ارسال
                    </button>
                    <br/>
                </Form>
                <Dialog

                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{fontSize: 10}}


                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            پایان زمان
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                زمان وارد کردن کد پایان یافت لطفا مجددا تلاش کنید.
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        {/*<Button  onClick={this.handleClose} color="primary">*/}
                        {/*    <p className={'dialogBtn'}>*/}
                        {/*        انصراف*/}
                        {/*    </p>*/}
                        {/*</Button>*/}
                        <div style={{ margin:'auto'}}>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            <p className={'dialogBtn'}>
                                بسیار خب
                            </p>
                        </Button></div>
                    </DialogActions>
                </Dialog>


                {/*{this.state.open?*/}
                {/*    <AlertDialog*/}
                {/*        header={'پایان زمان'}*/}
                {/*        message={'زمان وارد کردن کد پایان یافت لطفا مجددا درخواست ارسال کد کنید'}*/}
                {/*        button={'ارسال کد جدید'}*/}
                {/*        history={this.props.history}*/}
                {/*    />*/}
                {/*    :''}*/}



            </div>
        )
    }
}

export default CheckCodeForm;