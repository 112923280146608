import React from "react";
import './demandDebtStyle.css'
import axios from "axios";
import Loader from "react-loader-spinner";
import edit from "../../assets/logoAndIcons/edit.png";
import ReactTooltip from "react-tooltip";
import trash from "../../assets/logoAndIcons/trash2.png";
import InfiniteScroll from "react-infinite-scroll-component";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import download from "../../assets/logoAndIcons/file.png";
import {Form} from "react-bootstrap";
// import DatePicker from "react-datepicker2";
import momentJalaali from "moment-jalaali";
import TextField from '@material-ui/core/TextField';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import CurrencyInput from "react-currency-input-field";


class DemandDebt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            token: this.props.token,
            isDemand: this.props.isDemand,
            pageNum: 0,
            demands: [],
            debts: [],
            hasMore: true,
            loading: false,
            message: '',
            totalValue: '',
            cleared: '',
            outstanding: '',
            debtLoaded: true,
            demandLoaded: true,

            //Errors
            addingValueErr: '',
            fromErr: '',
            addingError: '',

            //Loading
            excelLoading: false,
            //Delete
            isOpenDelete: false,
            dialogLoading: false,
            selectedID: -1,
            //Add
            isOpenAdd: false,
            addingValue: '',
            addingFrom: '',
            description: '',
            //date
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            dateIsChanged: false,
            descriptionIsChanged: false,
            checkBox: false,

            //Edit

            isOpenEdit: false,
            editValue: '',
            editFrom: '',
            editDescription: '',
            // editDate: '',
            editReminder: false,
            editDate: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },

            isOpenTotalPay:false,
        }

    }

    componentDidMount() {
        this.state.isDemand ? this.getDemands(1) : this.getDebts(1);
    }

    //Get Demands & Debts
    getDemands = (e) => {
        this.getAllDemandDebts(e, true);
    }
    getDebts = (e) => {
        this.getAllDemandDebts(e, false);
    }
    getAllDemandDebts = (e, isDemand) => {
        const {token, accessToken, pageNum, demands, debts} = this.state;
        const page = (e === 1) ? 0 : pageNum;
        const api = 'https://api.molino.ir/Site/GetAllDemandDebts?page=' + page + '&isDemand=' + isDemand;
        this.setState({
            loading: true,
            demandLoaded: (isDemand) ? false : this.state.demandLoaded,
            debtLoaded: (!isDemand) ? false : this.state.debtLoaded
        }, () => {
            axios.get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            demandLoaded: (isDemand) ? true : this.state.demandLoaded,
                            debtLoaded: (!isDemand) ? true : this.state.debtLoaded
                        })
                        if (res.data.result === true) {
                            if (!res.data.demandDebts.length) {
                                // alert("finish")
                                page === 0 ?
                                    this.setState({
                                        hasMore: false,
                                        message: "رکوردی وجود ندارد",
                                        totalValue: res.data.majmoo,
                                        cleared: res.data.tasvie_shode,
                                        outstanding: res.data.tasvie_nashode,

                                    })
                                    :
                                    this.setState({
                                        hasMore: false,
                                    })

                            } else {
                                this.setState({
                                    //updating data
                                    demands: (isDemand) ? [...demands, ...res.data.demandDebts] : demands,
                                    debts: (!isDemand) ? [...debts, ...res.data.demandDebts] : debts,
                                    //updating page numbers
                                    pageNum: pageNum + 1
                                })
                                if (page === 0) {
                                    this.setState({
                                        totalValue: res.data.majmoo,
                                        cleared: res.data.tasvie_shode,
                                        outstanding: res.data.tasvie_nashode,
                                        demands: (isDemand) ? res.data.demandDebts : demands,
                                        debts: (!isDemand) ? res.data.demandDebts : debts,
                                    });

                                }
                                // alert()

                            }
                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                });

        })
    }

    //Adding Functions
    validateAdding = () => {
        const {addingValue, addingFrom, checkBox, date} = this.state;
        let formIsValid = true;
        let addingValueErr = '';
        let fromErr = '';
        // let addingError = '';
        if (addingValue === '') {
            formIsValid = false;
            addingValueErr = 'فیلد مبلغ ضروری است';

        }
        if (addingValue.charAt(0) === "0") {
            formIsValid = false;
            addingValueErr = 'مبلغ وارد شده قابل قبول نیست!';
        }
        if (addingFrom === '') {
            formIsValid = false;
            fromErr = 'این فیلد ضروری است';
        }
        if (!formIsValid) {
            this.setState({
                addingValueErr: addingValueErr,
                fromErr: fromErr,
            })
        }
        return formIsValid;
    }
    addDemandDebts = () => {
        if (this.validateAdding()) {
            const {token, accessToken, isDemand, addingValue, addingFrom, date, description, checkBox} = this.state;
            const body = {
                isDemand: isDemand,
                value: addingValue,
                from: addingFrom,
                day: date.day,
                month: date.month,
                year: date.year,
                description: description,
                remindAtDate: checkBox,
            };

            const api = 'https://api.molino.ir/Site/AddDemandDebt';

            this.setState({
                dialogLoading: true,
            }, () => {
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                dialogLoading: false,
                            });

                            if (res.data.result === true) {
                                this.setState({
                                    isOpenAdd: false,
                                });
                                this.reset();

                            } else {
                                if (res.data.message === "relogin") {
                                    this.handleCallbackReLogin();
                                } else if (res.data.message === "charge") {
                                    this.handleCallbackCharge();
                                }
                            }
                            this.resetForm();
                        })
                    .catch(function (error) {
                        console.log(error);
                        // this.setState({
                        //     dialogLoading: false,
                        //     addingError:"مشکلی در اتصال وجود دارد!"
                        // });
                    });
            })
        }
    }

    //Demand & Debt Button
    demandButton = () => {
        this.setState({
            pageNum: 0,
            isDemand: true,
            hasMore: true,
            message: '',
            demands: [],
            debts: [],
            totalValue: '',
            cleared: '',
            outstanding: '',
        })
        this.getDemands(1);
    }
    debtButton = () => {
        this.setState({
            pageNum: 0,
            isDemand: false,
            hasMore: true,
            message: '',
            demands: [],
            debts: [],
            totalValue: '',
            cleared: '',
            outstanding: '',
        })
        this.getDebts(1);
    }

    //callback
    // pageCallback = (page) => {
    //     this.props.parentCallbackPage(page);
    // }
    // backToHome = () => {
    //     this.pageCallback("home")
    // }
    installmentCallback = (i) => {
        const {isDemand, demands, debts} = this.state;
        this.props.parentCallbackInstallment((isDemand ? demands : debts).find(instance => instance.ID === Number(i.target.id)), this.state.isDemand);
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }

    //Close Dialog
    handleClose = () => {
        this.setState({
            isOpenDelete: false,
            isOpenAdd: false,
            isOpenEdit: false,
            isOpenTotalPay: false,
        })
        this.resetForm();
    }

    //Edit
    edit = (i) => {
        const {isDemand, demands, debts} = this.state;
        this.setState({
            selectedID: Number(i.target.id),
            editDate: {
                year: Number((isDemand ? demands : debts).find(instance => instance.ID === Number(i.target.id)).date.split("-")[0]),
                month: Number((isDemand ? demands : debts).find(instance => instance.ID === Number(i.target.id)).date.split("-")[1]),
                day: Number((isDemand ? demands : debts).find(instance => instance.ID === Number(i.target.id)).date.split("-")[2]),
            },
            isOpenEdit: true
        })
    }
    confirmEdit = () => {
        const {
            token,
            accessToken,
            selectedID,
            isDemand,
            addingValue,
            addingFrom,
            editDate,
            description,
            demands,
            debts,
            dateIsChanged,
            descriptionIsChanged
        } = this.state;
        const api = "https://api.molino.ir/Site/EditDemandDebt";
        const list = (isDemand) ? demands : debts;

        const body = {
            demandDebtId: selectedID,
            value: (addingValue === '') ? list.find(instance => instance.ID === selectedID).value : addingValue,
            from: (addingFrom === '') ? list.find(instance => instance.ID === selectedID).from : addingFrom,
            day: (dateIsChanged) ? editDate.day : list.find(instance => instance.ID === selectedID).date.split("-")[2],
            month: (dateIsChanged) ? editDate.month : list.find(instance => instance.ID === selectedID).date.split("-")[1],
            year: (dateIsChanged) ? editDate.year : list.find(instance => instance.ID === selectedID).date.split("-")[0],
            // year: (dateIsChanged) ? date.year : String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[0])),
            description: (descriptionIsChanged) ? description : list.find(instance => instance.ID === selectedID).description,

        };

        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        // alert(res.data.result + res.data.message)
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {

                            // (isDemand ? demands : debts).map(instance =>
                            //     (instance.ID === selectedID) ?
                            //         this.setState(
                            //             prevState => ({
                            //                     instance: {                   // object that we want to update
                            //                         ...prevState.instance,    // keep all other key-value pairs
                            //                         value: addingValue,       // update the value of specific key
                            //                         from: addingFrom,
                            //                         date: date,
                            //                         description: description,
                            //                     }
                            //                 }
                            //             ))
                            //         : '')

                            // list.map(instance =>
                            //     (instance.ID === selectedID) ?
                            //        alert(instance.value+"-"+instance.from+"-"+instance.date+"-"+instance.description)
                            //         : '')
                            if (dateIsChanged) {
                                this.setState({
                                    isOpenEdit: false
                                })
                                this.resetForm();
                                this.reset();
                            } else {
                                list.forEach((instance) => {
                                    if (instance.ID === selectedID) {
                                        instance.value = (addingValue === '') ? instance.value : Number(addingValue);
                                        instance.from = (addingFrom === '') ? instance.from : addingFrom;
                                        instance.date = (dateIsChanged) ? editDate.year + "-" + editDate.month + "-" + editDate.day : instance.date;
                                        instance.description = (descriptionIsChanged) ? description : instance.description;
                                    }
                                });
                                this.setState({
                                    demands: isDemand ? list : this.state.demands,
                                    debts: !isDemand ? list : this.state.debts,
                                })

                                this.setState({
                                    isOpenEdit: false
                                })
                                this.resetForm();
                            }

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    //DELETE
    delete = (i) => {
        this.setState({
            selectedID: Number(i.target.id),
            isOpenDelete: true
        })
    }
    confirmDelete = () => {
        const {token, accessToken, selectedID} = this.state;
        const api = "https://api.molino.ir/Site/DeleteDemandDebt";
        this.setState({dialogLoading: true}, () => {
            axios.post(api, {
                    demandDebtId: selectedID
                },
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                isOpenDelete: false
                            })
                            this.reset();

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    //Handle Change Input
    changeValue = (value) => {
        if (value) {
            if (value.charAt(0) === '0') {
                this.setState({
                    addingValueErr: "از مبلغ وارد شده اطمینان دارید؟"
                })
            }
        } else {
            this.setState({
                addingValueErr: ""
            })
        }
        this.setState({
            addingValue: value
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            amountError: '',
            descriptionIsChanged: true,
        });
    }
    handleAddingFrom = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }
    handleChangeChk = () => {
        const {date} = this.state;
        let addingError = '';
        let possible = true;
        const error = "برای اضافه کردن یادآور لازم است تاریخ تعیین شده برای آینده باشد.";
        if (!this.state.checkBox) {
            if (date.year < momentJalaali().jYear()) {
                possible = false;
                addingError = error;
            } else if (date.year === momentJalaali().jYear() && date.month < (momentJalaali().jMonth() + 1)) {
                possible = false;
                addingError = error;
            } else if (date.year === momentJalaali().jYear() && date.month === (momentJalaali().jMonth() + 1) && date.day <= momentJalaali().jDate()) {
                possible = false;
                addingError = error;
            }
        }
        this.setState(
            {
                checkBox: (possible) ? !this.state.checkBox : this.state.checkBox,
                addingError: addingError,
            }
        )

    }
    oathTokenRequest = () => {
        const oathTokenRequestApi = "https://api.molino.ir/Site/RequestOathToken";
        const {token, accessToken} = this.state;
        ///////////////////////////////////////
        const {isDemand} = this.state;

        //////////////////////////////////////
        this.setState({
            excelLoading: true,
        }, () => {
            axios.get(oathTokenRequestApi,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            excelLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                oathToken: res.data.oathToken
                            });

                            let link = "https://api.molino.ir/api/Files/GetDemanDebtExcelFile?oathToken=" + res.data.oathToken + "&isDemand=" + isDemand;
                            window.location.assign(link);
                        } else {

                            //موقت
                            alert(res.data.message)
                        }
                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        })
    }
    //TOTAL PAY
    totalPay = (i) => {
        this.setState({
            selectedID: Number(i.target.id),
            isOpenTotalPay: true
        })
    }
    //Installment
    confirmTotalPay = (i) => {
        const {
            token,
            accessToken,
            isDemand,
            demands,
            debts
        } = this.state;
        const date = {
            year: momentJalaali().jYear(),
            month: (momentJalaali().jMonth() + 1),
            day: momentJalaali().jDate(),
        };
        const demandDebt = (isDemand ? demands : debts).find(instance => instance.ID === this.state.selectedID);
        const value = demandDebt.value - demandDebt.cleared;
        const body = {
            demandDebtId: this.state.selectedID,
            value: value,
            day: date.day,
            month: date.month,
            year: date.year,
            description: '',
            remindAtDate: false,
        };

        const api = 'https://api.molino.ir/Site/AddInstallment';

        this.setState({
            dialogLoading: true,
        }, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        });

                        if (res.data.result === true) {
                            this.setState({
                                isOpenTotalPay: false
                            })
                            this.reset();

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                        }
                    })
                .catch(function (error) {
                    console.log(error.response.data);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })

    }
    partialPay = (i) => {
        this.installmentCallback(i);
    }

    //Adding
    addItem = () => {
        this.setState({
            isOpenAdd: true
        })
    }
    submitForm = (e) => {
        e.preventDefault();
    };

    //Reset
    reset = () => {
        this.setState({
            pageNum: 0,
            demands: [],
            debts: [],
            hasMore: true,
            loading: false,
            message: '',
            totalValue: '',
            cleared: '',
            outstanding: '',
        })
        this.state.isDemand ? this.getDemands(1) : this.getDebts(1);
    }
    resetForm = () => {
        this.setState({
            addingValueErr: '',
            fromErr: '',
            addingError: '',
            addingValue: '',
            addingFrom: '',
            description: '',
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            checkBox: false,
            dateIsChanged: false,
            descriptionIsChanged: false,

        })
    }

    render() {
        const {
            isDemand,
            demands,
            debts,
            hasMore,
            message,
            totalValue,
            cleared,
            outstanding,
            demandLoaded,
            debtLoaded,
            excelLoading,
            isOpenAdd,
            addingValue,
            checkBox,
            isOpenEdit,
            selectedID,
            isOpenTotalPay
        } = this.state;
        const {isOpenDelete, dialogLoading} = this.state;
        const dataLength = (isDemand) ? demands.length - 1 : debts.length - 1;
        const list = (isDemand) ? demands : debts;
        const type = (isDemand) ? " طلب ها" : " بدهی ها";
        const {currency} = this.props;
        //Errors
        const {addingValueErr, fromErr, addingError} = this.state;
        // {alert(String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[0])))}
        return (
            <div className={"manageCatRoot"}>
                <div className={"MCButtonContainer"}>
                    <button className={(isDemand) ? "MCButtonActive" : "MCButton"}
                            onClick={this.demandButton} disabled={!debtLoaded||isDemand}>
                        طلب های من
                    </button>
                    <button className={(!isDemand) ? "MCButtonActive" : "MCButton"}
                            onClick={this.debtButton} disabled={!demandLoaded||!isDemand}>
                        بدهی های من
                    </button>
                </div>

                <div
                    // className={"MCBody"}
                >

                    <div className={"reportContainer"}>
                        <ul className={"reportList"} style={{flexDirection: "column"}}>
                            <li className={"reportItem"}>
                                مجموع{type}&nbsp;:&nbsp;&nbsp;
                                {totalValue.toLocaleString() + ' ' + currency}
                            </li>
                            <li className={"reportItem"}>تسویه شده&nbsp;:&nbsp;&nbsp;
                                <p className={"lightGreenColor"}
                                   style={{display: "inline"}}>{cleared.toLocaleString()}</p>&nbsp;{currency}
                            </li>
                            <li className={"reportItem"}>تسویه نشده&nbsp;:&nbsp;&nbsp;
                                <p className={"lightRedColor"}
                                   style={{display: "inline"}}>{outstanding.toLocaleString()}</p>&nbsp;{currency}
                            </li>
                            <li className={"reportItem"}>
                                <a
                                    onClick={this.oathTokenRequest}>
                                    <button className={"excelButton"}
                                            disabled={!list.length}
                                    >
                                        <img className={"excelIcon"} src={download}/>
                                        <p className={"excelP"}>دانلود اکسل {isDemand ? " طلب ها" : " بدهی ها"}</p>
                                        {excelLoading ? <div className={"filterSpinner"}>
                                            <Loader
                                                type="Puff" color="#fff" height="80%"
                                                width="80%"/></div> : ''}
                                    </button>
                                </a>
                            </li>
                        </ul>


                    </div>
                    <ul className={"transactionList hoverItem"} onClick={this.addItem}>
                        <p className={"MCAddItem"}>+ افزودن {isDemand?"طلب":"بدهی"} جدید</p>

                    </ul>
                    <ul className={"historyHeader"}>
                        <li className={"historyHItem"}><p
                            className={"historyHItemP"}>مبلغ کل</p></li>
                        <li className={"historyHItem"}><p
                            className={"historyHItemP"}>{isDemand ? "طلب از" : "بدهی به"}</p></li>
                        <li className={"historyHItem"}><p
                            className={"historyHItemP"}>تسویه شده</p></li>
                        <li className={"historyHItem"}><p
                            className={"historyHItemP"}>تسویه نشده</p></li>
                        <li className={"historyHItem"} style={{flex: 4}}><p className={"historyHItemP"}>توضیح</p></li>
                        <li className={"historyHItem"}><p className={"historyHItemP"}>تاریخ</p></li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>ویرایش</p></li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>حذف</p></li>
                    </ul>
                    <p className={message !== '' ? "notfound" : "hidden"}>{message}</p>
                    <div
                        // className={"catList"}
                    >
                        <InfiniteScroll className={"historyScroll"}
                                        dataLength={dataLength} //This is important field to render the next data
                                        next={(isDemand) ? this.getDemands : this.getDebts}
                                        hasMore={hasMore}
                                        loader={<Loader style={{marginTop: 5}}
                                                        type="Oval" color="#4d535e" height="60"
                                                        width="60"/>}>
                            {list.map(instance => (

                                <ul className="ddListContainer"
                                    // key={transaction.value}
                                    // onClick={this.partialPay} id={instance.ID}
                                >
                                    <div className={"demandDebtList"}>
                                        <li className={"transactionItem"} style={{
                                            // color: "green",
                                            fontWeight: "bold", display: "flex",
                                            flexDirection: "row"
                                        }}
                                        ><p className={"currencyP"} style={{marginRight: 0}}>{currency}&nbsp;
                                        </p>
                                            <p className={"currencyP"} style={{marginLeft: 0}}>
                                                {/*+*/}
                                                {instance.value.toLocaleString()}
                                                {" "}
                                            </p></li>

                                        <li className={"transactionItem"}>{instance.from}</li>
                                        <li className={"transactionItem lightGreenColor"}
                                            style={{direction: "rtl"}}>
                                            <p className={"currencyP installmentSelect"}
                                               onClick={this.installmentCallback} id={instance.ID}>
                                                {instance.cleared.toLocaleString() + " " + currency}
                                            </p>
                                        </li>
                                        <li className={"transactionItem lightRedColor"}
                                            style={{display: "flex", flexDirection: "row"}}>
                                            <p className={"currencyP"} style={{marginRight: 0}}>{currency}&nbsp;</p>
                                            <p className={"currencyP"} style={{marginLeft: 0}}>
                                                {(instance.value - instance.cleared).toLocaleString()}
                                            </p>
                                        </li>
                                        <li className={"transactionItem"}
                                            style={{flex: 4, direction: 'rtl'}}>{instance.description}</li>
                                        <li className={"transactionItem"}>{instance.date}</li>
                                        <li className={"transactionItem"}
                                            style={{flex: 1}}
                                        >
                                            {/*<div*/}
                                            {/*    className={"transactionItemP"}>*/}
                                            <img className={"HIcon"} src={edit}
                                                 id={instance.ID}
                                                 onClick={this.edit} data-tip
                                                 data-for="editTip"/>
                                            <ReactTooltip id="editTip" place="top" effect="solid">
                                                <p className={"tooltip"}>
                                                    ویرایش
                                                </p>

                                            </ReactTooltip>
                                            {/*</div>*/}
                                        </li>
                                        <li className={"transactionItem"} style={{flex: 1}}>
                                            <img className={"HIcon"} src={trash}
                                                 id={instance.ID}
                                                 onClick={this.delete} data-tip
                                                 data-for="deleteTip"/>
                                            <ReactTooltip id="deleteTip" place="top" effect="solid">
                                                <p className={"tooltip"}>
                                                    حذف
                                                </p>
                                            </ReactTooltip>
                                        </li>
                                    </div>
                                    <div
                                        style={{textAlign:"left",
                                            direction:"rtl"
                                        }}
                                    >
                                        <button className={"ddCaseButton ddCaseButtonHover"} onClick={this.partialPay}
                                                id={instance.ID}>تسویه بخشی از مبلغ
                                        </button>
                                        <button
                                            className={instance.value === instance.cleared ? "ddCaseButton" : "ddCaseButton ddCaseButtonHover"}
                                            // style={{backgroundColor: "#61cd75"}}
                                            onClick={this.totalPay} id={instance.ID}
                                            disabled={instance.value === instance.cleared}>تسویه کل مبلغ
                                        </button>
                                        <button className={"ddCaseButton ddCaseButtonHover "} onClick={this.partialPay}
                                                id={instance.ID}>جزییات
                                        </button>
                                    </div>
                                </ul>


                            ))}

                        </InfiniteScroll>


                    </div>


                </div>

                <Dialog
                    open={isOpenDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف {(isDemand ? "طلب" : "بدهی")}
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا از حذف این مورد اطمینان دارید؟
                            </p>
                        </DialogContentText>

                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop: 0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmDelete} color="primary">
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>


                <Dialog
                    open={isOpenAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*<DialogTitle id="alert-dialog-title">*/}
                    {/*    <p className={'dialogTitle'}>*/}
                    {/*        افزودن {(isDemand ? " طلب " : " بدهی ")} جدید*/}
                    {/*    </p>*/}
                    {/*</DialogTitle>*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            <p className={'dialogTitle'}>
                                افزودن {(isDemand ? " طلب " : " بدهی ")} جدید
                            </p>
                            {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0", marginLeft:"0"}}
                                > مبلغ: </Form.Label>
                                <CurrencyInput
                                    // className={"homeInput"}
                                    className={"DDInput"}
                                    id="input-example"
                                    name="amount"
                                    style={{flex: 4, direction: "ltr"}}
                                    placeholder={"5,000,000"}
                                    value={addingValue}
                                    onChange={this.changeValue}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    // prefix={currency}
                                    disabled={dialogLoading}
                                />
                                {/*<Form.Control className={"DDInput"} type="text" placeholder="5,000,000"*/}
                                {/*              name="addedCat"*/}
                                {/*    // value={this.state.editedCat}*/}
                                {/*              onChange={this.handleChangeSelectedCat}*/}

                                {/*    // disabled={loading}*/}
                                {/*/>*/}
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > {currency} </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingValueErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > {(isDemand ? "از" : "به")}: </Form.Label>

                                <Form.Control className={"DDInput"} type="text" placeholder="علی"
                                              name="addingFrom"
                                              onChange={this.handleAddingFrom}
                                              disabled={dialogLoading}

                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>
                            <div className="DDErrorMsg">{fromErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={3}
                                              placeholder={(isDemand) ? "بابت خرید کالا (اختیاری)" : "دریافت وام از بانک (اختیاری)"}
                                              name="description"
                                              value={this.state.description}
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.date}
                                        onChange={date => this.setState({
                                            date: date,
                                            addingError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>
                            {/*<form*/}
                            {/*    // className={classes.container}*/}
                            {/*      noValidate>*/}
                            {/*    <TextField*/}
                            {/*        id="date"*/}
                            {/*        label="Birthday"*/}
                            {/*        type="date"*/}
                            {/*        defaultValue="2017-05-24"*/}
                            {/*        // className={classes.textField}*/}
                            {/*        InputLabelProps={{*/}
                            {/*            shrink: true,*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</form>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Control className={"DDCheckBox"} type="checkBox"
                                              name="addingFrom"
                                              checked={checkBox}
                                    // value={checkBox}
                                              onChange={this.handleChangeChk}
                                              disabled={dialogLoading}
                                    // disabled={loading}
                                />
                                <Form.Label className={"DDChLabel"}> در این تاریخ یادآور هم اضافه بشود </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.addDemandDebts} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>


                <Dialog
                    open={isOpenEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*{list.map((instance)=>instance.ID===selectedId?alert(instance.ID+"-"+selectedId):'')}*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            {/*heloooo*/}
                            {/*{selectedId}*/}
                            {/*{list.filter(instance=>instance.ID===selectedId).value}*/}
                            <p className={'dialogTitle'}>
                                ویرایش {(isDemand ? " طلب " : " بدهی ")}
                            </p>
                            {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0", marginLeft:"0"}}
                                > مبلغ: </Form.Label>

                                <CurrencyInput
                                    // className={"homeInput"}
                                    className={"DDInput"}
                                    id="input-example"
                                    name="amount"
                                    style={{flex: 4, direction: "ltr"}}
                                    defaultValue={list.filter(instance => instance.ID === selectedID).map(filtered => filtered.value)}
                                    // value={addingValue}
                                    onChange={this.changeValue}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    // prefix={currency}
                                    disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > {currency} </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingValueErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > {(isDemand ? "از" : "به")}: </Form.Label>

                                <Form.Control className={"DDInput"} type="text"
                                              name="addingFrom"
                                              onChange={this.handleAddingFrom}
                                              disabled={dialogLoading}
                                              defaultValue={list.filter(instance => instance.ID === selectedID).map(filtered => filtered.from)}

                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>
                            <div className="DDErrorMsg">{fromErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={3}
                                              name="description"
                                    // value={list.find(instance => instance.ID === selectedID).description}
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                              defaultValue={list.filter(instance => instance.ID === selectedID).map(filtered => filtered.description)}
                                    // defaultValue={list.find(instance => instance.ID === selectedID).description}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.editDate}
                                        // value={
                                        //     {
                                        //     year: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[0])),
                                        //     month: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[1])),
                                        //     day: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[2])),
                                        // }
                                        //
                                        // }
                                        onChange={date => this.setState({
                                            editDate: date,
                                            dateIsChanged: true,
                                            addingError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>

                            {/*<Form.Group className={'DDFormGroup'}>*/}
                            {/*    <Form.Control className={"DDCheckBox"} type="checkBox"*/}
                            {/*                  name="addingFrom"*/}
                            {/*                  checked={checkBox}*/}
                            {/*        // value={checkBox}*/}
                            {/*                  onChange={this.handleChangeChk}*/}
                            {/*                  disabled={dialogLoading}*/}
                            {/*        // disabled={loading}*/}
                            {/*    />*/}
                            {/*    <Form.Label className={"DDChLabel"}> در این تاریخ یادآور هم اضافه بشود </Form.Label>*/}

                            {/*</Form.Group>*/}
                            <div className="DDErrorMsg">{addingError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.confirmEdit} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>

                <Dialog
                    open={isOpenTotalPay}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            تسویه کل مبلغ
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا میخواهید کل مبلغ را تسویه کنید؟
                            </p>
                        </DialogContentText>

                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop: 0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    خیر
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmTotalPay} color="primary">
                                <p className={'dialogBtn'}>
                                    بله
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

            </div>
        )
    }

}

export default DemandDebt;