import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import { usePromiseTracker } from "react-promise-tracker";
// import store from './components/Redux/store'
// ReactDOM.render( <App/>, document.getElementById("root")
// )
// ;


ReactDOM.render(
    <React.StrictMode>
        {/*//   <Provider store={store}>*/}
        {/*  <App />*/}
        {/*  // </Provider>,*/}
        <App/>
    </React.StrictMode>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
