import React from 'react';
import PropTypes from 'prop-types';
import "./transactionStyle.css"
import Loader from "react-loader-spinner";
const propTypes = {
    id: PropTypes.string.isRequired,
    // loading: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired,
    resetFunc:PropTypes.func.isRequired,
    changeCategories:PropTypes.func.isRequired,
};

class Transaction extends React.Component {
    static modals = [];

    static open = (id) => (e) => {
        e.preventDefault();

        // open modal specified by id
        let modal = Transaction.modals.find(x => x.props.id === id);
        modal.setState({isOpen: true});
        document.body.classList.add('jw-modal-open');
    }

    static close = (id) => (e) => {
        e.preventDefault();

        // close modal specified by id
        let modal = Transaction.modals.find(x => x.props.id === id);
        modal.props.resetFunc();
        modal.setState({isOpen: false});
        document.body.classList.remove('jw-modal-open');
    }

    static confirm = (id) => (e) => {
        e.preventDefault();

        // close modal specified by id
        let modal = Transaction.modals.find(x => x.props.id === id);

        // alert("confirm")

        modal.props.func();

        if (modal.props.amount!=='') {
            modal.props.resetFunc();
            modal.setState({isOpen: false});
            document.body.classList.remove('jw-modal-open');
        }
    }
    static changeCategories = (id) => (e) => {
        e.preventDefault();

        // close modal specified by id
        let modal = Transaction.modals.find(x => x.props.id === id);

        // alert("confirm")
        modal.props.changeCategories();
        modal.props.resetFunc();
        modal.setState({isOpen: false});
        document.body.classList.remove('jw-modal-open');


    }

    constructor(props) {
        super(props);

        this.state = {isOpen: false};

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // add this modal instance to the modal service so it's accessible from other components
        Transaction.modals.push(this);
    }

    componentWillUnmount() {
        // remove this modal instance from modal service
        Transaction.modals = Transaction.modals.filter(x => x.props.id !== this.props.id);
        this.element.remove();
    }

    handleClick(e) {
        // close modal on background click
        if (e.target.className === 'jw-transaction') {
            Transaction.close(this.props.id)(e);
        }
    }

    render() {
        return (
            <div
                style={{display: +this.state.isOpen ? '' : 'none'}}
                onClick={this.handleClick} ref={el => this.element = el}
            >
                <div className={"jw-transaction"}>
                    <div className={'homeTransaction'}>
                        {this.props.children}
                    </div>
                </div>

                {/*<div>*/}
                {/*    {!this.props.loading ? <div className={"homeSpinner"}>*/}

                {/*            <Loader className={"spinnerH"} type="Circles" color="yellow" height="100"*/}
                {/*                    width="100"/>*/}
                {/*        </div>*/}
                {/*        : ''}*/}
                {/*</div>*/}

            </div>
        );
    }
}

Transaction.propTypes = propTypes;

export default Transaction;