import React from "react";
import "./installmentStyle.css"
import download from "../../assets/logoAndIcons/file.png";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import trash from "../../assets/logoAndIcons/trash2.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Form} from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-modern-calendar-datepicker";
import edit from "../../assets/logoAndIcons/edit.png";
import back from "../../assets/logoAndIcons/left-arrow-gray.png";
import axios from "axios";
import momentJalaali from "moment-jalaali";

class Installment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            token: this.props.token,
            demandDebt: this.props.demandDebt,
            demandDebtCleared: this.props.demandDebt.cleared,
            // isDemand: this.props.isDemand,
            currency: this.props.currency,

            //List
            installments: [],
            pageNum: 0,
            hasMore: true,
            message: '',
            loading: false,

            //Delete
            selectedID: -1,
            isOpenDelete: false,

            //Add
            isOpenAdd: false,
            addingValue: '',
            addingValueErr: '',
            description: '',
            checkBox: false,
            addingError: '',

            //Date
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },

            //Dialog
            dialogLoading: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    //Fetch Data
    fetchData = () => {
        const {pageNum, token, accessToken, demandDebt, installments} = this.state;
        const api = "https://api.molino.ir/Site/GetAllInstallments?demandDebtId=" + demandDebt.ID + "&page=0" + pageNum;
        axios
            .get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
            .then(res => {
                    if (res.data.result === true) {
                        if (!res.data.installments.length) {
                            pageNum === 0 ?
                                this.setState({
                                    hasMore: false,
                                    message: "رکوردی وجود ندارد"
                                })
                                :
                                this.setState({
                                    hasMore: false,
                                })

                        } else {
                            this.setState({
                                //updating data
                                installments: [...installments, ...res.data.installments],
                                //updating page numbers
                                pageNum: pageNum + 1
                            })
                        }
                    } else {
                        if (res.data.message === "relogin") {
                            this.handleCallbackReLogin();
                        } else if (res.data.message === "charge") {
                            this.handleCallbackCharge();
                        }
                        // alert(res.data.message)
                    }
                }
            ).catch(function (error) {
            console.log(error);
        });
    }

    //callback
    callback = () => {
        this.props.parentCallbackPage("demand_debt");
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }

    //DELETE
    delete = (i) => {
        this.setState({
            selectedID: Number(i.target.id),
            isOpenDelete: true
        })
    }
    confirmDelete = () => {
        const {token, accessToken, selectedID, installments, demandDebtCleared} = this.state;
        const api = "https://api.molino.ir/Site/DeleteInstallment";
        this.setState({dialogLoading: true}, () => {
            axios.post(api, {
                    installmentId: selectedID
                },
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                isOpenDelete: false,
                                demandDebtCleared: demandDebtCleared - Number(installments.find(instance => instance.ID === selectedID).value)
                            })
                            this.reset();

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    //Add
    addItem = () => {
        this.setState({
            isOpenAdd: true
        })
    }

    validateAdding = () => {
        const {addingValue, demandDebt, demandDebtCleared, checkBox, date} = this.state;
        // const installment =installments.find(instance => instance.ID === demandDebtId);
        let formIsValid = true;
        let addingValueErr = '';
        let fromErr = '';
        // let addingError = '';
        if (addingValue === '') {
            formIsValid = false;
            addingValueErr = 'فیلد مبلغ ضروری است';

        }
        if (addingValue.charAt(0) === "0") {
            formIsValid = false;
            addingValueErr = 'مبلغ وارد شده قابل قبول نیست!';
        }
        if (Number(addingValue) > demandDebt.value - demandDebtCleared) {
            formIsValid = false;
            addingValueErr = 'مقدار مبلغ وارد از مقدار کل بیشتر است!';
        }
        if (!formIsValid) {
            this.setState({
                addingValueErr: addingValueErr,
                fromErr: fromErr,
            })
        }
        return formIsValid;
    }

    confirmAdd = () => {
        if (this.validateAdding()) {
            const {
                token,
                accessToken,
                addingValue,
                date,
                description,
                checkBox,
                demandDebt,
                demandDebtCleared
            } = this.state;
            const body = {
                demandDebtId: demandDebt.ID,
                value: addingValue,
                day: date.day,
                month: date.month,
                year: date.year,
                description: description,
                remindAtDate: checkBox,
            };

            const api = 'https://api.molino.ir/Site/AddInstallment';

            this.setState({
                dialogLoading: true,
            }, () => {
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                dialogLoading: false,
                            });

                            if (res.data.result === true) {
                                this.setState({
                                    isOpenAdd: false,
                                    demandDebtCleared: demandDebtCleared + Number(addingValue),
                                });
                                this.reset();

                            } else {
                                if (res.data.message === "relogin") {
                                    this.handleCallbackReLogin();
                                } else if (res.data.message === "charge") {
                                    this.handleCallbackCharge();
                                }
                            }
                            this.resetForm();
                        })
                    .catch(function (error) {
                        console.log(error.response.data);
                        // this.setState({
                        //     dialogLoading: false,
                        //     addingError:"مشکلی در اتصال وجود دارد!"
                        // });
                    });
            })
        }
    }

    //Edit
    edit = (i) => {
        const {installments} = this.state;
        this.setState({
            selectedID: Number(i.target.id),
            editDate: {
                year: Number(installments.find(instance => instance.ID === Number(i.target.id)).date.split("-")[0]),
                month: Number(installments.find(instance => instance.ID === Number(i.target.id)).date.split("-")[1]),
                day: Number(installments.find(instance => instance.ID === Number(i.target.id)).date.split("-")[2]),
            },
            isOpenEdit: true
        })
    }

    confirmEdit = () => {
        const {
            token,
            accessToken,
            selectedID,
            addingValue,
            editDate,
            description,
            installments,
            dateIsChanged,
            descriptionIsChanged,
            demandDebtCleared
        } = this.state;
        const api = "https://api.molino.ir/Site/EditInstallment";
        const list = installments;

        const body = {
            installmentId: selectedID,
            value: (addingValue === '') ? list.find(instance => instance.ID === selectedID).value : addingValue,
            day: (dateIsChanged) ? editDate.day : list.find(instance => instance.ID === selectedID).date.split("-")[2],
            month: (dateIsChanged) ? editDate.month : list.find(instance => instance.ID === selectedID).date.split("-")[1],
            year: (dateIsChanged) ? editDate.year : list.find(instance => instance.ID === selectedID).date.split("-")[0],
            description: (descriptionIsChanged) ? description : list.find(instance => instance.ID === selectedID).description,
        };

        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                demandDebtCleared: (addingValue === '') ? demandDebtCleared : demandDebtCleared - Number(installments.find(instance => instance.ID === selectedID).value) + Number(addingValue),
                            })
                            if (dateIsChanged) {
                                this.setState({
                                    isOpenEdit: false
                                })
                                this.resetForm();
                                this.reset();
                            } else {
                                list.forEach((instance) => {
                                    if (instance.ID === selectedID) {
                                        instance.value = (addingValue === '') ? instance.value : Number(addingValue);
                                        instance.date = (dateIsChanged) ? editDate.year + "-" + editDate.month + "-" + editDate.day : instance.date;
                                        instance.description = (descriptionIsChanged) ? description : instance.description;
                                    }
                                });
                                this.setState({
                                    installments: list,
                                })

                                this.setState({
                                    isOpenEdit: false
                                })
                                this.resetForm();
                            }

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error.response.data);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    //Handle Change Input
    changeValue = (value) => {
        if (value) {
            if (value.charAt(0) === '0') {
                this.setState({
                    addingValueErr: "از مبلغ وارد شده اطمینان دارید؟"
                })
            }
        } else {
            this.setState({
                addingValueErr: ""
            })
        }
        this.setState({
            addingValue: value
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            amountError: '',
            descriptionIsChanged: true,
        });
    }
    handleChangeChk = () => {
        const {date} = this.state;
        let addingError = '';
        let possible = true;
        const error = "برای اضافه کردن یادآور لازم است تاریخ تعیین شده برای آینده باشد.";
        if (!this.state.checkBox) {
            if (date.year < momentJalaali().jYear()) {
                possible = false;
                addingError = error;
            } else if (date.year === momentJalaali().jYear() && date.month < (momentJalaali().jMonth() + 1)) {
                possible = false;
                addingError = error;
            } else if (date.year === momentJalaali().jYear() && date.month === (momentJalaali().jMonth() + 1) && date.day <= momentJalaali().jDate()) {
                possible = false;
                addingError = error;
            }
        }
        this.setState(
            {
                checkBox: (possible) ? !this.state.checkBox : this.state.checkBox,
                addingError: addingError,
            }
        )

    }

    submitForm = (e) => {
        e.preventDefault();
    };
    //Close Dialog
    handleClose = () => {
        this.setState({
            isOpenDelete: false,
            isOpenAdd: false,
            isOpenEdit: false,
        })
        this.resetForm();
    }

    //Reset
    reset = () => {
        this.setState({
            pageNum: 0,
            installments: [],
            hasMore: true,
            loading: false,
            message: '',
        })
        this.fetchData();
    }
    resetForm = () => {
        this.setState({
            addingValueErr: '',
            addingError: '',
            addingValue: '',
            description: '',
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            dateIsChanged: false,
            descriptionIsChanged: false,

        })
    }

    render() {
        const {demandDebt, demandDebtCleared} = this.state;
        const type = (demandDebt.isDemand) ? " طلب " : " بدهی";
        const totalValue = demandDebt.value;
        const isCleared = (totalValue === demandDebtCleared);
        const {message, installments, hasMore, currency, isOpenDelete, dialogLoading} = this.state;
        //Add
        const {isOpenAdd, addingValue, checkBox, addingError, addingValueErr} = this.state;
        //Edit
        const {isOpenEdit, selectedID} = this.state;
        //////////////////////////////////////////////////////////////////////
        const dataLength = installments.length - 1;
        return (
            <div className={"manageCatRoot"}>
                <div className={"installmentHeader"}>
                    <p className={"installmentTitle"}>
                        جزییات تسویه ها
                    </p>
                    <img className={"IHBackIconH"} alt={"بازگشت"}
                         src={back} data-tip data-for="returnTip"
                         onClick={this.callback}/>
                    <ReactTooltip id="returnTip" place="bottom" effect="solid">
                        <p className={"tooltip"}>
                            بازگشت
                        </p>
                    </ReactTooltip>
                </div>

                <div className={"reportContainer"}>
                    <ul className={"reportList"} style={{flexDirection: "column"}}>
                        <li className={"reportItem"}>
                            مجموع{type}&nbsp;:&nbsp;&nbsp;
                            {totalValue.toLocaleString() + ' ' + currency}
                        </li>
                        <li className={"reportItem"}>تسویه شده&nbsp;:&nbsp;&nbsp;
                            <p className={"lightGreenColor"}
                               style={{display: "inline"}}>{demandDebtCleared.toLocaleString()}</p>&nbsp;{currency}
                        </li>
                        <li className={"reportItem"}>تسویه نشده&nbsp;:&nbsp;&nbsp;
                            <p className={"lightRedColor"}
                               style={{display: "inline"}}>{(totalValue - demandDebtCleared).toLocaleString()}</p>&nbsp;{currency}
                        </li>
                        <li className={"reportItem"}>
                            تاریخ{type}&nbsp;:&nbsp;&nbsp;
                            <p style={{display:"inline",direction: "rtl"}}>{demandDebt.date}</p>
                        </li>
                    </ul>


                </div>

                <div
                    // className={"MCBody"}
                >
                    <ul className={isCleared ? "transactionList" : "transactionList hoverItem"}
                        onClick={isCleared ? '' : this.addItem}>
                        <p className={"MCAddItem"}>
                            {isCleared ? "کامل تسویه شده" : "+ افزودن تسویه جدید"
                            } </p>
                    </ul>
                    <ul className={"historyHeader"}>
                        <li className={"historyHItem"}><p
                            className={"historyHItemP"}>مبلغ کل</p></li>
                        <li className={"historyHItem"} style={{flex: 4}}><p className={"historyHItemP"}>توضیح</p>
                        </li>
                        <li className={"historyHItem"}><p className={"historyHItemP"}>تاریخ</p></li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>ویرایش</p>
                        </li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>حذف</p></li>
                    </ul>
                    <p className={message !== '' ? "notfound" : "hidden"}>{message}</p>

                    <InfiniteScroll className={"historyScroll"}
                                    dataLength={dataLength} //This is important field to render the next data
                                    next={this.fetchData}
                                    hasMore={hasMore}
                                    loader={<Loader style={{marginTop: 5}}
                                                    type="Oval" color="#4d535e" height="60"
                                                    width="60"/>}>
                        {installments.map(instance => (

                            <ul className="ddListContainer"
                                // key={transaction.value}
                            >
                                <div className={"demandDebtList"}>
                                    <li className={"transactionItem"} style={{
                                        // color: "green",
                                        fontWeight: "bold", display: "flex",
                                        flexDirection: "row"
                                    }}
                                    ><p className={"currencyP"} style={{marginRight: 0}}>{currency}&nbsp;
                                    </p>
                                        <p className={"currencyP"} style={{marginLeft: 0}}>
                                            {/*+*/}
                                            {instance.value.toLocaleString()}
                                            {" "}
                                        </p></li>
                                    <li className={"transactionItem"}
                                        style={{flex: 4, direction: 'rtl'}}>{instance.description}</li>
                                    <li className={"transactionItem"}>{instance.date}</li>
                                    <li className={"transactionItem"}
                                        style={{flex: 1}}
                                    >
                                        {/*<div*/}
                                        {/*    className={"transactionItemP"}>*/}
                                        <img className={"HIcon"} src={edit}
                                             id={instance.ID}
                                             onClick={this.edit} data-tip
                                             data-for="editTip"/>
                                        <ReactTooltip id="editTip" place="top" effect="solid">
                                            <p className={"tooltip"}>
                                                ویرایش
                                            </p>

                                        </ReactTooltip>
                                        {/*</div>*/}
                                    </li>
                                    <li className={"transactionItem"} style={{flex: 1}}>
                                        <img className={"HIcon"} src={trash}
                                             id={instance.ID}
                                             onClick={this.delete} data-tip
                                             data-for="deleteTip"/>
                                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                                            <p className={"tooltip"}>
                                                حذف
                                            </p>
                                        </ReactTooltip>
                                    </li>
                                </div>

                            </ul>


                        ))}

                    </InfiniteScroll>


                </div>
                <Dialog
                    open={isOpenDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف تسویه
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا از حذف این مورد اطمینان دارید؟
                            </p>
                        </DialogContentText>

                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop: 0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmDelete} color="primary">
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

                <Dialog
                    open={isOpenAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*<DialogTitle id="alert-dialog-title">*/}
                    {/*    <p className={'dialogTitle'}>*/}
                    {/*        افزودن {(isDemand ? " طلب " : " بدهی ")} جدید*/}
                    {/*    </p>*/}
                    {/*</DialogTitle>*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            <p className={'dialogTitle'}>
                                تسویه بخشی از مبلغ
                            </p>
                            {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0", marginLeft:"0"}}
                                > مبلغ: </Form.Label>
                                <CurrencyInput
                                    // className={"homeInput"}
                                    className={"DDInput"}
                                    id="input-example"
                                    name="amount"
                                    style={{flex: 4, direction: "ltr"}}
                                    placeholder={"5,000,000"}
                                    value={addingValue}
                                    onChange={this.changeValue}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    // prefix={currency}
                                    disabled={dialogLoading}
                                />
                                {/*<Form.Control className={"DDInput"} type="text" placeholder="5,000,000"*/}
                                {/*              name="addedCat"*/}
                                {/*    // value={this.state.editedCat}*/}
                                {/*              onChange={this.handleChangeSelectedCat}*/}

                                {/*    // disabled={loading}*/}
                                {/*/>*/}
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > {currency} </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingValueErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={5}
                                              placeholder={"به صورت کارت به کارت (اختیاری)"}
                                              name="description"
                                              value={this.state.description}
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.date}
                                        onChange={date => this.setState({
                                            date: date,
                                            addingError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>
                            {/*<form*/}
                            {/*    // className={classes.container}*/}
                            {/*      noValidate>*/}
                            {/*    <TextField*/}
                            {/*        id="date"*/}
                            {/*        label="Birthday"*/}
                            {/*        type="date"*/}
                            {/*        defaultValue="2017-05-24"*/}
                            {/*        // className={classes.textField}*/}
                            {/*        InputLabelProps={{*/}
                            {/*            shrink: true,*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</form>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Control className={"DDCheckBox"} type="checkBox"
                                              name="addingFrom"
                                              checked={checkBox}
                                    // value={checkBox}
                                              onChange={this.handleChangeChk}
                                              disabled={dialogLoading}
                                    // disabled={loading}
                                />
                                <Form.Label className={"DDChLabel"}> در این تاریخ یادآور هم اضافه بشود </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.confirmAdd} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>

                <Dialog
                    open={isOpenEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*{list.map((instance)=>instance.ID===selectedId?alert(instance.ID+"-"+selectedId):'')}*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            {/*heloooo*/}
                            {/*{selectedId}*/}
                            {/*{list.filter(instance=>instance.ID===selectedId).value}*/}
                            <p className={'dialogTitle'}>
                                ویرایش تسویه
                            </p>
                            {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0", marginLeft:"0"}}
                                > مبلغ: </Form.Label>

                                <CurrencyInput
                                    // className={"homeInput"}
                                    className={"DDInput"}
                                    id="input-example"
                                    name="amount"
                                    style={{flex: 4, direction: "ltr"}}
                                    defaultValue={installments.filter(instance => instance.ID === selectedID).map(filtered => filtered.value)}
                                    // value={addingValue}
                                    onChange={this.changeValue}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    // prefix={currency}
                                    disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > {currency} </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{addingValueErr}</div>
                            {/*<Form.Group className={'DDFormGroup'}>*/}
                            {/*    <Form.Label className={"DDLabel"}*/}
                            {/*        // style={{marginTop: "0", marginBottom: "0"}}*/}
                            {/*    > {(isDemand ? "از" : "به")}: </Form.Label>*/}

                            {/*    <Form.Control className={"DDInput"} type="text"*/}
                            {/*                  name="addingFrom"*/}
                            {/*                  onChange={this.handleAddingFrom}*/}
                            {/*                  disabled={dialogLoading}*/}
                            {/*                  defaultValue={list.filter(instance => instance.ID === selectedID).map(filtered => filtered.from)}*/}

                            {/*    />*/}
                            {/*    <Form.Label className={"DDLabel"}*/}
                            {/*                style={{marginRight: "2%"}}*/}
                            {/*    > </Form.Label>*/}
                            {/*</Form.Group>*/}
                            {/*<div className="DDErrorMsg">{fromErr}</div>*/}
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={5}
                                              name="description"
                                    // value={list.find(instance => instance.ID === selectedID).description}
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                              defaultValue={installments.filter(instance => instance.ID === selectedID).map(filtered => filtered.description)}
                                    // defaultValue={list.find(instance => instance.ID === selectedID).description}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.editDate}
                                        // value={
                                        //     {
                                        //     year: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[0])),
                                        //     month: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[1])),
                                        //     day: String(list.filter(instance => instance.ID === selectedID).map(filtered => filtered.date.split("-")[2])),
                                        // }
                                        //
                                        // }
                                        onChange={date => this.setState({
                                            editDate: date,
                                            dateIsChanged: true,
                                            addingError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>

                            {/*<Form.Group className={'DDFormGroup'}>*/}
                            {/*    <Form.Control className={"DDCheckBox"} type="checkBox"*/}
                            {/*                  name="addingFrom"*/}
                            {/*                  checked={checkBox}*/}
                            {/*        // value={checkBox}*/}
                            {/*                  onChange={this.handleChangeChk}*/}
                            {/*                  disabled={dialogLoading}*/}
                            {/*        // disabled={loading}*/}
                            {/*    />*/}
                            {/*    <Form.Label className={"DDChLabel"}> در این تاریخ یادآور هم اضافه بشود </Form.Label>*/}

                            {/*</Form.Group>*/}
                            <div className="DDErrorMsg">{addingError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.confirmEdit} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>

            </div>
        )
    }


}

export default Installment;