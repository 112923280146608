import React from "react";
import './manageCategorisStyle.css'
import edit from "../../assets/logoAndIcons/edit.png"
import trash from "../../assets/logoAndIcons/trash2.png"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {Form} from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";

class ManageCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            token: this.props.token,
            incomeCat: this.props.incomeCat,
            expenseCat: this.props.expenseCat,
            isIncome: this.props.isIncome,
            selectedCat: '',
            editedCat: '',
            addedCat: '',
            isOpenDelete: false,
            isOpenEdit: false,
            isOpenAdd: false,
            incomeChange: false,
            expenseChange: false,
            loading: false,
            deleteDialog: false,
            //Errors
            editedError: '',
            addedError: '',
            //Loading
            dialogLoading: false,
        }

    }

    incomeButton = () => {
        this.setState({
            isIncome: true,
        })
    }
    expenseButton = () => {
        this.setState({
            isIncome: false
        })
    }
    //callback
    pageCallback = (page) => {
        this.props.parentCallbackPage(page);

    }
    backToHome = () => {
        this.pageCallback("home")
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }

    //close dialog
    handleClose = () => {
        this.setState({
            isOpenDelete: false,
            isOpenEdit: false,
            isOpenAdd: false,
            deleteDialog: false,
            addedError: '',
            editedError: ''
        })
    }

    deleteCat = (i) => {
        const {isIncome, incomeCat, expenseCat} = this.state;
        const category = (isIncome) ? incomeCat : expenseCat;
        if (category.length === 1) {
            this.setState({
                deleteDialog: true,
            })

            return
        }

        this.setState({
            selectedCat: i.target.id,
            isOpenDelete: true
        })


    }

    confirmDeleteCat = () => {

        const {isIncome, selectedCat, incomeCat, expenseCat, incomeChange, expenseChange} = this.state;
        // alert(selectedCat)
        this.setState(prevState => ({
            incomeCat: (isIncome) ? prevState.incomeCat.filter(item => item !== selectedCat) : incomeCat,
            expenseCat: (isIncome) ? expenseCat : prevState.expenseCat.filter(item => item !== selectedCat),
            incomeChange: (isIncome) ? true : incomeChange,
            expenseChange: (isIncome) ? expenseChange : true,
        }))

        const api = "https://api.molino.ir/Site/DeleteUserCategory";
        let body = {
            isIncom: isIncome,
            old_category: selectedCat
        }
        this.postApi(api, body);

    }


    editCat = (i) => {
        this.setState({
            selectedCat: i.target.id,
            editedCat: i.target.id,
            isOpenEdit: true
        })
    }
    handleChangeSelectedCat = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            editedError: '',
            addedError: '',
        });
    }

    validateEditedForm = (item) => {
        let isValid = true;
        const {editedCat, addedCat, isIncome, incomeCat, expenseCat} = this.state;
        const category = (isIncome) ? incomeCat : expenseCat;
        const select = (item === 'add') ? addedCat : editedCat;
        if (select === '') {
            this.setState({
                addedError: (item === 'add') ? "عنوان دسته را وارد کنید." : '',
                editedError: (item === 'add') ? '' : "عنوان دسته را وارد کنید."
            })
            isValid = false;
        } else if (category.some(item => select === item)) {
            this.setState({
                addedError: (item === 'add') ? "این عنوان در دسته های شما وجود دارد." : '',
                editedError: (item === 'add') ? '' : "این عنوان در دسته های شما وجود دارد."
            })
            isValid = false;
        }
        return isValid;
    }


    confirmEditCat = () => {
        if (this.validateEditedForm('edit')) {
            const {isIncome, selectedCat, editedCat, incomeCat, expenseCat, incomeChange, expenseChange} = this.state;
            const tempCat = (isIncome) ? incomeCat : expenseCat;
            const index = tempCat.indexOf(selectedCat);
            const copy = tempCat.slice();
            copy[index] = editedCat;
            this.setState({
                incomeCat: (isIncome) ? copy : incomeCat,
                expenseCat: (isIncome) ? expenseCat : copy,
                incomeChange: (isIncome) ? true : incomeChange,
                expenseChange: (isIncome) ? expenseChange : true,
            });

            const api = "https://api.molino.ir/Site/EditUserCategory";
            let body = {
                isIncom: isIncome,
                old_category: selectedCat,
                new_category: editedCat
            }
            this.postApi(api, body);
        }

    }

    addItem = (i) => {

        this.setState({
            selectedCat: i.target.id,
            isOpenAdd: true
        })
    }
    postApi = (api, body) => {
        const {accessToken, token} = this.state;

        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            this.handleClose();
                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }

                        }

                    })
                .catch(function (error) {
                    console.log(error);
                });

        })

    }
    confirmAddCat = () => {
        if (this.validateEditedForm('add')) {
            const {isIncome, addedCat, incomeCat, expenseCat, incomeChange, expenseChange} = this.state;
            this.setState({
                incomeCat: (isIncome) ? incomeCat.concat(addedCat) : incomeCat,
                expenseCat: (isIncome) ? expenseCat : expenseCat.concat(addedCat),
                incomeChange: (isIncome) ? true : incomeChange,
                expenseChange: (isIncome) ? expenseChange : true,
            })
            const api = "https://api.molino.ir/Site/AddUserCategory";

            let body = {
                isIncom: isIncome,
                new_category: addedCat
            }
            this.postApi(api, body);
        }
    }

    render() {
        const {
            isIncome,
            isOpenEdit,
            isOpenDelete,
            isOpenAdd,
            dialogLoading,
            deleteDialog,
            incomeCat,
            expenseCat
        } = this.state;
        return (
            <div className={"manageCatRoot"}>
                <div className={"MCButtonContainer"}>
                    <button className={(isIncome) ? "MCButtonActive" : "MCButton"}
                            onClick={this.incomeButton}>
                        مدیریت دسته بندی دریافتی ها
                    </button>
                    <button className={(!isIncome) ? "MCButtonActive" : "MCButton"}
                            onClick={this.expenseButton}>
                        مدیریت دسته بندی مخارج
                    </button>
                </div>

                <div className={"MCBody"}>

                    <div className={"catList"}>
                        <div className={"catItem hoverItem"} onClick={this.addItem}>
                            <p className={"MCAddItem"}>+ افزودن مورد جدید</p>

                        </div>
                        {(isIncome ? incomeCat : expenseCat).map(category =>
                            <div className={"catItem"}>
                                <p style={{flex: 6, margin: "auto"}}>{category}</p>

                                <img className={"MCIcon"} src={edit} id={category}
                                     onClick={this.editCat} data-tip data-for="editTip"/>
                                <ReactTooltip id="editTip" place="top" effect="solid">
                                    <p className={"tooltip"}>
                                        ویرایش
                                    </p>

                                </ReactTooltip>
                                <img className={"MCIcon"} src={trash} id={category}
                                     onClick={this.deleteCat} data-tip data-for="deleteTip"/>
                                <ReactTooltip id="deleteTip" place="top" effect="solid">
                                    <p className={"tooltip"}>
                                        حذف
                                    </p>
                                </ReactTooltip>

                            </div>
                        )}


                    </div>

                </div>
                <button className={"MCBottomButton"} onClick={this.backToHome}>بازگشت</button>



                {/*<div>*/}
                {/*    {loading ? <div className={"homeSpinner"}>*/}

                {/*            <Loader className={"spinnerH"} type="Circles" color="yellow" height="100"*/}
                {/*                    width="100"/>*/}
                {/*        </div>*/}
                {/*        : ''}*/}
                {/*</div>*/}

                <Dialog
                    open={isOpenEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            ویرایش دسته بندی
                        </p>
                    </DialogTitle>
                    <DialogContent style={{direction: "rtl"}}>
                        {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}
                                        style={{marginTop: "auto", marginBottom: "auto", direction: "rtl"}}
                            > عنوان: </Form.Label>

                            <Form.Control className={"homeInput"} type="text"
                                          style={{flex: 4}}
                                          name="editedCat"
                                          value={this.state.editedCat}
                                          onChange={this.handleChangeSelectedCat}

                                // disabled={loading}
                            />


                        </Form.Group>
                        <div className="errorMsg">
                            {this.state.editedError}
                        </div>
                        {/*</DialogContentText>*/}
                        {/*<div className={"spinnerContainer"} style={{height: 50, marginTop:0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>

                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmEditCat} color="primary">
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>


                <Dialog
                    open={isOpenDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف دسته بندی
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا از حذف این مورد اطمینان دارید؟<br/>
                                (تذکر: اگر موردی با عنوان این دسته بندی ثبت شده باشد، با همین عنوان باقی خواهد ماند.
                                )
                            </p>
                        </DialogContentText>
                        {/*<div className={"spinnerContainer"} style={{height: 80, marginBottom:0,marginTop:0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmDeleteCat} color="primary">
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

                <Dialog
                    open={isOpenAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}

                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            افزودن مورد جدید
                        </p>
                    </DialogTitle>
                    <DialogContent style={{direction: "rtl"}}>
                        {/*<DialogContentText id="alert-dialog-description" className={"MCDialog"}>*/}
                        <Form.Group className={'formGroup'}>
                            <Form.Label className={"homeLabel"}
                                        style={{marginTop: "auto", marginBottom: "auto", direction: "rtl"}}
                            > عنوان: </Form.Label>

                            <Form.Control className={"homeInput MCInput"} type="text" placeholder="عنوان دسته جدید"
                                          style={{flex: 4}}
                                          name="addedCat"
                                // value={this.state.editedCat}
                                          onChange={this.handleChangeSelectedCat}

                                // disabled={loading}
                            />

                        </Form.Group>

                        <div className="errorMsg">
                            {this.state.addedError}
                        </div>
                        {/*</DialogContentText>*/}
                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>

                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmAddCat} color="primary">
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

                <Dialog
                    open={deleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف دسته بندی
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                کاربر گرامی امکان حذف دسته مورد نظر وجود ندارد.
                                زیرا لازم است حداقل یک دسته بندی وجود داشته باشد.
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    متوجه شدم
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>


            </div>

    )
    }

}

export default ManageCategories;