import React from 'react';
import './contactStyle.css';
import 'react-bootstrap';
import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import telegram from "../../assets/logoAndIcons/telegramBlack.png"
import instagram from "../../assets/logoAndIcons/instagramBlack.png"
import email from "../../assets/logoAndIcons/emailBlack.png"
import address from "../../assets/logoAndIcons/Address.png"
import phone from "../../assets/logoAndIcons/phone.png"

class Contact extends React.Component {

    render() {
        return (
            <div className={"contactRoot"}>
                <Menu history={this.props.history}  page={'contact'}/>
                <div className={"contactText"}>
                    <h1 className={"aboutHeader"}>تماس با ما</h1>
                    <p className={"contactTextP"}>
                        شما عزیزان می توانید نظرات انتقادات و پیشنهادات خود را جهت بهتر شدن برنامه از طریق راه های
                        ارتباطی زیر با ما در میان بگذارید:

                        <div className={"contactCase"}>
                            <div className={"iconText"}>
                                <img className="contactIcon" src={address}/>
                                <p>آدرس&nbsp;:</p>
                            </div>

                            {/*<a className={"contactA"} target="_blank"*/}
                            {/*   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">*/}
                            &nbsp;تهران - خیابان مطهری، بعد از سهروردی، خیابان یوسفیان، پلاک 123، واحد 4
                            {/*</a>*/}
                        </div>
                        <div className={"contactCase"}>
                            <div className={"iconText"}>
                                <img className="contactIcon" src={phone}/>
                                <p>شماره تماس&nbsp;:</p>
                            </div>

                            {/*<a className={"contactA"} target="_blank"*/}
                            {/*   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">*/}
                            &nbsp;021-88410916
                        {/*</a>*/}
                        </div>
                        <div className={"contactCase"}>
                            <div className={"iconText"}>
                                <img className="contactIcon" src={email}/>
                                <p>ایمیل&nbsp;:</p>
                            </div>

                            <a className={"contactA"} target="_blank"
                               href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                                support@molino.ir </a>
                        </div>
                        <div className={"contactCase"}>
                            <div className={"iconText"}>
                                <img className="contactIcon" src={telegram}/>
                                <p>تلگرام&nbsp;:</p>
                            </div>
                            <a className={"contactA"} target="_blank" href="https://t.me/Jobaccount">
                                Jobaccount@ </a>
                        </div>
                        <div className={"contactCase"}>
                            <div className={"iconText"}>
                                <img className="contactIcon" src={instagram}/>
                                <p>اینستاگرام&nbsp;:</p>
                            </div>
                            <a className={"contactA"} target="_blank"
                               href="https://instagram.com/molino_ir?igshid=kv98nyyd4z8c">
                                molino_ir@ </a>
                        </div>
                    </p>
                </div>
                <Footer history={this.props.history}/>

            </div>
        )
    }
}

export default Contact;
