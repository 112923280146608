import React from 'react';
import "../../../node_modules/video-react/dist/video-react.css";
import video from "../../assets/videos/trailer.mp4"
import poster from "../../assets/pictures/poster1.png"
import "./videoPlayerStyle.css"
/////////////////////////////////////
import {DefaultPlayer as Video} from 'react-html5video';
import 'react-html5video/dist/styles.css';
import banner from '../../assets/pictures/120-600.gif'

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSign: (localStorage.getItem('token') !== null)
        };
    }



    render() {


        const isSign = (localStorage.getItem('token') !== null);
        return (

            <section className="page-section cta">
                <div className="playerContainer">
                    <div style={{display: "flex", flexDirection: 'row',
                        // backgroundColor: "hsla(0, 0%, 100%, .85)"
                    }}>
                        <a
                            className={"aVideo"}
                            href={isSign ? "/profile" : "/sign"}>

                            <img className={"banner1"} src={banner}/>
                        </a>
                        <div className="VC">
                            {/*<div className="col-xl-9 mx-auto">*/}
                                <div style={{marginLeft:0}} className="cta-inner text-center rounded">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-lower">چرا مالینو؟</span></h2>
                                    <Video
                                        // style={{marginTop:"auto", marginBottom:"auto"}}
                                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                        poster={poster}
                                        onCanPlayThrough={() => {
                                            // Do stuff
                                        }}>
                                        <source src={video} type="video/webm"/>
                                        <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt"
                                               default/>
                                    </Video>

                                    <p className="mb-0" style={{direction: 'rtl'}}>
                                        با مالینو سرعت، دقت و کارایی بالا را همزمان دارید.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                {/*</div>*/}
            </section>
        );
    }
}

export default VideoPlayer;
