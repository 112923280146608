import React from 'react';
import './posterStyle.css';
import logo from "../../assets/logoAndIcons/logo.png"
import bazar from "../../assets/logoAndIcons/bazar.jpg"
import posterPhoto from "../../assets/pictures/posterPhoto1.png"

class Poster extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSign: (localStorage.getItem('token') !== null)
        };
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    render() {
        const isSign = this.state.isSign;
        return (
            <div className={"posterContainer"}>

                <div className={"leftPoster"}>
                    <img className={"posterLogo"} src={logo}/>
                    <h1 className={"posterText1"}>حسابداری شخصی مالینو</h1>
                    <h2 className={"posterText2"}>با مالینو می توانید درآمد، مخارج، طلب، بدهی، اقساط و ... خودتون رو ثبت
                        کنید. </h2>
                    <br/>
                    <div className={"buttons"}>
                        <a className="buttonPoster"
                           href={isSign ? "/profile" : "/sign"}>
                            {/*// onClick={this.sign}*/}
                            {isSign ? 'حساب کاربری' : 'ورود/ثبت نام'}

                        </a>
                        یا
                        <a className={"buttonPoster"} target="_blank" style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                           href="https://cafebazaar.ir/app/com.molino">
                            <img className={"buttonImgPoster"} src={bazar}/>
                            <p style={{flex: 5, margin: "auto"}}>دریافت از کافه بازار</p>

                        </a>
                    </div>
                </div>
                <div className={"rightPoster"}>
                    {/*<Video/>*/}
                    <img className={"posterPhoto"} src={posterPhoto}/>
                </div>

                {/*<DateAndTime/>*/
                }
            </div>

        )
    }


}


export default Poster;
