import React from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Landing from "./pages/Landing/Landing"
import About from "./pages/About/About"
import Inside from "./pages/Inside/Inside.js"
import Contact from "./pages/Contact/Contact";
import Sign from "./pages/Sign/Sign";
import Charge from "./pages/Charge/Charge";
import Terms from "./pages/Terms/Terms";
import Faq from "./pages/Faq/Faq";
import './App.css';
import SuccessPay from "./pages/SuccessPay/SuccessPay";
import FailedPay from "./pages/FailedPay/FailedPay";

function App() {

    return (

        <Router
            // history={BrowserHistory}
        >
            <div className="App">
                <Switch>
                    <Route exact={true} path='/' component={Landing}/>
                    <Route path='/about' component={About}/>
                    <Route path='/contact' component={Contact}/>
                    <Route path='/profile' component={Inside}/>
                    <Route path='/sign' component={Sign}/>
                    <Route path='/charge' component={Charge}/>
                    <Route path='/terms' component={Terms}/>
                    <Route path='/faq' component={Faq}/>
                    <Route path='/successPay' component={SuccessPay}/>
                    <Route path='/failedPay' component={FailedPay}/>
                </Switch>

            </div>
        </Router>
    );
}

export default App;
