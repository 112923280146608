import React from "react";
import './insideMenuStyle.css'
import home from "../../assets/logoAndIcons/home.png";
import activeHome from "../../assets/logoAndIcons/home2.png";
import history from "../../assets/logoAndIcons/history.png";
import activeHistory from "../../assets/logoAndIcons/history2.png";
import demand_debt from "../../assets/logoAndIcons/transfer.png";
import activeDemand_debt from "../../assets/logoAndIcons/transfer2.png";
import back from "../../assets/logoAndIcons/left-arrow-gray.png"
import reminder from "../../assets/logoAndIcons/bell-gray.png"
import activeReminder from "../../assets/logoAndIcons/bell-green.png"
import categorize from "../../assets/logoAndIcons/categorize.png";

class InsideMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
        }
    }

    pageCallback = (page) => {
        this.props.parentCallbackPage(page);

    }

    homePage = () => {
        this.setState({
            page: "home"
        })
        this.pageCallback("home");
    }
    historyPage = () => {
        this.setState({
            page: "history"
        })
        this.pageCallback("history");
    }
    demand_debtPage = () => {
        this.setState({
            page: "demand_debt"
        })
        this.pageCallback("demand_debt");
    }
    reminderPage = () => {
        this.setState({
            page: "reminder"
        })
        this.pageCallback("reminder");
    }

    render() {
        const {page} = this.state;
        return (
            <div className={"InsideMenuRoot"}>
                <button className={(page === 'home') ? "InsideMenuButtonActive" : "InsideMenuButton"}
                        onClick={this.homePage}>
                    <img className={"InsideMenuIcon"} src={(page === 'home') ? activeHome : home}/>
                    <p className={"InsideMenuP"}>خانه</p>
                </button>
                <button className={(page === 'history') ? "InsideMenuButtonActive" : "InsideMenuButton"}
                        onClick={this.historyPage}>
                    <img className={"InsideMenuIconH"} src={(page === 'history') ? activeHistory : history}/>
                    <p className={"InsideMenuP"}>تاریخچه</p>
                </button>
                <button className={(page === 'demand_debt') ? "InsideMenuButtonActive" : "InsideMenuButton"}
                        onClick={this.demand_debtPage}>
                    <img className={"InsideMenuIconH"}
                         src={(page === 'demand_debt') ? activeDemand_debt : demand_debt}/>
                    <p className={"InsideMenuP"}>طلب و بدهی</p>
                </button>
                <button className={(page === 'reminder') ? "InsideMenuButtonActive" : "InsideMenuButton"}
                        onClick={this.reminderPage}>
                    <img className={"InsideMenuIconH"}
                         src={(page === 'reminder') ? activeReminder : reminder}/>
                    <p className={"InsideMenuP"}>یادآور</p>
                </button>
                <button className={"InsideMenuButton"}>
                    <a href={"/"} className={"insideBackA"}>

                        <img className={"InsideMenuBackIconH"}
                             src={back}/>
                        <p className={"InsideMenuBackP"}>صفحه اصلی</p>
                    </a>
                </button>

            </div>
        )
    }

}

export default InsideMenu;