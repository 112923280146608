import React from 'react';
import './signStyle.css';
import 'react-bootstrap';
import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import SignBox from "../../components/SignBox/SignBox";
class Sign extends React.Component {
    componentDidMount() {
        const isLogin=localStorage.getItem('token');
        if (isLogin!==null){
            this.props.history.replace({
                    pathname: './profile'
                }
            )
        }
    }
    render() {
        return (
            <div className = {"signRoot"} >
                <Menu history = {this.props.history} page = {'sign'} />
                <SignBox history = {this.props.history} />

                <Footer history={this.props.history}/>

            </div>
        )
    }
}

export default Sign;
