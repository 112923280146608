import React from 'react';
import './casesStyle.css';
//pictures
// import final1 from "../../assets/pictures/final1.png"
import final1 from "../../assets/pictures/sc1.png"
import final2 from "../../assets/pictures/sc5.png"
import final3 from "../../assets/pictures/sc4.png"
import final4 from "../../assets/pictures/sc2.png"
import final5 from "../../assets/pictures/final5.png"
import final6 from "../../assets/pictures/sc3.png"
import final8 from "../../assets/pictures/sc6.png"
import final9 from "../../assets/pictures/final9.png"
import home from "../../assets/pictures/home.png"
import demandAndDebt from "../../assets/pictures/demand&debt.png"
import history from "../../assets/pictures/history.png"
import installment from "../../assets/pictures/installment.png"
import reminder from "../../assets/pictures/reminder.png"
import catManage from "../../assets/pictures/catManage.png"
class Cases extends React.Component {
    render() {
        return (

            <section className="showcase">
                <div className="container-fluid p-0">

                    <div className="row no-gutters">
                        <div className="col-lg-5 order-lg-2 showcase-img">
                            <img className={"CaseImg"} src={final1}/>

                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>ثبت دریافتی و خرج</h2>
                            <p className="lead mb-0">
                                دریافتی و خرج خود را ثبت کنید .
                                دسته&zwnj;بندی مناسب را انتخاب کرده و تاریخ مورد نظر را انتخاب کنید.
                                به این ترتیب دریافتی یا خرج مورد نظر در دسته&zwnj;بندی مشخص به همراه تاریخ ذخیره
                                می&zwnj;شود.
                                {/*در این بخش می توانید دریافتی و خرج خود را ثبت کنید. دسته&nbsp;*/}
                                {/*بندی مناسب را انتخاب و توضیحات لازم را اضافه کنید و*/}
                                {/*در صورت نیاز تاریخ ثبت را از امروز به تاریخ دیگری تغییر دهید.*/}
                            </p>
                            <img className={"CaseImgS"} src={home}/>
                        </div>
                    </div>


                    <div className="row no-gutters">
                        <div className="col-lg-5 text-white showcase-img"
                            // style="background-image:url('bg-showcase-2.jpg');"
                        >

                            <img className={"CaseImg"} src={final4}/>
                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>ثبت طلب و بدهی از اشخاص </h2>
                            <p className="lead mb-0">
                                طلب&zwnj;ها و بدهی&zwnj;های خود شامل مبلغ ، شخص ، توضیحات لازم و تاریخ را ثبت کنید.<br/>
                                مجموع طلب&zwnj;های و بدهی&zwnj;ها را به همراه جزئیات (تسویه شده یا تسویه نشده) مشاهده
                                کنید.
                                به سهولت طلب&zwnj;ها و بدهی&zwnj;های خود را مدیریت کنید.
                            </p>
                            <img className={"CaseImgS"} src={demandAndDebt}/>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 order-lg-2 text-white showcase-img"
                            // style="background-image:url('bg-showcase-3.jpg');"
                        >
                            <img className={"CaseImg"} src={final6}/>
                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>تنظیم یادآور های گوناگون برای روز های خاص</h2>
                            <p className="lead mb-0">
                                برای پرداخت به موقع قسط&zwnj;ها و پرداختی&zwnj;های خود برای تاریخ خاص یادآور ایجاد کنید
                                تا پیامک و اعلان یادآوری در آن تاریخ برایتان ارسال شود.
                                به این ترتیب نگران فراموش کردن تاریخ سررسید&zwnj;ها نباشید.
                            </p>
                            <img className={"CaseImgS"} src={reminder}/>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 text-white showcase-img"
                            // style="background-image:url('bg-showcase-2.jpg');"
                        >

                            <img className={"CaseImg"} src={final8}/>
                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>تعیین دسته بندی ها به دلخواه</h2>
                            <p className="lead mb-0">
                                دسته بندی&zwnj;ها را به دلخواه خودتان اضافه، حذف و یا تغییر دهید.
                                با توجه به شغل و نیاز خود دسته&zwnj;بندی&zwnj;های مد نظرتان را به
                                دسته&zwnj;بندی&zwnj;های اولیه اضافه کنید.

                            </p>
                            <img className={"CaseImgS"} src={catManage}/>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 order-lg-2 text-white showcase-img"
                            // style="background-image:url('bg-showcase-3.jpg');"
                        >
                            {/*<span className={"span"}>*/}
                            <img className={"CaseImg"} src={final2}/>

                            {/*</span>*/}

                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>جستجو و فیلتر بر اساس تاریخ و دسته بندی در موارد ثبت شده</h2>
                            <p className="lead mb-0">
                                در قسمت تاریخچه می&zwnj;توانید دریافتی&zwnj;ها و مخارج ثبت شده خود را مشاهده کنید.
                                در این بخش می&zwnj;توانید موارد مورد نظرتان را بر اساس نوع، تاریخ و دسته&zwnj;بندی مد
                                نظرتان ببینید.
                            </p>
                            <img className={"CaseImgS"} src={history}/>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 text-white showcase-img"
                            // style="background-image:url('bg-showcase-2.jpg');"
                        >
                            <span></span>
                            <img className={"CaseImg"} src={final5}/>
                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>تقسیط طلب ها و بدهی ها</h2>
                            <p className="lead mb-0">
                                در بخش طلب و بدهی می&zwnj;توانید به مدیریت طلب و بدهی خود بپردازید و طلب یا بدهیتان را
                                قسط&zwnj;بندی کنید
                                بدین ترتیب در صورت تسویه بخشی از طلب یا بدهی آن را ثبت کنید تا از مبلغ کل کم شود.
                            </p>
                            <img className={"CaseImgS"} src={installment}/>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 order-lg-2 text-white showcase-img"
                            // style="background-image:url('bg-showcase-3.jpg');"
                        >
                            {/*<span className={"span"}>*/}
                            <img className={"CaseImgT"} src={final3}/>

                            {/*</span>*/}

                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>نمودار درصدی در بازه های زمانی هفتگی، ماهیانه و سالیانه</h2>
                            <p className="lead mb-0">
                                در بخش آنالیز با استفاده از نمودار درصدی هزینه&zwnj;ها و دریافتی&zwnj;هایتان در
                                دسته&zwnj;های مختلف را مشاهده و مقایسه کنید.
                                با استفاده از این به بخش به مدیریت هرچه بهتر مخارج و دریافتی&zwnj;هایتان بپردازید.
                            </p>
                            {/*<img className={"CaseImgS"} src={home}/>*/}
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-5 text-white showcase-img"
                            // style="background-image:url('bg-showcase-2.jpg');"
                        >
                            <img className={"CaseImgT"} src={final9}/>
                        </div>
                        <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                            <h2>نمودار میله ای در بازه های زمانی هفتگی، ماهیانه و سالیانه</h2>
                            <p className="lead mb-0">
                                در بخش آنالیز با استفاده از نمودارهای میله&zwnj;ای ارائه شده در بازه های زمانی مد نظرتان
                                (هفتگی، ماهانه و سالانه)
                                به تفکیک مقدار دریافتی و مخارج خود را مشاهده کنید.
                            </p>
                            {/*<img className={"CaseImgS"} src={home}/>*/}
                        </div>
                    </div>


                </div>
            </section>
        )
    }
}

export default Cases;