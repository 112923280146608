import React from 'react';
import './landingStyle.css';
import 'react-bootstrap';
import Footer from "../../components/Footer/Footer";
import Poster from "../../components/Poster/Poster"
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Menu from "../../components/Menu/Menu";
import Cases from "../../components/Cases/Cases";
import banner from "../../assets/pictures/970-250.gif"
import QAndAns from "../../components/QAndAns/QAndAns";
// import {Helmet} from "react-helmet";

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSign: (localStorage.getItem('token') !== null)
        };
    }

    render() {
        const isSign = this.state.isSign;

        return (
            <div className={"landingRoot"}>
                {/*<Helmet></Helmet>*/}
                <Menu history={this.props.history} page={'landing'}/>
                <Poster history={this.props.history}/>
                {/*<br/>*/}
                {/*<h1 style={{marginTop:"4%"}} >چرا مالینو؟</h1>*/}
                <VideoPlayer history={this.props.history}/>
                <Cases/>
                {/*<QAndAns/>*/}
                <a className={"bannerContainer"}
                    href={isSign ? "/profile" : "/sign"}>
                    <img className={"banner"} src={banner}/>
                </a>

                <Footer history={this.props.history}/>

            </div>
        )
    }
}

export default Landing;
