import React, {useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import "./FailedPayStyle.css"
import support from "../../assets/logoAndIcons/support.png"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import email from "../../assets/logoAndIcons/emailBlack.png";
import telegram from "../../assets/logoAndIcons/telegramBlack.png";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import back from "../../assets/logoAndIcons/left-arrow.png";
import fail from "../../assets/logoAndIcons/fail.png";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function renderSwitch(param) {
    switch (param) {
        case "0":
            return 'خطای ناشناخته';
        case "6841":
            return 'پکیج مورد نظر یافت نشد، لطفا به پشتیبانی پیام بدهید.';
        case "84562":
            return 'کاربر مورد نظر یافت نشد. اگر وارد حساب کاربری خود نشده اید ابتدا وارد شوید در غیر این صورت لطفا به پشتیبانی پیام دهید.';
        case "96582":
            return 'درگاه در حال حاضر در دسترس نمی باشد.';
        case "102569":
            return 'اطلاعات ارسال شده ناقص است.';
        case "108554":
            return 'مرچنت کد پذیرنده صحیح نیست.';
        case "125963":
            return 'باتوجه به محدودیت های شاپرک امکان پرداخت با رقم درخواست شده میسر نمی باشد.';
        case "158950":
            return 'سطح تایید پذیرنده پایین تر از سطح نقره ای است.';
        case "186952":
            return 'درخواست مورد نظر یافت نشد.';
        case "198562":
            return 'امکان ویرایش درخواست میسر نمی باشد.';
        case "256985":
            return 'هیچ نوع عملیات مالی برای این تراکنش یافت نشد.';
        case "269856":
            return 'تراکنش ناموفق می باشد.';
        case "326578":
            return 'رقم تراکنش با رقم پرداخت شده مطابقت ندارد.';
        case "336541":
            return 'سقف تقسیم تراکنش از لحاظ تعداد یا رقم عبور نموده است.';
        case "345293":
            return 'اجازه دسترسی به متد مربوطه وجود ندارد.';
        case "412563":
            return 'اطلاعات ارسال شده مربوط به AdditionalData غیر معتبر می باشد.';
        case "425698":
            return 'مدت زمان معتبر طول عمر شناسه پرداخت بین 30 دقیقه تا 45 روز می باشد.';
        case "443658":
            return 'درخواست مورد نظر آرشیو شده است.';
        case "589632":
            return 'عملیات پرداخت موفق بوده و قبلا PaymentVerification تراکنش انجام شده است.';
        case "652136":
            return 'پرداخت ناموفق';
        default:
            return 'خطای ناشناخته';
    }
}


export default function FailedPay(props) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false);
    }
    let history = useHistory();
    function handleClick() {
        history.push("/profile");
    }

    let query = useQuery();
    return (
            <div className={"failRoot"}>
                <div className={"failContainer"}>
                    <div className={"failHeaderContainer"}>
                        <img className={"failHeaderIcon"} src={fail}/>
                        <h1 className={"failH1"}>پرداخت ناموفق</h1>
                    </div>
                    <p>علت:&nbsp;{renderSwitch(query.get("reason"))}</p>
                    <br/>
                    <div className={"failSupporterContainer"}>
                        <img className={"failSupporterImg"} src={support}/>
                        <p className={"failSupporter"} onClick={handleOpen}>ارتباط با پشتیبانی</p>
                    </div>
                </div>
                <button className={"failButton"} onClick={handleClick}>
                    <img className={"failButtonIcon"} src={back}/>
                    <p className={"failButtonP"}>بازگشت به پنل کاربری</p>

                </button>
                <Dialog

                    open={isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {/*<img aria-label="close" className={closeButton} onClick={onClose}>*/}
                        {/*    <CloseIcon className={closeButton}/>*/}
                        {/*</img>*/}
                        <p className={'dialogTitle'}>
                            ارتباط با پشتیبانی
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/*<p className={'DialogContentText'}>*/}
                            {/*    آیا میخواهید از حساب خود خارج شوید؟*/}
                            {/*</p>*/}
                            <div className={"contactDialogCase"}>
                                <div className={"contactDialogT"}>
                                    <img className="contactIcon" src={email}/>
                                    <p>:&nbsp;ایمیل</p>
                                </div>
                                <a className={"contactDialogA"} target="_blank"
                                   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                                    support@molino.ir </a>
                            </div>
                            <div className={"contactDialogCase"}>
                                <div className={"contactDialogT"}>
                                    <img className="contactIcon" src={telegram}/>
                                    <p>:&nbsp;تلگرام</p>
                                </div>
                                <a className={"contactDialogA"} target="_blank"
                                   href="https://t.me/Jobaccount">
                                    @Jobaccount </a>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={"dialogActionS"}>
                        <div className={"dialogBtnContainer"}>
                            <Button onClick={handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    بستن
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>
            </div>
    );
}
