import React from "react";
import './historyStyle.css'
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import Loader from "react-loader-spinner";
import edit from "../../assets/logoAndIcons/edit.png";
import ReactTooltip from "react-tooltip";
import trash from "../../assets/logoAndIcons/trash2.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FilterTransactions from "../FilterTransactions/FilterTransactions";
import momentJalaali from "moment-jalaali";
// import DatePicker from "react-datepicker2";
import {Form} from "react-bootstrap";
import filter from "../../assets/logoAndIcons/filter.png"
import back from "../../assets/logoAndIcons/left-arrow.png"
import download from "../../assets/logoAndIcons/file.png"
// import transitions from "@material-ui/core/styles/transitions";
import MultiSelect from "react-multi-select-component";
import CurrencyInput from "react-currency-input-field";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

// const styles = {}

class History extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            token: this.props.token,
            oathToken: '',
            pageNum: 0,
            type: 0,
            transactions: [],
            hasMore: true,
            selectedTransactionId: -1,
            isOpenDelete: false,
            dialogLoading: false,
            message: '',

            //Edit
            isOpenEdit: false,
            isIncome: true,
            editValue: '',
            editCategory: '',
            editDescription: '',
            dateIsChanged: false,
            editDate: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            valueErr: '',

            //date
            // date: momentJalaali(),
            // from_date: momentJalaali(),
            from_date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            // to_date: momentJalaali(),
            to_date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            from_day: momentJalaali().jDate(),
            from_month: (momentJalaali().jMonth() + 1),
            from_year: momentJalaali().jYear(),
            to_day: momentJalaali().jDate(),
            to_month: (momentJalaali().jMonth() + 1),
            to_year: momentJalaali().jYear(),
            selected_from_date: momentJalaali(),
            selected_to_date: momentJalaali(),

            //filter
            filterPageNum: 0,
            reportType: 0,
            filterTransactions: [],
            filterLoading: false,
            filterHasMore: true,
            filterMode: false,
            incomeCounts: 0,
            outcomeCounts: 0,
            incomeSum: 0,
            outcomeSum: 0,
            filterMessage: '',
            income_categories: [],
            outcome_categories: [],
            selected_income_categories: [],
            selected_outcome_categories: [],
            selectedIReport: [],
            selectedOReport: [],
            excelLoading: false,

            //date
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },


        }

    }

    componentDidMount() {
        //initial request is sent
        this.fetchData();
        // this.oathTokenRequest();
    }

    //callback
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }


    //fetchTransactions
    fetchData = () => {
        const {pageNum, token, accessToken} = this.state;
        const api = "https://api.molino.ir/Site/getAllTransactions?page=" + pageNum;
        axios
            .get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
            .then(res => {
                    if (res.data.result === true) {
                        if (!res.data.transactions.length) {
                            // alert("finish")
                            pageNum === 0 ?
                                this.setState({
                                    hasMore: false,
                                    message: "رکوردی وجود ندارد"
                                })
                                :
                                this.setState({
                                    hasMore: false,
                                })

                        } else {
                            if (pageNum === 0) {
                                res.data.incom_categories.map(category =>
                                    this.setState({
                                        income_categories: [...this.state.income_categories,
                                            {
                                                label: category,
                                                value: category,
                                            }
                                        ],
                                    })
                                )

                                res.data.outcom_categories.map(category =>
                                    this.setState({
                                        outcome_categories: [...this.state.outcome_categories,
                                            {
                                                label: category,
                                                value: category,
                                            }
                                        ],
                                    })
                                )
                                // this.setState({
                                //     selected_income_categories: this.state.income_categories,
                                //     selected_outcome_categories: this.state.outcome_categories,
                                // })
                            }
                            this.setState({
                                //updating data
                                transactions: [...this.state.transactions, ...res.data.transactions],
                                //updating page numbers
                                pageNum: this.state.pageNum + 1
                            })
                        }
                    } else {
                        if (res.data.message === "relogin") {
                            this.handleCallbackReLogin();
                        } else if (res.data.message === "charge") {
                            this.handleCallbackCharge();
                        }
                        // alert(res.data.message)
                    }
                }
            ).catch(function (error) {
            console.log(error);
        });
    };

    //Edit
    edit = (i) => {
        const {filterMode, filterTransactions, transactions} = this.state;
        const selectedTransactionId = Number(i.target.id);
        const list = filterMode ? filterTransactions : transactions;
        this.setState({
            selectedTransactionId: selectedTransactionId,
            editDate: {
                year: Number(list.find(instance => instance.ID === selectedTransactionId).date.split("-")[0]),
                month: Number(list.find(instance => instance.ID === selectedTransactionId).date.split("-")[1]),
                day: Number(list.find(instance => instance.ID === selectedTransactionId).date.split("-")[2]),
            },
            isIncome: list.find(instance => instance.ID === selectedTransactionId).isIncom,
            editValue: list.find(instance => instance.ID === selectedTransactionId).value,
            editCategory: list.find(instance => instance.ID === selectedTransactionId).category,
            editDescription: list.find(instance => instance.ID === selectedTransactionId).description,
            isOpenEdit: true
        })
    }
    confirmEdit = () => {
        const {
            token,
            accessToken,
            selectedTransactionId,
            isIncome,
            editCategory,
            editDescription,
            editValue,
            editDate,
            dateIsChanged,
        } = this.state;
        const api = "https://api.molino.ir/Site/EditTransaction";
        const list = this.state.transactions;

        const body = {
            isIncom: isIncome,
            category: editCategory,
            transactionId: selectedTransactionId,
            value: editValue,
            day: editDate.day,
            month: editDate.month,
            year: editDate.year,
            description: editDescription,
        };
        // alert(body.isIncom+" "+body.transactionId+" "+body.value+" "+body.year+"/"+body.month+"/"+body.day+" "+body.description)

        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        // alert(res.data.result + res.data.message)
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {

                            if (dateIsChanged) {
                                this.setState({
                                    isOpenEdit: false
                                })
                                // this.resetForm();
                                this.reset();
                                // alert("dateIsChanged")
                            } else {
                                list.forEach((instance) => {
                                    if (instance.ID === selectedTransactionId) {
                                        instance.isIncom = isIncome;
                                        instance.value = Number(editValue);
                                        instance.category = editCategory;
                                        instance.date = editDate.year + "-" + editDate.month + "-" + editDate.day;
                                        instance.description = editDescription;
                                    }
                                });
                                this.setState({
                                    transactions: list
                                })

                                this.setState({
                                    isOpenEdit: false
                                })
                                // alert("date is not changed")
                                // this.resetForm();
                            }

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    //Reset
    reset = () => {
        this.setState({
            pageNum: 0,
            type: 0,
            transactions: [],
            hasMore: true,
            loading: false,
            message: '',

        })
        this.state.isDemand ? this.getDemands(1) : this.getDebts(1);
    }

    //Delete
    deleteTransaction = (i) => {
        this.setState({
            selectedTransactionId: Number(i.target.id),
            isOpenDelete: true
        })
    }
    confirmDeleteTransaction = () => {

        const {accessToken, token, selectedTransactionId} = this.state;
        const {filterTransactions, incomeCounts, outcomeCounts, incomeSum, outcomeSum} = this.state;
        const api = "https://api.molino.ir/Site/DeleteTransaction";
        let body = {
            transactionId: selectedTransactionId
        };
        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {

                            filterTransactions.map(transition =>
                                (transition.ID === selectedTransactionId) ?
                                    this.setState({
                                        incomeCounts: (transition.isIncom) ? (incomeCounts - 1) : incomeCounts,
                                        outcomeCounts: (transition.isIncom) ? outcomeCounts : (outcomeCounts - 1),
                                        incomeSum: (transition.isIncom) ? (incomeSum - transition.value) : incomeSum,
                                        outcomeSum: (transition.isIncom) ? outcomeSum : (outcomeSum - transition.value),
                                    })
                                    : '')
                            // this.setState(prevState => ({
                            //     transactions: prevState.transactions.filter((item) => item.ID !== selectedTransactionId),
                            //     filterTransactions: prevState.filterTransactions.filter((item) => item.ID !== selectedTransactionId),
                            //     isOpenDelete: false,
                            // }))
                            //
                            //
                            // if (!this.state.filterTransactions.length) {
                            //     this.setState({
                            //         filterMessage: "هیچ رکوردی یافت نشد",
                            //         filterHasMore: false,
                            //     })
                            // }
                            //
                            // if (!this.state.transactions.length) {
                            //     this.setState({
                            //         message: "رکوردی وجود ندارد",
                            //         hasMore: false,
                            //     })
                            // }
                            this.setState({
                                isOpenDelete: false
                            })
                            this.reset();

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            } else {
                                this.setState({
                                    message: res.data.message
                                })
                            }
                        }

                    })
                .catch(function (error) {
                    console.log(error);
                });

        })
    }

    //CloseDialog
    handleClose = () => {
        this.setState({
            isOpenDelete: false,
            isOpenEdit: false,
        })
    }

    //Filter
    filterMode = () => {
        this.setState({
            filterMode: true,
        })

    }
    filterTransactions = (e) => {
        const {
            token, type, from_date, to_date, accessToken, filterPageNum, filterTransactions, reportType,
            selected_income_categories, selected_outcome_categories, income_categories, outcome_categories,
            selected_from_date, selected_to_date
        } = this.state;
        if (e === 1) {
            this.setState({
                filterTransactions: [],
                filterHasMore: true,
                filterMessage: "",
                selected_from_date: from_date,
                selected_to_date: to_date,
            })
        }
        const page = (e === 1) ? 0 : filterPageNum;
        const from_day = ((e === 1) ? from_date : selected_from_date).day;
        const from_month = ((e === 1) ? from_date : selected_from_date).month;
        const from_year = ((e === 1) ? from_date : selected_from_date).year;
        const to_day = ((e === 1) ? to_date : selected_to_date).day;
        const to_month = ((e === 1) ? to_date : selected_to_date).month;
        const to_year = ((e === 1) ? to_date : selected_to_date).year;
        const typeToSend = (e === 1) ? type : reportType;
        const categories = (typeToSend === 1) ? selected_income_categories : (typeToSend === 2) ? selected_outcome_categories : [];
        const selectedLength = (typeToSend === 1) ? selected_income_categories.length : (typeToSend === 2) ? selected_outcome_categories.length : 0;
        const categoryLength = (typeToSend === 1) ? income_categories.length : (typeToSend === 2) ? outcome_categories.length : 0;
        let api = "https://api.molino.ir/Site/FilterTransactions?page=" + page + "&type=" + typeToSend + "&from_day=" + from_day + "&from_month=" + from_month
            + "&from_year=" + from_year + "&to_day=" + to_day + "&to_month=" + to_month + "&to_year=" + to_year +
            "&category=";
        if (selectedLength !== categoryLength) {
            categories.map(category =>
                api = api + category.value + ","
            )
        }
        this.setState({filterLoading: (e === 1)}, () => {
            axios.get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            filterLoading: false,
                        })
                        // alert("res.data.transactions.length= " + res.data.transactions.length + " page= " + page + " type=" + type)
                        if (res.data.result === true) {
                            if (!res.data.transactions.length) {
                                this.setState({
                                    filterHasMore: false,
                                    // filterPageNum: 0
                                })
                                if (page === 0) {
                                    this.setState({
                                        filterMessage: "هیچ رکوردی یافت نشد",
                                        filterTransactions: [],
                                        reportType: typeToSend,
                                        incomeSum: 0,
                                        outcomeSum: 0,
                                        incomeCounts: 0,
                                        outcomeCounts: 0,
                                        from_day: from_day,
                                        from_month: from_month,
                                        from_year: from_year,
                                        to_day: to_day,
                                        to_month: to_month,
                                        to_year: to_year,
                                        selectedIReport: selected_income_categories,
                                        selectedOReport: selected_outcome_categories,

                                    });
                                    if (typeToSend === 1 && selected_income_categories === income_categories) {
                                        this.setState({
                                            selectedIReport: [{
                                                label: "همه",
                                                value: "همه",
                                            }]
                                        })

                                    } else if (typeToSend === 2 && selected_outcome_categories === outcome_categories) {
                                        this.setState({
                                            selectedOReport: [{
                                                label: "همه",
                                                value: "همه",
                                            }]
                                        })

                                    }
                                }
                            } else {
                                if (page === 0) {
                                    // alert("page=0")
                                    this.setState({
                                        incomeCounts: res.data.incomCounts,
                                        outcomeCounts: res.data.outcomCounts,
                                        incomeSum: res.data.incomSum,
                                        outcomeSum: res.data.outcomSum,
                                        reportType: typeToSend,
                                        from_day: from_day,
                                        from_month: from_month,
                                        from_year: from_year,
                                        to_day: to_day,
                                        to_month: to_month,
                                        to_year: to_year,
                                        selectedIReport: selected_income_categories,
                                        selectedOReport: selected_outcome_categories,
                                    })
                                    if (typeToSend === 1 && selected_income_categories === income_categories) {
                                        this.setState({
                                            selectedIReport: [{
                                                label: "همه",
                                                value: "همه",
                                            }]
                                        })

                                    } else if (typeToSend === 2 && selected_outcome_categories === outcome_categories) {
                                        this.setState({
                                            selectedOReport: [{
                                                label: "همه",
                                                value: "همه",
                                            }]
                                        })

                                    }
                                }

                                this.setState({
                                    //updating data
                                    filterTransactions: (e === 1) ? res.data.transactions : [...filterTransactions, ...res.data.transactions],
                                    //updating page numbers
                                    filterPageNum: (e === 1) ? 1 : filterPageNum + 1,

                                })
                            }

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            } else {
                                alert(res.data.message)
                            }

                        }
                    })
                // })
                .catch(function (error) {
                    console.log(error);
                });
        })
    }

    //Reset
    resetDates = () => {
        this.setState({
            date: momentJalaali(),
            type: 0,
            // selected_income_categories: this.state.income_categories,
            // selected_outcome_categories: this.state.outcome_categories,

        })

    }
    reset = () => {
        const {filterMode} = this.state;
        this.setState({
            pageNum: 0,
            transactions: [],
            hasMore: true,
            message: '',
            filterPageNum: 0,
            filterTransactions: [],
            filterHasMore: true,
            filterMessage: ""
        })

        filterMode ? this.filterTransactions() :
            this.fetchData();
    }

    //Handle Change Input
    changeValue = (value) => {
        if (value) {
            if (value.charAt(0) === '0') {
                this.setState({
                    valueErr: "از مبلغ وارد شده اطمینان دارید؟"
                })
            }
        } else {
            this.setState({
                valueErr: ""
            })
        }
        this.setState({
            editValue: value
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            amountError: ''
        });
    }

    back = () => {
        this.setState({
            filterMode: false,
            filterMessage: ''
        })
    }
    all = () => {
        this.setState({
            type: 0,
        })
    }
    income = () => {
        this.setState({
            type: 1,
        })
    }
    expense = () => {
        this.setState({
            type: 2,
        })
    }
    handleIncomeCategories = (e) => {
        // alert(e[0].value)
        this.setState({
            selected_income_categories: e,
        })
    }
    handleOutcomeCategories = (e) => {
        this.setState({
            selected_outcome_categories: e,
        })
    }

    oathTokenRequest = () => {
        const oathTokenRequestApi = "https://api.molino.ir/Site/RequestOathToken";
        const {token, accessToken} = this.state;
        // let oToken='';
        ///////////////////////////////////////
        const {from_day, from_month, from_year, to_day, to_month, to_year} = this.state;
        const {
            reportType,
            selectedIReport,
            selectedOReport
        } = this.state;
        let selectedI = '';
        let selectedO = '';
        let selectedCategories = '';
        selectedIReport.map(category =>
            selectedI = selectedI + category.value + " ، "
        )
        selectedI = selectedI.slice(0, -3);
        selectedOReport.map(category =>
            selectedO = selectedO + category.value + " ، "
        )
        selectedO = selectedO.slice(0, -3);

        // eslint-disable-next-line no-unused-expressions
        (reportType === 1) ? selectedIReport.map(category =>
            selectedCategories = selectedCategories + category.value + ","
        ) : (reportType === 2) ? selectedOReport.map(category =>
            selectedCategories = selectedCategories + category.value + ","
        ) : '';
        //////////////////////////////////////
        this.setState({
            excelLoading: true,
        }, () => {
            axios.get(oathTokenRequestApi,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            excelLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                oathToken: res.data.oathToken
                            });
                            // oToken=res.data.oathToken;
                            // alert(oToken);
                            // alert(res.data.message);

                            let link = "https://api.molino.ir/api/Files/GetTransactionsExcelFile?oathToken=" + res.data.oathToken +
                                "&type=" + reportType + "&from_day=" + from_day + "&from_month=" + from_month + "&from_year=" + from_year +
                                "&to_day=" + to_day + "&to_month=" + to_month + "&to_year=" + to_year + "&category=" + selectedCategories

                            //window.location.href = link;
                            window.location.assign(link);
                            // window.open(link, "_blank");
                            // return(<a href={link}></a>)
                        } else {

                            //موقت
                            alert(res.data.message)
                        }
                    }
                ).catch(function (error) {
                console.log(error);
            });
        })
    }
    submitForm = (e) => {
        e.preventDefault();
    };

    render() {
        const {transactions, hasMore, isOpenDelete, isOpenEdit, dialogLoading, filterMode, excelLoading} = this.state;
        const {from_day, from_month, from_year, to_day, to_month, to_year} = this.state;
        const {filterTransactions, filterHasMore, type, filterMessage, message, filterLoading} = this.state;
        const {isIncome, income_categories, outcome_categories} = this.state;
        const {
            incomeCounts,
            outcomeCounts,
            incomeSum,
            outcomeSum,
            reportType,
            selectedIReport,
            selectedOReport,
        } = this.state;
        const {editValue, editDescription, editCategory, editDate, valueErr} = this.state;
        const {currency} = this.props;
        const dataLength = transactions.length - 1;
        const filterDataLength = filterTransactions.length - 1;
        let selectedI = '';
        let selectedO = '';
        let selectedCategories = '';
        selectedIReport.map(category =>
            selectedI = selectedI + category.value + " ، "
        )
        selectedI = selectedI.slice(0, -3);
        selectedOReport.map(category =>
            selectedO = selectedO + category.value + " ، "
        )
        selectedO = selectedO.slice(0, -3);

        // eslint-disable-next-line no-unused-expressions
        (reportType === 1) ? selectedIReport.map(category =>
            selectedCategories = selectedCategories + category.value + ","
        ) : (reportType === 2) ? selectedOReport.map(category =>
            selectedCategories = selectedCategories + category.value + ","
        ) : '';

        const list = filterMode ? filterTransactions : transactions;
        return (
            <div className={"historyRoot"}>

                {/*<div>*/}
                {/*    {excelLoading ? <div className={"homeSpinner"}>*/}


                {/*            <div className={"spinnerC"}*/}
                {/*                // style={{ marginTop: '50',marginBottom:'auto', padding:'50'}}*/}
                {/*            >*/}
                {/*                <Loader*/}
                {/*                    type="TailSpin" color="#fff" height="100"*/}
                {/*                    width="100"/>*/}
                {/*            </div>*/}
                {/*            /!*<Loader className={"spinnerH"} type="Circles" color="#ecec44" height="100"*!/*/}
                {/*            /!*        width="100"/>*!/*/}
                {/*        </div>*/}
                {/*        : ''}*/}
                {/*</div>*/}

                <div className={"historyButton"}>
                    <button className={filterMode ? "backButton" : "hidden"}
                            onClick={this.back}>
                        <img className={"backIcon"} src={back}/>
                        <p className={"backP"}>بازگشت</p>
                    </button>
                    <button className={"filterButton"}
                            disabled={!transactions.length && !filterTransactions.length}
                            onClick={FilterTransactions.open("filter")}>
                        <img className={"filterIcon"} src={filter}/>
                        <p className={"filterP"}>فیلتر</p>
                    </button>
                    <a
                        onClick={this.oathTokenRequest}>
                        <button className={filterMode ? "fExcelButton" : "hidden"}
                                disabled={!transactions.length && !filterTransactions.length}
                        >
                            <img className={"filterIcon"} src={download}/>
                            <p className={"fExcelP"}>دانلود اکسل تراکنش ها</p>
                            {excelLoading ? <div className={"filterSpinner"}>
                                <Loader
                                    type="Puff" color="#fff" height="90%"
                                    width="90%"/></div> : ''}
                        </button>
                    </a>
                </div>

                <br/>
                {filterMode ? <div className={"reportContainer"}>
                    <ul className={"reportList"}>
                        <li className={"reportItem"}>
                            از تاریخ:&nbsp;&nbsp;&nbsp;&nbsp;
                            {filterLoading ? '' : (from_year + "/" + from_month + "/" + from_day)}
                        </li>
                        <li className={"reportItem"}>تا تاریخ:&nbsp;&nbsp;&nbsp;&nbsp;
                            {filterLoading ? '' : to_year + "/" + to_month + "/" + to_day}</li>
                    </ul>
                    <ul className={"reportList"}>
                        <li className={"reportItem"}>نوع:&nbsp;&nbsp;&nbsp;&nbsp;
                            {filterLoading ? ' ' : (reportType === 0 ? "همه تراکنش ها" : reportType === 1 ? "دریافتی ها" : "مخارج")}</li>
                        <li className={"reportItem"}>تعداد
                            رکورد:&nbsp;&nbsp;&nbsp;&nbsp;
                            {filterLoading ? '' : (reportType === 0 ? incomeCounts + outcomeCounts : reportType === 1 ? incomeCounts : outcomeCounts)}</li>
                    </ul>
                    <ul className={"reportList"}>
                        <li className={reportType !== 2 ? "reportItem" : "hidden"}>
                            {filterLoading ? ' ' : (reportType === 0 ? "مجموع دریافتی:   " + incomeSum.toLocaleString() + "  (" + incomeCounts + " مورد)" : "مجموع دریافتی:   " + incomeSum.toLocaleString())}
                        </li>

                        <li className={reportType !== 1 ? "reportItem" : "hidden"}>
                            {filterLoading ? ' ' : (reportType !== 0 ? "مجموع مخارج:   " + outcomeSum.toLocaleString() + "  (" + outcomeCounts + " مورد)" : "مجموع دریافتی:   " + incomeSum.toLocaleString())}
                        </li>
                        <li className={reportType !== 0 ? "reportItem" : "hidden"}>
                            دسته بندی:&nbsp;&nbsp;{reportType === 1 ? selectedI : selectedO}
                        </li>
                    </ul>

                </div> : ''}

                <ul className={"historyHeader"}>
                    <li className={"historyHItem"}><p
                        className={"historyHItemP"}>مبلغ</p></li>
                    <li className={"historyHItem"}><p className={"historyHItemP"}>دسته</p></li>
                    <li className={"historyHItem"} style={{flex: 4}}><p className={"historyHItemP"}>توضیحات</p></li>
                    <li className={"historyHItem"}><p className={"historyHItemP"}>تاریخ</p></li>
                    <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>ویرایش</p></li>
                    <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>حذف</p></li>
                </ul>
                {filterMode ?
                    <p className={filterMessage !== '' ? "notfound" : "hidden"}>{filterMessage}</p>

                    : <p className={message !== '' ? "notfound" : "hidden"}>{message}</p>}
                <div>
                    {/*{filterMode ?*/}
                    <InfiniteScroll className={"historyScroll"}
                                    dataLength={filterMode ? filterDataLength : dataLength} //This is important field to render the next data
                                    next={filterMode ? this.filterTransactions : this.fetchData}
                                    hasMore={filterMode ? filterHasMore : hasMore}
                                    loader={<Loader style={{marginTop: 5}}
                                                    type="Oval" color="#4d535e" height="60"
                                                    width="60"/>}>
                        {list.map(transaction => (
                            <ul className="transactionList"
                                // key={transaction.value}
                            >

                                {transaction.isIncom ?
                                    <li className={"transactionItem"} style={{
                                        color: "green", fontWeight: "bold", display: "flex",
                                        flexDirection: "row"
                                    }}
                                    ><p className={"currencyP"} style={{marginRight: 0}}>{currency}&nbsp;
                                    </p>
                                        <p className={"currencyP"} style={{marginLeft: 0}}>
                                            +{transaction.value.toLocaleString()}
                                            {" "}
                                        </p></li>
                                    :

                                    <li className={"transactionItem"} style={{
                                        color: "red", fontWeight: "bold", display: "flex",
                                        flexDirection: "row"
                                    }}
                                    >
                                        <p className={"currencyP"} style={{marginRight: 0}}>{currency}&nbsp;
                                        </p>
                                        <p className={"currencyP"} style={{marginLeft: 0}}>
                                            -{transaction.value.toLocaleString()}
                                            {" "}
                                        </p>
                                    </li>
                                }

                                <li className={"transactionItem"}>{transaction.category}</li>
                                <li className={"transactionItem"}
                                    style={{flex: 4, direction: 'rtl'}}>{transaction.description}</li>
                                <li className={"transactionItem"}>{transaction.date}</li>
                                <li className={"transactionItem"} style={{flex: 1}}>
                                    {/*<div*/}
                                    {/*    className={"transactionItemP"}>*/}
                                    <img className={"HIcon"} src={edit}
                                         id={transaction.ID}
                                         onClick={this.edit}
                                         data-tip data-for="editTip"/>
                                    <ReactTooltip id="editTip" place="top" effect="solid">
                                        <p className={"tooltip"}>
                                            ویرایش
                                        </p>

                                    </ReactTooltip>
                                    {/*</div>*/}
                                </li>
                                <li className={"transactionItem"} style={{flex: 1}}>
                                    <img className={"HIcon"} src={trash}
                                         id={transaction.ID}
                                         onClick={this.deleteTransaction} data-tip
                                         data-for="deleteTip"/>
                                    <ReactTooltip id="deleteTip" place="top" effect="solid">
                                        <p className={"tooltip"}>
                                            حذف
                                        </p>
                                    </ReactTooltip>
                                </li>
                            </ul>
                        ))}
                    </InfiniteScroll>

                </div>
                {/*Delete*/}
                <Dialog
                    open={isOpenDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف تراکنش
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا از حذف این مورد اطمینان دارید؟
                            </p>
                        </DialogContentText>

                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop: 0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmDeleteTransaction} color="primary" disabled={dialogLoading}>
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

                {/*Edit*/}
                <Dialog
                    open={isOpenEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>

                            <p className={'dialogTitle'}>
                                ویرایش {isIncome ? " دریافتی " : " خرج "}
                            </p>
                            <DialogContentText id="alert-dialog-description" className={"MCDialog"}>
                            </DialogContentText>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0", marginLeft:"0"}}
                                > مبلغ: </Form.Label>

                                <CurrencyInput
                                    className={"DDInput"}
                                    id="input-example"
                                    name="editValue"
                                    style={{flex: 4, direction: "ltr"}}
                                    defaultValue={editValue}
                                    onChange={this.changeValue}
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > {currency} </Form.Label>

                            </Form.Group>
                            <div className="DDErrorMsg">{valueErr}</div>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > دسته: </Form.Label>
                                <Form.Control
                                    className={"DDInput"}
                                    // className={"homeInput"}
                                    as="select"
                                    name="editCategory"
                                    value={editCategory}
                                    onChange={this.handleChange}
                                >
                                    {(isIncome ? income_categories : outcome_categories).map(category =>
                                        <option>{category.label}</option>
                                    )}

                                </Form.Control>
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={3}
                                              name="editDescription"
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                              defaultValue={editDescription}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>
                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={editDate}
                                        onChange={date => this.setState({
                                            editDate: date,
                                            dateIsChanged: true,
                                            addingError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="history-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>


                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                        </DialogContent>
                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.confirmEdit} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>

                <FilterTransactions id="filter" filterTransactions={this.filterTransactions}
                                    resetFunc={this.resetDates} filterMode={this.filterMode}
                                    type={reportType}
                    // resetData={this.resetData}
                >
                    <h1>فیلتر</h1>
                    <br/>
                    <br/>
                    {/*<Form className={"homeForm"} id={"signInForm"}*/}
                    {/*      onSubmit={this.submitForm}>*/}
                    <div className={"typeHContainer"}>
                        <Form.Label className={"HLabel"}>نوع:</Form.Label>
                        <div className={"typeHBContainer"}>
                            <button className={type === 1 ? "typeHActive" : "typeH"} onClick={this.income}>دریافتی
                            </button>
                            <button className={type === 0 ? "typeHActive" : "typeH"} onClick={this.all}>همه</button>
                            <button className={type === 2 ? "typeHActive" : "typeH"} onClick={this.expense}>خرج</button>
                        </div>
                    </div>

                    <div className={type === 1 ? "multiSelectContainer" : "hidden"}>
                        <Form.Label className={"HLabel"}>دسته:</Form.Label>
                        <MultiSelect
                            className={"multiSelect"}
                            options={this.state.income_categories}
                            value={this.state.selected_income_categories}
                            onChange={this.handleIncomeCategories}
                            labelledBy={"Select"}
                            disableSearch={true}
                            overrideStrings={{
                                "selectSomeItems": "انتخاب دسته",
                                "allItemsAreSelected": "همه دسته ها انتخاب شدند.",
                                "selectAll": "انتخاب همه",
                                "search": "Search",
                                "clearSearch": "Clear Search"
                            }}
                        />
                    </div>
                    <Form.Group className={'formGroup'}
                                style={{margin: "auto", textAlign: "center", direction: "rtl"}}>
                        <div className={type === 2 ? "multiSelectContainer" : "hidden"}>
                            <Form.Label className={"HLabel"}>دسته:</Form.Label>
                            <MultiSelect
                                className={"multiSelect"}
                                options={this.state.outcome_categories}
                                value={this.state.selected_outcome_categories}
                                onChange={this.handleOutcomeCategories}
                                labelledBy={"SelectOutcome"}
                                disableSearch={true}
                                overrideStrings={{
                                    "selectSomeItems": "انتخاب دسته",
                                    "allItemsAreSelected": "همه دسته ها انتخاب شدند.",
                                    "selectAll": "انتخاب همه",
                                    "search": "Search",
                                    "clearSearch": "Clear Search"
                                }}
                                style={{zIndex:1000}}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className={'formGroup'}
                                style={{margin: "auto", textAlign: "center", direction: "rtl"}}>
                        <Form.Label className={"HLabel"}>از تاریخ:</Form.Label>
                        <div className={"dateH"}>
                            <DatePicker
                                value={this.state.from_date}
                                onChange={date => this.setState({
                                    from_date: date,
                                    // dateIsChanged: true,
                                    // dateError: ''
                                })}
                                shouldHighlightWeekends
                                locale="fa" // add this
                                calendarClassName="responsive-calendar"

                            />
                            {/*<DatePicker*/}

                            {/*    calendarClass={"calendarClass"}*/}
                            {/*    className={"homeInput"}*/}
                            {/*    // className={"historyDateInput"}*/}
                            {/*    // datePickerClass={"datePickerClass"}*/}
                            {/*    // styles={styles}*/}
                            {/*    // calendarContainer={"90%"}*/}
                            {/*    timePicker={false}*/}
                            {/*    onChange={date => this.setState({*/}
                            {/*            // from_year: momentJalaali(date).jYear(),*/}
                            {/*            // from_month: (momentJalaali(date).jMonth() + 1),*/}
                            {/*            // from_day: momentJalaali(date).jDate(),*/}
                            {/*            from_date: date*/}
                            {/*        }*/}
                            {/*    )}*/}
                            {/*    value={this.state.date}*/}
                            {/*    isGregorian={false}/>*/}
                        </div>

                    </Form.Group>

                    <Form.Group className={'formGroup'} style={{textAlign: "center", direction: "rtl"}}>
                        <Form.Label className={"HLabel"}>تا تاریخ:</Form.Label>
                        <div className={"dateH"}>
                            <DatePicker
                                value={this.state.to_date}
                                onChange={date => this.setState({
                                    to_date: date,
                                    // dateIsChanged: true,
                                    // dateError: ''
                                })}
                                shouldHighlightWeekends
                                locale="fa" // add this
                                calendarClassName="responsive-calendar"

                            />
                            {/*<DatePicker*/}
                            {/*    // styles={myStyle}*/}
                            {/*    calendarClass={"calendarClass"}*/}
                            {/*    className={"homeInput"}*/}
                            {/*    // datePickerClass={"datePickerClass"}*/}
                            {/*    // styles= { {calendarContainer: "width:90%" }}*/}
                            {/*    // calendarContainer={"90%"}*/}
                            {/*    timePicker={false}*/}
                            {/*    onChange={date => this.setState({*/}
                            {/*            // to_year: momentJalaali(date).jYear(),*/}
                            {/*            // to_month: (momentJalaali(date).jMonth() + 1),*/}
                            {/*            // to_day: momentJalaali(date).jDate(),*/}
                            {/*            to_date: date*/}
                            {/*        }*/}
                            {/*    )}*/}
                            {/*    value={this.state.date}*/}
                            {/*    isGregorian={false}/>*/}
                        </div>

                    </Form.Group>
                    {/*</Form>*/}


                    <button className={"homeButton backgroundRed"}
                            onClick={FilterTransactions.close('filter')}>انصراف
                    </button>
                    <button
                        // disabled={loading}
                        type="submit"
                        // onClick={this.incomeTransaction}
                        onClick={FilterTransactions.confirm('filter')}
                        className={"homeButton"}
                    >
                        تایید
                    </button>
                </FilterTransactions>

            </div>
        )
    }

}

export default History;