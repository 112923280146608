import React from 'react';
import './faqStyle.css';
import 'react-bootstrap';
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";

class Faq extends React.Component {

    render() {
        return (
            <div className={"faqRoot"}>
                {/*<Menu history={this.props.history}  page={'about'}/>*/}
                <div className={"faqText"}>
                    <h1 className={"aboutHeader"}>سوالات متداول</h1>
                    <div className={"aboutTextP"}>

                        <ul className={"QAndAnsUl"}>
                            <li className={"QAndAnsLi"}>
                                <b>1. با خرید اشتراک قادر به استفاده از چه قابلیت هایی هستیم؟</b><br/>
                                کاربران محترم با خرید اشتراک می توانند به صورت نامحدود و تا پایان زمان اتمام اشتراکشان
                                از تمامی امکانات حسابداری شخصی مالینو مانند ثبت مخارج و دریافتی ها و طلب ها و بدهی ها و
                                نمودار ها و ... استفاده کنند.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>2. اطلاعات دریافت ها و مخارج و ... در کجا و با چه فرمتی ذخیره می شود؟</b>
                                <br/>
                                اگر کاربر اپلیکیشن مالینو هستید اطلاعات شما بر روی تلفن همراهتان
                                و اصطلاحا به صورت local در حافظه داخلی تلفن همراهتان ذخیره می شود
                                و با زدن دکمه پشتیبان گیری از اطلاعات، این اطلاعات به سرور های مالینو منتقل شده
                                و در آن جا نیز ثبت و بروزرسانی می شوند
                                تا با پاک کردن برنامه یا تعویض تلفن همراه و یا خروج از حساب کاربریتان،
                                اطلاعاتتان از بین نرود و با ورود بعدی مجددا به آن ها دسترسی پیدا کنید.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>3. اگر مدت زمان اشتراکم تمام شده باشد، با خروج و سپس ورود مجدد به حساب کاربری اعتبار
                                    حسابم زیاد می شود؟</b>
                                <br/>
                                اشتراک خریداری شده توسط شما، به شماره تلفن شما اختصاص می یابد و
                                با کارهایی از قبیل خروج و ورود یا حذف و نصب مجدد اپلیکیشن تغییر در آن ایجاد نمی شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>4. چرا پس از مدتی برخی اطلاعات ثبت شده پاک شده اند؟</b>
                                <br/>
                                طبق قوانین ذکر شده، مخارج و دریافتی هایی که تاریخشان قبل تر از 3 سال شمسی پیش از امروز
                                باشد
                                و یادآور هایی که تاریخ موعدشان قبل تر از 1 سال شمسی پیش از امروز باشد
                                و طلب و بدهی هایی که تاریخ شان قبل تر از 5 سال شمسی پیش از امروز باشد
                                به صورت اتوماتیک حذف می شوند، علت این امر هم وجود محدودیت های حافظه می باشد.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>5. آیا پس از پایان اشتراک می توانم به اطلاعاتی که ثبت کرده ام دسترسی داشته باشم؟</b>
                                <br/>
                                از نسخه 3.3.0 (انتشار بهمن 1399)
                                به بعد این امکان فراهم شده و چنانچه اشتراک شما پایان یافته باشد و به هر دلیلی نخواهید
                                آن را تمدید کنید می توانید فایل های اکسل اطلاعات ثبت شده موجود در اپلیکیشن را دریافت کنید.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>6. آیا امکان لغو اشتراک وجود دارد؟</b>
                                <br/>
                                خیر مالینو هیچ گونه اقداماتی برای لغو اشتراک کاربران
                                و بازگشت وجه انجام نمی دهد لذا کاربران باید در خرید اشتراک دقت کنند.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>7. آیا امکان استفاده همزمان از یک حساب کاربری در جاهای مختلف وجود دارد؟</b>
                                <br/>
                                خیر، هر حساب کاربری حداکثر توسط یک کاربر قابل دسترسی می باشد و امکان استفاده از یک حساب
                                در چند وسیله وجود ندارد. در صورتی که در یک حساب کاربری دیگری صورت بگیرد کاربر قبلی به
                                صورت اتوماتیک خارج می شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>8. آیا با وارد کردن شماره تلفن برای ثبت نام، هزینه ای از سیم کارت من کم می شود؟</b>
                                <br/>
                                خیر به هیچ عنوان هیچ گونه هزینه اضافی و یا سرویس های به اختصار وس که از سیم کارت اعتبار
                                کم می کنند در مالینو وجود ندارد و این تضمین به کاربران محترم داده می شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>9. در هر روز چند بار مجاز به پشتیبان گیری از طریق اپلیکیشن هستیم؟</b>
                                <br/>
                                حداکثر تعداد دفعات مجاز برای پشتیبان گیری 3 بار در هر 24 ساعت است و اگر
                                بیشتر از این مقدار باشد، مدیریت سیستم صاحب اختیار است تا کاربر خاطی را تعلیق کند.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>10. قصد خرید اشتراک درون اپلیکیشن مالینو را دارم اما با خطای
                                    اشتراک فعال است از سمت بازار مواجه می شوم در این حالت چه اقدامی انجام دهم؟</b>
                                <br/>
                                طبق قوانین کافه بازار،
                                تا زمانی که اشتراک جاری کاربر تمام نشده باشد وی نمی تواند اشتراک جدیدی بخرد، اما
                                اگر در این حالت اجازه ورود به برنامه داده نشد حتما با پشتیبانی ارتباط برقرار کنید.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>11. مدت زمان استفاده رایگان از مالینو چقدر است؟</b>
                                <br/>
                                حساب کاربران جدید پس از ثبت نام، به مدت 3 روز شارژ می شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>12. اگر از حسابم خارج شوم و مجدد وارد شوم باید مجددا اشتراک خریداری کنم؟</b>
                                <br/>
                                خیر اشتراک های خریداری شده به حساب متصل به شماره تلفن شما اضافه می شود
                                لذا با هر چند بار ورود، خروج ، نصب یا حذف برنامه از اعتبار شما کاسته نمی شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>13. رمز عبوری که
                                    برای ورود به اپلیکیشن تنظیم کرده بودم را فراموش کرده ام باید چه کار کنم؟</b>
                                <br/>
                                به دلایل امنیتی بازیابی رمز عبور فقط توسط تیم پشتیبانی و با پروتکل های خاص اجرایی است و
                                فرآیند ساده ای نمی باشد لذا در
                                حفظ آن کوشا باشید و اگر رمز عبور را فراموش کردید با پشتیبانی ارتباط برقرار کنید.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>14. یادآوری به چه صورت انجام می شود؟</b>
                                <br/>
                                یادآوری توسط ارسال sms و نوتیفیکیشن (صرفا برای اپلیکیشن)
                                در روز موعد یادآور و همچنین یک روز قبل از موعد به کاربر انجام می شود.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>15. یادآوری در چه ساعتی انجام می شود؟</b>
                                <br/>
                                یادآوری در ساعت 6 صبح شروع می شود و با توجه به این که در صف ارسال قرار می گیرید،
                                ممکن است پیام یادآوری کمی بعد از ساعت 6 صبح به دست شما برسد.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>16. هنگام ورود به حساب کاربری ام با خطایی مواجه می شوم که اخطار می دهد از طریق
                                    دیگری در حال استفاده از مالینو می باشم، در این حالت چه اقدامی انجام دهم؟</b>
                                <br/>
                                امکان استفاده از یک حساب کاربری در چند دستگاه محتلف وجود ندارد و اگر ورود جدیدی صورت
                                بگیرد، کاربر قبلی به صورت اتوماتیک خارج شده و حتی امکان پشتیبان گیری از اطلاعات را نیز
                                نخواهد داشت، پس باید قبل از ورود کردن از طریق دستگاهی جدید، ابتدا از طریق دستگاه قبلی از
                                اطلاعات خود پشتیبان بگیرید و سپس با دستگاه جدید به حساب خود وارد شوید، اگر این کار را
                                کرده اید و همچنان پیغام خطا را مشاهده می کنید، پیغام را نادیده بگیرید.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>17. آیا مالینو خدماتش را بر بستر وبسایت نیز ارائه می کند؟</b>
                                <br/>
                                هم اکنون وبسایت مالینو در آدرس molino.ir در دسترس می باشد اما استفاده از امکانات موجود
                                در اپلیکیشن در بستر وبسایت در حال حاضر مقدور نیست، گرچه به زودی این امکان نیز فراهم می شود
                                تا کاربران بتوانند از امکانات موجود در اپلیکیشن، در قالب وبسایت هم بهره مند شوند.
                            </li>
                            <li className={"QAndAnsLi"}>
                                <b>18. آیا استفاده از اپلیکیشن مالینو نیاز به اینترنت دارد؟</b>
                                <br/>
                                به طور کلی برای باز کردن
                                اپلیکیشن و هر بار شروع استفاده از آن نیاز است تا اینترنت دستگاه شما روشن باشد اما پس از آن
                                می توانید ثبت اطلاعات گوناگون و استفاده از امکانات مختلف (مانند ثبت دریافتی و بدهی و ...)
                                را بدون داشتن اینترنت انجام دهید، گرچه صفحه یادآور ها نیز به اینترنت نیاز دارد.
                            </li>
                        </ul>
                    </div>
                </div>

                {/*<Footer/>*/}

            </div>
        )
    }
}

export default Faq;
