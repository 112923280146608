import React from 'react';
import './chargeStyle.css';
import 'react-bootstrap';
import axios from "axios";
import discount from "../../assets/logoAndIcons/discount.png"
import back from "../../assets/logoAndIcons/left-arrow.png"
import Loader from "react-loader-spinner";

class Charge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: localStorage.getItem('token'),
            loading: false,
            packages: [],
        }
    }

    componentDidMount() {
        this.getPackages();
    }

    getPackages = () => {
        const api = "https://api.molino.ir/site/getPackages";

        this.setState({loading: true}, () => {
            axios.get(api)
                .then(
                    res => {
                        this.setState({
                            loading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                packages: res.data.packages
                            })
                        } else {
                            alert("false")
                        }

                    })
                .catch(function (error) {
                    console.log(error);
                });

        })
    }

    logout = () => {
        const { data } = this.props.location;
        if (data){
            localStorage.removeItem('token');
        }
        this.props.history.replace({
                pathname: data?'./':'./profile'
            }
        )
    }

    createTable = () => {
        const {packages, token} = this.state;
        const packageLength = packages.length;
        const length = Math.ceil(packageLength / 2);
        let table = []
        let index;
        // Outer loop to create parent
        for (let i = 0; i < length; i++) {
            let children = []
            //Inner loop to create children
            for (let j = 0; j < 2; j++) {
                index=2*i+j;
                children.push(
                    <td className={"chargeCase"}>
                        <a className={"chargeCaseA"}
                           href={"https://api.molino.ir/site/RequestPayment?packageId=" + packages[index].Id + "&userToken=" + token}>

                        <div className={packages[index].hasOff ? "chargeDiscountContainer" : "hidden"}>
                            <img className={"chargeDiscountImg"} src={discount}/>
                            <p className={"chargeDiscount"}>
                                {"%" + packages[index].offPercent}
                                <br/>
                                تخفیف
                            </p>
                        </div>
                        <p className={"shortDescription"}>{packages[index].shortDescription}</p>
                        <p className={"longDescription"}>
                            {packages[index].longDescription}
                        </p>
                        <p className={packages[index].hasOff ? "mainCostLine" : "mainCost"}>
                            قیمت {packages[index].hasOff ? "اصلی" : ""}:&nbsp;{packages[index].mainPrice.toLocaleString()}&nbsp;تومان
                        </p>

                        <p className={"finalCost"}>{packages[index].hasOff ? "قیمت نهایی: " + packages[index].finalPrice.toLocaleString() + " تومان" : ""}</p>
                        {/*<a target="_blank"*/}
                        {/*   href={"https://api.molino.ir/site/RequestPayment?packageId=" + packages[index].Id + "&userToken=" + token}>*/}

                            <button className={"chargeButton"}>فعال سازی</button>
                        </a>


                    </td>)
            }
            //Create the parent and add the children
            table.push(<tr className={"chargeRow"}>{children}</tr>)
        }
        return table
    }


    render() {
        const {loading} = this.state;
        const { data } = this.props.location;
        const text=(data)?"کاربر گرامی از این که مالینو را برای استفاده انتخاب کرده اید متشکریم. با عرض پوزش مدت زمان اشتراک شما به پایان رسیده است، لطفا برای ادامه استفاده از برنامه، یکی از اشتراک های زیر را انتخاب بفرمایید:"
            :
            "کاربر گرامی از این که مالینو را برای استفاده انتخاب کرده اید متشکریم. اشتراک مورد نظر خود را انتخاب بفرمایید:"
        const bottomText="به مبالغ فوق 9% مالیات بر ارزش افزوده اضافه خواهد شد.";
        return (
            <div className={"chargeRoot"}>
                <button className={"chargeBack"} onClick={this.logout}>
                    <img className={"chargeBackIcon"} src={back}/>
                    <p className={"chargeBackP"}>{(data)?"خروج از حساب":"پنل کاربری"}</p>
                </button>


                <div className={"chargeContainer"}>
                    <h1>خرید اشتراک</h1>
                    <p className={"chargeHeader"}>{text}</p>
                    {loading ?
                        <div className={"spinnerC"}
                            // style={{ marginTop: '50',marginBottom:'auto', padding:'50'}}
                        >
                            <Loader
                                type="TailSpin" color="#848383" height="100"
                                width="100"/>
                        </div>
                        :
                        <table className={"chargeTable"}>
                            {this.createTable()}
                        </table>
                    }
                    <p className={"chargeHeader"}>{bottomText}</p>

                </div>


            </div>
        )
    }
}

export default Charge;
