import React, {Component} from "react";
// import list from "../../assets/logoAndIcons/wList.png"
import list from "../../assets/logoAndIcons/list.png"

import "./menuStyle.css"

export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            isSign: (localStorage.getItem('token') !== null)
        };
        // this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu = () => {
        this.setState({menu: !this.state.menu})
    }

    // aboutUs = () => {
    //     if (this.props.page !== 'about') {
    //         this.props.history.replace({
    //                 pathname: './about'
    //             }
    //         )
    //     }
    // }
    // landing = () => {
    //     if (this.props.page !== 'landing') {
    //         this.props.history.replace({
    //                 pathname: './'
    //             }
    //         )
    //     }
    // }
    // contactUs = () => {
    //     if (this.props.page !== 'contact') {
    //         this.props.history.replace({
    //                 pathname: './contact'
    //             }
    //         )
    //     }
    // }
    // sign = () => {
    //     if (this.props.page !== 'sign') {
    //         // alert("token "+localStorage.getItem('token'))
    //         if (localStorage.getItem('token') === null) {
    //             this.props.history.replace({
    //                     pathname: './sign'
    //                 }
    //             )
    //         } else {
    //             this.props.history.replace({
    //                     pathname: './profile'
    //                 }
    //             )
    //         }
    //     }
    // }

    render() {

        const show = (this.state.menu) ? "show" : "";
        const isSign = this.state.isSign;
        return (

            <nav className="navbar navbar-light navbar-expand-lg bg-dark py-lg-4" id="mainNav">
                <div className="menuContainer">


                    <button className="navbar-toggler"
                            aria-controls="navbarResponsive" aria-expanded="false"
                            aria-label="Toggle navigation" type="button" onClick={this.toggleMenu}>
                        <img src={list} className="navbar-toggler-icon"/>
                    </button>

                    <a className="navbar-brand text-uppercase d-lg-none text-expanded"
                        // href="/"

                    >منو</a>

                    <div className={"collapse navbar-collapse " + show} id="navbarResponsive">
                        <ul className="nav navbar-nav mx-auto">
                            <li role="presentation" className="nav-item">
                                <a className="nav-link red"
                                   href={isSign ?"/profile":"/sign"}
                                    // onClick={this.sign}
                                >{isSign ? 'حساب کاربری' : 'ورود/ثبت نام'}</a>
                            </li>

                            <li role="presentation" className="nav-item">
                                <a className="nav-link"
                                   href={"/contact"}
                                    // onClick={this.contactUs}
                                   style={{color: this.props.page === "contact" ? "hsla(50, 80%, 55%, 0.84)" : ""}}
                                >تماس با ما</a>
                            </li>
                            <li role="presentation" className="nav-item">
                                <a className="nav-link"
                                   href={"/about"}
                                    // target="_blank"
                                   style={{color: this.props.page === "about" ? "hsla(50, 80%, 55%, 0.84)" : ""}}
                                    // onClick={this.aboutUs}
                                >درباره ما</a>


                            </li>
                            <li role="presentation" className="nav-item">
                                <a className="nav-link"
                                    // onClick={this.landing}
                                   href={"/"}
                                   style={{color: this.props.page === "landing" ? "hsla(50, 80%, 55%, 0.84)" : ""}}
                                >صفحه اصلی</a></li>


                        </ul>
                    </div>
                </div>
            </nav>

        );
    }
}