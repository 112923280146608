import React from 'react';
import './phoneNumStyle.css';
import 'react-bootstrap';
import {Form} from "react-bootstrap";
import axios from "axios";
import JwModal from "../JwModal/JwModal";
import Loader from 'react-loader-spinner';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

class PhoneNumForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNum: '',
            phoneNumError: '',
            checkBoxError: '',
            checkBox: true,
            loading: false,
            message: '',
            regexp: /^[0-9\b]+$/
        }
    }

    handleChangeChk = () => {
        this.setState(
            {
                checkBox: !this.state.checkBox,
                checkBoxError: ''

            }
        )
    }
    submitForm = (e) => {
        e.preventDefault();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            phoneNumError: ''
        });
    }
    handleChangePhone = (e) => {
        let newPhone = e.target.value;
        if (newPhone==='' || this.state.regexp.test(newPhone)) {
            this.setState({
                [e.target.name]:newPhone
            })
        }
    }


    validateForm = () => {
        let formIsValid = true;
        let phoneNumError = '';
        let checkBoxError = '';
        const {phoneNum} = this.state;
        if (!this.state.checkBox) {
            formIsValid = false;
            checkBoxError = "لطفا قوانین و مقررات را تایید کنید!"

        }
        if (phoneNum === '') {
            formIsValid = false;
            phoneNumError = "لطفا شماره موبایل را وارد کنید!";
        } else if (phoneNum.length < 11) {
            formIsValid = false;
            phoneNumError = "لطفا شماره موبایل را به درستی وارد کنید!";
        } else if (/[a-zA-z]/.test(phoneNum)) {
            formIsValid = false;
            phoneNumError = "لطفا شماره موبایل را به درستی وارد کنید!";
        }
        if (!formIsValid) {
            this.setState(
                {
                    phoneNumError: phoneNumError,
                    checkBoxError: checkBoxError
                }
            )
        }
        if (!formIsValid && checkBoxError === '' && phoneNumError !== '') {
            this.setState(
                {
                    phoneNum: ''
                }
            )
        }
        return formIsValid;
    }

    submit = () => {
        const {loading} = this.state;
        if (this.validateForm() && !loading) {
            const {phoneNum} = this.state;
            const {accessToken} = this.props;
            const api = "https://api.molino.ir/Site/sendCode?phone=" + phoneNum;
            this.setState({loading: true}, () => {
                axios.get(api,
                    {
                        headers: {
                            'accessToken': accessToken
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                loading: false
                            })
                            // alert(res.data.result + " ," + res.data.message);
                            if (res.data.result === true) {
                                if (res.data.message.length > 20) {
                                    this.setState({
                                        message: res.data.message
                                    })
                                } else {
                                    this.phoneNumCallback();
                                }
                            } else {
                                this.setState({
                                    phoneNumError: res.data.message
                                })
                            }
                        })
                    // })
                    .catch(function (error) {
                        console.log(error);
                    });
            })


        }

    };

    phoneNumCallback = () => {
        this.props.parentCallbackPhoneNum(this.state.phoneNum, 2);
        // e.preventDefault();
    }
    handleClose = () => {
        this.phoneNumCallback();
    }

    render() {
        const {loading} = this.state;
        return (
            <div>
                <Form className={"signInBox"} id={"signInForm"}
                      onSubmit={this.submitForm}
                >

                    <br/>
                    <Form.Label className={"label"}> :شماره موبایل خود را وارد کنید </Form.Label>
                    <br/>
                    <Form.Control className={"inputBox"} type="text" placeholder={"09-- ---- ---"}
                                  name="phoneNum"
                                  value={this.state.phoneNum}
                                  onChange={this.handleChangePhone}
                                  maxLength="11"
                                  disabled={loading}
                    />
                    <div className="errorMsg" style={{marginBottom: 5}}>{this.state.phoneNumError}</div>

                    <label className={"label"}>تایید&nbsp;
                        <a className={"signCheck"}
                           // onClick={JwModal.open('custom-modal-2')}
                           href={"/terms"}
                           // target="_blank"
                        >قوانین و مقررات </a>
                    </label>

                    <input className={"checkBox"} type={"checkBox"} id={"rCheckBox"} value="confirmation"
                           defaultChecked={this.state.checkBox} onChange={this.handleChangeChk}
                           disabled={loading}/>

                    <div className="errorMsg">{this.state.checkBoxError}</div>
                    <div className={"spinnerContainer"} style={{width: 100, height: 50}}>

                        {loading ? <Loader className={"spinner"} type="ThreeDots" color="#09C8AB" height="100"
                                           width="100"/> : ''}
                    </div>

                    {/*<br/>*/}
                    <button disabled={loading}
                            type="submit"
                            onClick={this.submit}
                            className={loading ? "signButton inactiveSignButton" : "signButton"}
                    >
                        ارسال
                    </button>

                    <br/>

                </Form>


                <Dialog
                    open={this.state.message !== ''}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            "هشدار"
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                {this.state.message}
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    متوجه شدم
                                </p>
                            </Button>
                        </div>
                        {/*<Button onClick={this.resendCode} color="primary" autoFocus>*/}
                        {/*    ارسال مجدد کد*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>

                <JwModal id="custom-modal-2">
                    <h3

                        style={{color: "red"}}
                    >قوانین و مقررات</h3>
                    <hr/>
                    <ul className={"liText"}>
                        <li>
                            حسابدار مالینو در صورتی که کاربران اقدام به پشتیبان گیری از اطلاعات خود نکنند و این عملیات
                            با موفقیت انجام نشود،
                            هیچ گونه مسئولیتی در قبال حفظ اطلاعات شخصی کاربران نخواهد داشت.
                        </li>
                        <li>
                            کاربران تنها می توانند با استفاده از یک دستگاه (موبایل یا مرورگر)
                            در حساب کاربری خود لاگین کرده باشند و نمی توانند از دو یا بیشتر دستگاه از یک حساب با تلفن
                            همراه یکتا استفاده کنند و
                            در ضورتی که این اتفاق بیفتد اجازه ورود نخواهند داشت.
                        </li>
                        <li>
                            مبالغ پرداختی جهت خرید اشتراک به هیچ وجه از سمت مالینو عودت داده نمی شود لذا در خرید اشتراک
                            دقت لازم را به عمل آورید.
                        </li>
                        <li>
                            مخارج و دریافتی هایی که تاریخ ایجادشان قبل تر از 3 سال پیش از امروز باشد، به صورت اتوماتیک
                            از دسترس خارج شده و حذف می شوند.
                        </li>
                        <li>
                            یادآورهایی که تاریخ موعدشان قبل تر از یک سال پیش از امروز باشد، به صورت اتوماتیک از دسترس
                            خارج وحذف می شوند.
                        </li>
                        <li>
                            طلب و بدهی هایی که تاریخشان قبل تر از 5 سال پیش از امروز باشد، به صورت اتوماتیک از دسترس
                            خارج شده و حذف می شوند.
                        </li>
                        <li>
                            اشتراک های خریداری شده به حساب کاربری که با شماره موبایل شناخته می شوند اختصاص می یابد و
                            کاربران می توانند تا پایان مدت اشتراک حساب کاربریشان، حتی با وجود هرچند بار ورود و خروج از
                            آن استفاده کنند.
                        </li>
                        <li>
                            کاربران با خرید اشتراک می توانند از تمامی امکانات برنامه استفاده کنند و در صورتی که اعتبار
                            زمانی حسابشان پایان یابد، تا شارژ مجدد قادر به ورود به برنامه نخواهند بود.
                        </li>
                        <li>
                            مالینو تضمین می دهد از هیچ گونه سرویس های پرداخت سیم کارت یا به اختصار وس استفاده نمی کند و
                            تنها درگاه مالی بین کاربران و مالینو، پرداخت اینترنتی می باشد.
                        </li>
                        <li>
                            تضمینی برای ثابت بودن قیمت اشتراک ها در طول زمان وجود ندارد.
                        </li>
                        <li>
                            بخش مدیریت صاحب اختیار است که تا کاربرانی که فعالیت هایی انجام می دهند که ایجاد مزاحمت در
                            سامانه تلقی می شود، خلع دسترسی کنند و در این صورت اجازه استفاده از برنامه با ان شماره همراه
                            را نخواهند داشت.
                        </li>
                        <li>
                            پشتیبان گیری بیش از حد مجاز (3 مرتبه در روز) مزاحمت در سامانه تلقی می شود.
                        </li>
                        <li>
                            اطلاعات شخصی کاربران نزد مالینو محفوظ است.
                        </li>
                    </ul>
                    <hr/>
                    <button className={"closeButton"} onClick={JwModal.close('custom-modal-2')}>بستن</button>
                </JwModal>
            </div>
        )
    }
}


export default PhoneNumForm;