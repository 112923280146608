import React from "react";
import "./reminderStyle.css"
import download from "../../assets/logoAndIcons/file.png";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTooltip from "react-tooltip";
import trash from "../../assets/logoAndIcons/trash2.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

////////////
// import Button from '@mui/material/Button';
// import Dialog, {DialogProps} from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

import {Form} from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-modern-calendar-datepicker";
import edit from "../../assets/logoAndIcons/edit.png";
import axios from "axios";
import momentJalaali from "moment-jalaali";

class Reminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accessToken: '34hDFVf9834hJDSV23h03gihnidDFBwjfFDV53',
            token: this.props.token,
            reminders: [],
            allReminders: [],
            isAll: false,
            futureReminders: [],
            isFuture: true,
            todayReminders: [],
            isToday: false,
            pageNum: 0,
            loading: false,
            hasMore: true,
            message: '',
            //Loading
            dialogLoading: false,
            todayLoaded: true,
            futureLoaded: true,
            allLoaded: true,
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            //Dialog
            isOpenDelete: false,
            isOpenAdd: false,
            isOpenEdit: false,

            selectedID: -1,

            //Edit
            editDate: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            dateError: '',
            dateIsChanged: false,
            titleIsChanged: false,
            //
            open: false,
            scroll: 'paper',
        }
    }

    componentDidMount() {
        this.getFutureReminders();
    }

    //callback
    pageCallback = (page) => {
        this.props.parentCallbackPage(page);

    }
    backToHome = () => {
        this.pageCallback("home")
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }

    //Get
    getReminders = (e, time) => {
        const {pageNum, token, accessToken} = this.state;
        const page = (e === 1) ? 0 : pageNum;
        // const {isAll, isFuture, isToday} = this.state;
        const isToday = (time === "today");
        const isFuture = (time === "future");
        const isAll = (time === "all");
        // alert("isToday" + isToday + " - isFuture" + isFuture + " - isAll" + isAll)
        const getReminders = isToday ? "getTodayReminders" : isFuture ? "getFutureReminders" : "getAllReminders";
        const api = "https://api.molino.ir/Reminders/" + getReminders + "?page=" + page;

        this.setState({
            // loading: true,
            todayLoaded: (isToday) ? false : this.state.todayLoaded,
            futureLoaded: (isFuture) ? false : this.state.futureLoaded,
            allLoaded: (isAll) ? false : this.state.allLoaded,
        }, () => {
            axios
                .get(api,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                .then(res => {
                        this.setState({
                            todayLoaded: (isToday) ? true : this.state.todayLoaded,
                            futureLoaded: (isFuture) ? true : this.state.futureLoaded,
                            allLoaded: (isAll) ? true : this.state.allLoaded,
                        })
                        if (res.data.result === true) {
                            if (!res.data.reminders.length) {
                                // alert("finish")
                                page === 0 ?
                                    this.setState({
                                        hasMore: false,
                                        message: "رکوردی وجود ندارد",
                                    })
                                    :
                                    this.setState({
                                        hasMore: false,
                                    })

                            } else {

                                // alert()
                                this.setState({
                                    //updating data
                                    reminders: [...this.state.reminders, ...res.data.reminders],
                                    //updating page numbers
                                    pageNum: this.state.pageNum + 1
                                })
                            }
                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    }
                ).catch(function (error) {
                console.log(error);
            });
        })
    }
    getTodayReminders = (e) => {
        this.getReminders(e, "today");
    }
    getFutureReminders = (e) => {
        this.getReminders(e, "future");
    }
    getAllReminders = (e) => {
        this.getReminders(e, "all");
    };

    //Button
    todayButton = () => {
        this.setState({
            pageNum: 0,
            isToday: true,
            isFuture: false,
            isAll: false,
            hasMore: true,
            message: '',
            reminders: [],
        })
        this.getTodayReminders(1);
    }
    futureButton = () => {
        this.setState({
            pageNum: 0,
            isToday: false,
            isFuture: true,
            isAll: false,
            hasMore: true,
            message: '',

            reminders: [],
            // debts: [],
            // totalValue: '',
            // cleared: '',
            // outstanding: '',
        })
        this.getFutureReminders(1);
    }
    allButton = () => {
        this.setState({
            pageNum: 0,
            isToday: false,
            isFuture: false,
            isAll: true,
            hasMore: true,
            message: '',

            reminders: [],
            // debts: [],
            // totalValue: '',
            // cleared: '',
            // outstanding: '',
        })
        this.getAllReminders(1);
    }

    //Close Dialog
    handleClose = () => {
        this.setState({
            isOpenDelete: false,
            isOpenAdd: false,
            isOpenEdit: false,
        })
        this.resetForm();
    }
    //Adding
    addItem = () => {
        this.setState({
            isOpenAdd: true
        })
    }
    validateAdding = () => {
        let formIsValid = true;
        const {date}=this.state;
        let dateError = '';
        const error="برای اضافه کردن یادآور لازم است تاریخ تعیین شده برای آینده باشد.";
        if (!this.state.checkBox) {
            if (date.year < momentJalaali().jYear()) {
                dateError = error;
                formIsValid=false;
            } else if (date.year === momentJalaali().jYear() && date.month < (momentJalaali().jMonth() + 1)) {
                dateError = error;
                formIsValid=false;
            } else if (date.year === momentJalaali().jYear() && date.month === (momentJalaali().jMonth() + 1) && date.day <= momentJalaali().jDate()) {
                dateError = error;
                formIsValid=false;
            }
        }
        this.setState(
            {
                dateError: dateError,
            }
        )
        return formIsValid;
    }
    addReminder = () => {
        if (this.validateAdding()) {
            const {token, accessToken, date, title} = this.state;
            const body = {
                day: date.day,
                month: date.month,
                year: date.year,
                title: title,
            };

            const api = 'https://api.molino.ir/Reminders/AddReminder';

            this.setState({
                dialogLoading: true,
            }, () => {
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                dialogLoading: false,
                            });
                            if (res.data.result === true) {
                                this.setState({
                                    isOpenAdd: false,
                                });
                                this.reset();

                            } else {
                                if (res.data.message === "relogin") {
                                    this.handleCallbackReLogin();
                                } else if (res.data.message === "charge") {
                                    this.handleCallbackCharge();
                                }
                            }
                            this.resetForm();
                        })
                    .catch(function (error) {
                        console.log(error);
                        // this.setState({
                        //     dialogLoading: false,
                        //     addingError:"مشکلی در اتصال وجود دارد!"
                        // });
                    });
            })
        }
    }

    //Edit
    edit = (i) => {
        const {reminders} = this.state;
        this.setState({
            selectedID: Number(i.target.id),
            date: {
                year: Number(reminders.find(reminder => reminder.ID === Number(i.target.id)).year),
                month: Number(reminders.find(reminder => reminder.ID === Number(i.target.id)).month),
                day: Number(reminders.find(reminder => reminder.ID === Number(i.target.id)).day),
            },
            isOpenEdit: true
        })
    }
    confirmEdit = () => {
        // if (this.validateAdding()) {
            const {
                token,
                accessToken,
                selectedID,
                title,
                date,
                dateIsChanged,
                reminders,
                titleIsChanged
            } = this.state;
            const api = "https://api.molino.ir/Reminders/EditReminder";
            const body = {
                reminderId: selectedID,
                day: (dateIsChanged) ? date.day : reminders.find(reminder => reminder.ID === selectedID).day,
                month: (dateIsChanged) ? date.month : reminders.find(reminder => reminder.ID === selectedID).month,
                year: (dateIsChanged) ? date.year : reminders.find(reminder => reminder.ID === selectedID).year,
                title: (titleIsChanged) ? title : reminders.find(reminder => reminder.ID === selectedID).title,

            };

            this.setState({dialogLoading: true}, () => {
                // alert(body.title + " " + body.year + "/" + body.month + "/" + body.day)
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            this.setState({
                                dialogLoading: false,
                            })
                            if (res.data.result === true) {

                                if (dateIsChanged) {
                                    this.setState({
                                        isOpenEdit: false
                                    })
                                    this.resetForm();
                                    this.reset();
                                } else {
                                    reminders.forEach((reminder) => {
                                        if (reminder.ID === selectedID) {
                                            reminder.date = (dateIsChanged) ? date.year + "-" + date.month + "-" + date.day : reminder.date;
                                            reminder.title = (titleIsChanged) ? title : reminder.title;
                                        }
                                    });
                                    this.setState({
                                        isOpenEdit: false
                                    })
                                    this.resetForm();
                                }

                            } else {
                                // alert("relogin")
                                if (res.data.message === "relogin") {
                                    this.handleCallbackReLogin();
                                } else if (res.data.message === "charge") {
                                    // alert("charge")
                                    this.handleCallbackCharge();
                                }
                            }
                        })
                    .catch(function (error) {
                        console.log(error);
                        // this.setState({
                        //     dialogLoading: false,
                        //     addingError:"مشکلی در اتصال وجود دارد!"
                        // });
                    });
            })
        // }
    }


    //DELETE
    delete = (i) => {
        this.setState({
            selectedID: Number(i.target.id),
            isOpenDelete: true
        })
    }
    confirmDelete = () => {
        const {token, accessToken, selectedID} = this.state;
        const api = "https://api.molino.ir/Reminders/DeleteReminder";
        this.setState({dialogLoading: true}, () => {
            axios.post(api, {
                    reminderId: selectedID
                },
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            this.setState({
                                isOpenDelete: false
                            })
                            this.reset();

                        } else {
                            if (res.data.message === "relogin") {
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.handleCallbackCharge();
                            }
                            // alert(res.data.message)
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                    // this.setState({
                    //     dialogLoading: false,
                    //     addingError:"مشکلی در اتصال وجود دارد!"
                    // });
                });
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            titleIsChanged: true,
        });
    }
    //reset
    reset = () => {
        this.setState({
            pageNum: 0,
            reminders: [],
            hasMore: true,
            loading: false,
            message: '',
            dateError: '',
            title:''
        })
        const {isFuture, isToday} = this.state;
        isToday ? this.getTodayReminders(1) : isFuture ? this.getFutureReminders(1) : this.getAllReminders(1);
    }

    resetForm = () => {
        this.setState({
            dateError: '',
            title: '',
            date: {
                year: momentJalaali().jYear(),
                month: (momentJalaali().jMonth() + 1),
                day: momentJalaali().jDate(),
            },
            dateIsChanged: false,
            titleIsChanged: false,

        })
    }
////Test
    // handleClickOpen = (scrollType) => () => {
    //     this.setState({
    //         open: true,
    //         scroll: scrollType,
    //     })
    //
    //     // setScroll(scrollType);
    // };
    //
    // handleClose = () => {
    //     this.setState({
    //         open: false,
    //     })
    // };
//     const
//     descriptionElementRef = React.useRef < HTMLElement > (null);
//     React
// .
//
//     useEffect(
//
// () => {
//     if(open) {
//         const {current: descriptionElement} = descriptionElementRef;
//         if (descriptionElement !== null) {
//             descriptionElement.focus();
//         }
//     }
// }
//
// ,
// [open]
// )
// ;
    submitForm = (e) => {
        e.preventDefault();
    };

    render() {
        const {
            isAll,
            isFuture,
            isToday,
            reminders,
            hasMore,
            message,
            todayLoaded,
            futureLoaded,
            allLoaded,
            isOpenDelete,
            isOpenAdd,
            // addingValue,
            isOpenEdit,
            selectedID,
            dialogLoading,
            dateError
        } = this.state;
        const dataLength = reminders.length - 1;
        //Test
        // const {open, scroll} = this.state;
        return (
            <div className={"manageCatRoot"}>
                <div className={"MCButtonContainer"}>
                    <button className={(isToday) ? "MCButtonActive" : "MCButton"}
                            onClick={this.todayButton}
                            disabled={(!futureLoaded) || (!allLoaded)}
                    >
                        امروز به قبل
                    </button>
                    <button className={(isFuture) ? "MCButtonActive" : "MCButton"}
                            onClick={this.futureButton} disabled={!todayLoaded || !allLoaded}>
                        فردا به بعد
                    </button>
                    <button className={(isAll) ? "MCButtonActive" : "MCButton"}
                            onClick={this.allButton} disabled={!todayLoaded || !futureLoaded}>
                        همه
                    </button>
                </div>
                <p className={"reminderNote"}>* در روز موعد و همچنین یک روز قبل از آن، sms یادآوری دریافت
                    خواهید کرد.</p>
                <div
                    // className={"MCBody"}
                >
                    <ul className={"transactionList hoverItem"} onClick={this.addItem}>
                        <p className={"MCAddItem"}>+ افزودن یادآور جدید</p>

                    </ul>
                    {/*header*/}
                    <ul className={"historyHeader"}>
                        <li className={"historyHItem"} style={{flex: 4}}><p className={"historyHItemP"}>توضیح</p></li>
                        <li className={"historyHItem"}><p className={"historyHItemP"}>موعد</p></li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>ویرایش</p></li>
                        <li className={"historyHItem"} style={{flex: 1}}><p className={"historyHItemP"}>حذف</p></li>
                    </ul>

                    <p className={message !== '' ? "notfound" : "hidden"}>{message}</p>
                    <div
                        // className={"catList"}
                    >
                        <InfiniteScroll className={"historyScroll"}
                                        dataLength={dataLength} //This is important field to render the next data
                                        next={isToday ? this.getTodayReminders : isFuture ? this.getFutureReminders : this.getAllReminders}
                                        hasMore={hasMore}
                                        loader={<Loader style={{marginTop: 5}}
                                                        type="Oval" color="#4d535e" height="60"
                                                        width="60"/>}>
                            {reminders.map(reminder => (
                                <ul className="transactionList"
                                    // key={transaction.value}
                                >

                                    <li className={"transactionItem"}
                                        style={{flex: 4, direction: 'rtl'}}>{reminder.title}</li>
                                    <li className={"transactionItem"}>{reminder.year + "/" + reminder.month + "/" + reminder.day}</li>
                                    <li className={"transactionItem"}
                                        style={{flex: 1}}
                                    >
                                        {/*<div*/}
                                        {/*    className={"transactionItemP"}>*/}
                                        <img className={"HIcon"} src={edit}
                                             id={reminder.ID}
                                             onClick={this.edit} data-tip
                                             data-for="editTip"/>
                                        <ReactTooltip id="editTip" place="top" effect="solid">
                                            <p className={"tooltip"}>
                                                ویرایش
                                            </p>

                                        </ReactTooltip>
                                        {/*</div>*/}
                                    </li>
                                    <li className={"transactionItem"} style={{flex: 1}}>
                                        <img className={"HIcon"} src={trash}
                                             id={reminder.ID}
                                             onClick={this.delete} data-tip
                                             data-for="deleteTip"/>
                                        <ReactTooltip id="deleteTip" place="top" effect="solid">
                                            <p className={"tooltip"}>
                                                حذف
                                            </p>
                                        </ReactTooltip>
                                    </li>
                                </ul>
                            ))}

                        </InfiniteScroll>


                    </div>


                </div>

                <Dialog
                    open={isOpenDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // style={{padding:5}}
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        <p className={'dialogTitle'}>
                            حذف یادآور
                        </p>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={'DialogContentText'}>
                                آیا از حذف این مورد اطمینان دارید؟
                            </p>
                        </DialogContentText>

                        {/*<div className={"spinnerContainer"} style={{height: 85, marginTop: 0}}>*/}
                        {dialogLoading ?
                            <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="80"
                                    width="80"/> : ''}
                        {/*</div>*/}
                    </DialogContent>
                    <DialogActions>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                <p className={'dialogBtn'}>
                                    انصراف
                                </p>
                            </Button>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button onClick={this.confirmDelete} color="primary" disabled={dialogLoading}>
                                <p className={'dialogBtn'}>
                                    تایید
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>

                {/*//////Add/////////*/}
                <Dialog
                    open={isOpenAdd}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}

                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*<DialogTitle id="alert-dialog-title">*/}
                    {/*    <p className={'dialogTitle'}>*/}
                    {/*        افزودن {(isDemand ? " طلب " : " بدهی ")} جدید*/}
                    {/*    </p>*/}
                    {/*</DialogTitle>*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            <p className={'dialogTitle'}>
                                افزودن یادآور
                            </p>

                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={8}
                                              placeholder={"یادآور پرداخت قسط بانک (الزامی)"}
                                              name="title"
                                              value={this.state.title}
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.date}
                                        onChange={date => this.setState({
                                            date: date,
                                            dateError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"
                                        // styles={{zIndex:1000}}
                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>
                            <div className="DDErrorMsg">{dateError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.addReminder} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>


                <Dialog
                    open={isOpenEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={"paperWidthLg"}
                    fullWidth={true}
                    // style={{position:"absolute"}}
                    // scroll={"paper"}

                >
                    {/*{list.map((instance)=>instance.ID===selectedId?alert(instance.ID+"-"+selectedId):'')}*/}
                    <Form
                        // className={"signInBox"} id={"signInForm"}
                        onSubmit={this.submitForm}
                    >
                        <DialogContent style={{direction: "rtl"}} className={"DDDialogContent"}
                                       onSubmit={this.submitForm}>
                            <p className={'dialogTitle'}>
                                ویرایش یادآور
                            </p>
                            <Form.Group className={'DDFormGroup'}>
                                <Form.Label className={"DDLabel"}
                                    // style={{marginTop: "0", marginBottom: "0"}}
                                > توضیح: </Form.Label>

                                <Form.Control className={"DDInput DDInputDescription"} as="textarea" rows={8}
                                              name="title"
                                              onChange={this.handleChange}
                                              disabled={dialogLoading}
                                              defaultValue={reminders.filter(reminder => reminder.ID === selectedID).map(filtered => filtered.title)}
                                />
                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>
                            </Form.Group>

                            <Form.Group className={'DDFormGroup'}
                                        style={{
                                            margin: "auto", textAlign: "center", direction: "rtl"
                                        }}
                            >
                                <Form.Label className={"DDLabel"}>تاریخ:</Form.Label>

                                <div className={"DDInput"}>
                                    <DatePicker
                                        value={this.state.date}
                                        onChange={date => this.setState({
                                            date: date,
                                            dateIsChanged: true,
                                            dateError: ''
                                        })}
                                        shouldHighlightWeekends
                                        locale="fa" // add this
                                        calendarClassName="responsive-calendar"

                                    />
                                </div>

                                <Form.Label className={"DDLabel"}
                                            style={{marginRight: "2%"}}
                                > </Form.Label>


                            </Form.Group>

                            <div className="DDErrorMsg">{dateError}</div>
                            {/*</DialogContentText>*/}
                            {/*<div className={"spinnerContainer"} style={{height: 85, marginTop:0}}>*/}
                            {dialogLoading ?
                                <Loader className={"spinnerCh DDSpinner"} type="ThreeDots" color="#5e70b5"
                                        height="auto"
                                        width="80"/> : ''}
                            {/*</div>*/}
                        </DialogContent>

                        <DialogActions>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.handleClose} color="primary" disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        انصراف
                                    </p>
                                </Button>
                            </div>
                            <div style={{margin: 'auto'}}>
                                <Button onClick={this.confirmEdit} type="submit" color="primary"
                                        disabled={dialogLoading}>
                                    <p className={'dialogBtn'}>
                                        تایید
                                    </p>
                                </Button>
                            </div>
                        </DialogActions>
                    </Form>
                </Dialog>
                {/*            <Button onClick={this.handleClickOpen('paper')}>scroll=paper</Button>*/}
                {/*            <Button onClick={this.handleClickOpen('body')}>scroll=body</Button>*/}
                {/*            <Dialog*/}
                {/*                open={open}*/}
                {/*                onClose={this.handleClose}*/}
                {/*                scroll={scroll}*/}
                {/*                aria-labelledby="scroll-dialog-title"*/}
                {/*                aria-describedby="scroll-dialog-description"*/}
                {/*            >*/}
                {/*                <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>*/}
                {/*                <DialogContent dividers={scroll === 'paper'}>*/}
                {/*                    <DialogContentText*/}
                {/*                        id="scroll-dialog-description"*/}
                {/*                        // ref={descriptionElementRef}*/}
                {/*                        tabIndex={-1}*/}
                {/*                    >*/}
                {/*                        {[...new Array(50)]*/}
                {/*                            .map(*/}
                {/*                                () => `Cras mattis consectetur purus sit amet fermentum.*/}
                {/*Cras justo odio, dapibus ac facilisis in, egestas eget quam.*/}
                {/*Morbi leo risus, porta ac consectetur ac, vestibulum at eros.*/}
                {/*Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,*/}
                {/*                            )*/}
                {/*                            .join('\n')}*/}
                {/*                    </DialogContentText>*/}
                {/*                </DialogContent>*/}
                {/*                <DialogActions>*/}
                {/*                    <Button onClick={this.handleClose}>Cancel</Button>*/}
                {/*                    <Button onClick={this.handleClose}>Subscribe</Button>*/}
                {/*                </DialogActions>*/}
                {/*            </Dialog>*/}
            </div>
        )
    }
}

export default Reminder;