import React from "react";
import './mainStyle.css'
import Home from "../Home/Home";
import ManageCategories from "../ManageCategories/ManageCategories";
import History from "../History/History";
import DemandDebt from "../DemandDebt/DemandDebt";
import Installment from "../Installment/Installment";
import InsideMenu from "../InsideMenu/InsideMenu";
import Reminder from "../Reminder/Reminder";

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page,
            incomeCat: this.props.income_categories,
            expenseCat: this.props.outcome_categories,
            isIncome: true,
            demandDebt:null,
            isDemand:true,
        }

    }


    handleCallbackToMC = (page, incomeCat, expenseCat, isIncome) => {
        this.setState({
            page: page,
            incomeCat: incomeCat,
            expenseCat: expenseCat,
            isIncome: isIncome,
        })
    }
    handleCallbackInstallment=(demandDebt,isDemand)=>{
        this.setState({
            page: "Installment",
            demandDebt: demandDebt,
            isDemand:isDemand,
        })
    }
    handleCallbackPage = (page) => {
        this.setState({
            page: page
        })
    }
    handleCallbackReLogin = () => {
        this.props.parentCallbackReLogin();
    }
    handleCallbackCharge = () => {
        this.props.parentCallbackcharge();
    }


    render() {
        // const {page}=(this.state.page==="manageCat")?this.state.page:this.props;
        const {page, incomeCat, expenseCat, isIncome, demandDebt, isDemand} = this.state;
        return (
            <div className={"mainRoot"}>

                <InsideMenu page={page} parentCallbackPage={this.handleCallbackPage}/>

                {page === 'home' ? <Home token={this.props.token}
                                         currency={this.props.currency}
                                         parentCallbackToMC={this.handleCallbackToMC}
                                         // parentCallbackPage={this.handleCallbackPage}
                                         parentCallbackReLogin={this.handleCallbackReLogin}
                                         parentCallbackcharge={this.handleCallbackCharge}/>
                    : (page === "manageCat") ?
                        <ManageCategories token={this.props.token}
                                          incomeCat={incomeCat} expenseCat={expenseCat} isIncome={isIncome}
                                          parentCallbackPage={this.handleCallbackPage}
                                          parentCallbackReLogin={this.handleCallbackReLogin}
                                          parentCallbackcharge={this.handleCallbackCharge}/>
                        : (page === "history") ?
                            <History token={this.props.token}
                                     currency={this.props.currency}
                                     income_categories={this.state.incomeCat}
                                     outcome_categories={this.state.expenseCat}
                                     // parentCallbackPage={this.handleCallbackPage}
                                     parentCallbackReLogin={this.handleCallbackReLogin}
                                     parentCallbackcharge={this.handleCallbackCharge}/>
                            : (page === "demand_debt") ?
                                <DemandDebt token={this.props.token}
                                            currency={this.props.currency}
                                            // parentCallbackPage={this.handleCallbackPage}
                                            isDemand={isDemand}
                                            parentCallbackInstallment={this.handleCallbackInstallment}
                                            parentCallbackReLogin={this.handleCallbackReLogin}
                                            parentCallbackcharge={this.handleCallbackCharge}/>
                                : (page === "Installment") ?
                                    <Installment token={this.props.token}
                                                 currency={this.props.currency}
                                                 demandDebt={demandDebt}
                                                 // isDemand={isDemand}
                                                 parentCallbackPage={this.handleCallbackPage}
                                                 parentCallbackReLogin={this.handleCallbackReLogin}
                                                 parentCallbackcharge={this.handleCallbackCharge}
                                    />
                                    : (page === "reminder") ?
                                        <Reminder token={this.props.token}
                                                  currency={this.props.currency}
                                                  // parentCallbackPage={this.handleCallbackPage}
                                                  parentCallbackReLogin={this.handleCallbackReLogin}
                                                  parentCallbackcharge={this.handleCallbackCharge}/> : ''}

            </div>
        )
    }

}

export default Main;