import React from 'react';
import './nameFormStyle.css';
import 'react-bootstrap';
import {Form} from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";

class NameForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            // result: false,
            message: '',
            loading: false,
        }
    };

    submitForm = (e) => {
        e.preventDefault();
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    validateForm = () => {
        let formIsValid = true;
        let message = '';
        const {name} = this.state;
        if (name === '') {
            formIsValid = false;
            message = "لطفا نام خود را وارد کنید!"
        }
        this.setState({
            message: message
        })
        return formIsValid;
    }

    submit = () => {
        const {loading} = this.state;
        if (this.validateForm() && !loading) {
            const {name} = this.state;
            const {accessToken, token} = this.props;
            const api = "https://api.molino.ir/Site/setName";
            let body = {
                name: name
            };
            this.setState({loading: true}, () => {
                axios.post(api, body,
                    {
                        headers: {
                            'accessToken': accessToken,
                            'Authorization': token
                        }
                    })
                    .then(
                        res => {
                            // alert(res.data.result + " ," + res.data.message);
                            this.setState({
                                loading: false,
                            })
                            if (res.data.result === true) {
                                this.props.history.replace({
                                        pathname: '/profile'
                                    }
                                )
                            } else {
                                this.setState({
                                    message: res.data.message
                                })
                            }

                        })
                    .catch(function (error) {
                        console.log(error);
                    });

            })
        }
    }

    render() {
        const {loading} = this.state;
        return (
            <div>
                <Form
                    className="signInBox"
                    id={"signInForm"}
                    onSubmit={this.submitForm}>

                    <br/>
                    <Form.Label className={"label"}> :نام و نام خانوادگی خود را وارد کنید </Form.Label>
                    <br/>
                    <Form.Control className={"inputBox"} type="text"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.handleChange}
                                  disabled={loading}
                    />
                    <div className="errorMsg">{this.state.message}</div>
                    <div className={"spinnerContainer"} style={{width: 1000, height: 50}}>
                        {loading ? <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB" height="100"
                                           width="100"/> : ''}
                    </div>
                    <button disabled={loading}
                            type="submit"
                            onClick={this.submit}
                            className={loading ? "signButton inactiveSignButton" : "signButton"}
                    >
                        تایید
                    </button>
                    <br/>
                </Form>

            </div>
        )
    }
}

export default NameForm;
