import React from 'react';
import Profile from "../../components/Profile/Profile";
import './insideStyle.css'
import axios from "axios";
import Loader from "react-loader-spinner";
import FlashMessage from 'react-flash-message'
import Timer from "react-compound-timer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Main from "../../components/Main/Main";
import email from "../../assets/logoAndIcons/emailBlack.png";
import telegram from "../../assets/logoAndIcons/telegramBlack.png";
import {Form} from "react-bootstrap";
import Transaction from "../../components/Transaction/Transaction";
import phone from "../../assets/logoAndIcons/phone.png"
const time = 0;

class Inside extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phoneNum: '',
            message: '',
            result: true,
            credit: '',
            token: localStorage.getItem('token'),
            accessToken: 'nhDFVf983gnvirtntww98fhnidDFBwjfFDV6bufEB53',
            loading: false,
            logout: false,
            supporter: false,
            currencyIsOPen: false,
            currency: localStorage.getItem("currency"),
            confirmedCurrency: localStorage.getItem("currency"),
            dialogLoading: false,
            reLogin: false,
            charge: false,
            aboutUs: false,
            manageCat: false,
            income_categories:[],
            outcome_categories:[],
        }
    };

    componentDidMount() {
        this.getInfo();
    }

    getInfo = () => {
        const api = "https://api.molino.ir/Site/getInfo";
        const {token} = this.state;
        const {accessToken} = this.state;
        this.setState({loading: true}, () => {
            axios.get(api,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        this.setState({
                            loading: false
                        })
                        // alert(res.data.message)
                        if (res.data.result === true) {
                            this.setState({
                                name: res.data.name,
                                phoneNum: res.data.phone,
                                credit: res.data.credit,
                                result: res.data.result,
                                income_categories:res.incom_categories,
                                outcome_categories:res.outcom_categories,
                            })


                        } else {
                            if (res.data.message === 'charge') {
                                this.setState({
                                    name: res.data.name,
                                    phoneNum: res.data.phone,
                                    credit: res.data.credit,
                                    result: res.data.result,
                                    message: "اشتراک شما پایان یافته است.",
                                })

                            } else {
                                this.setState({
                                    message: "زمان ورود شما منقضی شده است. مجددا وارد شوید.",
                                    result: res.data.result
                                })
                                localStorage.removeItem('token');
                            }


                        }
                    })
                // })
                .catch(function (error) {
                    console.log(error);
                });
        })

    }

    redirect = () => {
        this.props.history.push({
            pathname: (this.state.reLogin) ? './sign' : '/charge',
            data: (this.state.reLogin) ? "" : "true",
        })
    }


    handleCallbackLogout = (childData) => {
        this.setState({
            logout: childData,
        })
    }

    logout = () => {
        localStorage.removeItem('token');
        this.props.history.replace({
                pathname: './'
            }
        )
    }
    handleClose = () => {
        this.setState({
            logout: false,
            supporter: false,
            currencyIsOPen: false,
            aboutUs: false
        })
    }
    handleCallbackSupporter = (childData) => {
        this.setState({
            supporter: childData,
        })
    }
    handleCallbackCurrency = (childData) => {
        this.setState({
            currencyIsOPen: childData,
        })
    }
    handleChange = (e) => {
        this.setState({
            currency: e.target.value,

        });
    }
    changeCurrency = () => {
        const {token, currency, accessToken} = this.state;
        let body = {
            currency: currency
        };
        const api = "https://api.molino.ir/Site/setCurrency";
        this.setState({dialogLoading: true}, () => {
            axios.post(api, body,
                {
                    headers: {
                        'accessToken': accessToken,
                        'Authorization': token
                    }
                })
                .then(
                    res => {
                        // alert(res.data.result + " ," + res.data.message);
                        this.setState({
                            dialogLoading: false,
                        })
                        if (res.data.result === true) {
                            localStorage.setItem("currency", currency);
                            this.setState({
                                currencyIsOPen: false,
                                confirmedCurrency: currency,
                            })
                        } else {
                            if (res.data.message === "relogin") {
                                this.setState({
                                    message: "زمان ورود شما منقضی شده است. مجددا وارد شوید.",
                                    result: res.data.result
                                })

                                localStorage.removeItem('token');
                                this.handleCallbackReLogin();
                            } else if (res.data.message === "charge") {
                                this.setState({
                                    message: "اشتراک شما پایان یافته است.",
                                    result: res.data.result
                                })
                                this.handleCallbackCharge();

                            }

                        }

                    })
                .catch(function (error) {
                    console.log(error);
                });

        })
    }
    handleCallbackReLogin = () => {
        this.setState({
            result: false,
            message: "زمان ورود شما منقضی شده است. مجددا وارد شوید.",
            reLogin: true,
        })
        localStorage.removeItem('token');
    }
    handleCallbackCharge = () => {
        this.setState({
            result: false,
            message: "اشتراک شما پایان یافته است.",
            charge: true,
        })
    }

    handleCallbackManageCat = () => {
        this.setState({
            manageCat: true,
        })
    }
    ///////About
    handleCallbackAboutUs = () => {
        this.setState({
            aboutUs: true,
        })
    }
    handleCallbackBuyCharge = () => {
        this.props.history.push({
            pathname: '/charge',
            data: false,
        })
    }

    render() {
        const {
            name, phoneNum, credit, loading, result, message, logout, supporter,
            currencyIsOPen, currency, dialogLoading, reLogin, charge, aboutUs,manageCat
        } = this.state;
        return (
            <div className={"insideRoot"}>
                {loading ?
                    <div className={"spinnerC"}
                        // style={{ marginTop: '50',marginBottom:'auto', padding:'50'}}
                    >
                        <Loader
                            type="TailSpin" color="#fff" height="100"
                            width="100"/>
                    </div>
                    :
                    <div>
                        {result ?
                            <div className={'insideContainer'}>
                                <Profile
                                    history={this.props.history} name={name} phoneNum={phoneNum} credit={credit}
                                    parentCallbackLogout={this.handleCallbackLogout}
                                    parentCallbackSupporter={this.handleCallbackSupporter}
                                    parentCallbackCurrency={this.handleCallbackCurrency}
                                    parentCallbackcharge={this.handleCallbackBuyCharge}
                                    parentCallbackManageCat={this.handleCallbackManageCat}
                                    parentCallbackAboutUs={this.handleCallbackAboutUs}
                                />

                                <Main token={this.state.token}
                                      currency={this.state.confirmedCurrency}
                                      income_categories={this.state.income_categories}
                                      outcome_categories={this.state.outcome_categories}
                                      parentCallbackReLogin={this.handleCallbackReLogin}
                                      parentCallbackcharge={this.handleCallbackCharge}
                                      page={manageCat?"manageCat":"home"}
                                />

                                <Dialog

                                    open={logout}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"

                                >
                                    <DialogTitle id="alert-dialog-title">
                                        <p className={'dialogTitle'}>
                                            خروج
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <p className={'DialogContentText'}>
                                                آیا میخواهید از حساب خود خارج شوید؟
                                            </p>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div style={{margin: 'auto'}}>
                                            <Button onClick={this.handleClose} color="primary">
                                                <p className={'dialogBtn'}>
                                                    خیر
                                                </p>
                                            </Button>
                                        </div>
                                        <div style={{margin: 'auto'}}>
                                            <Button onClick={this.logout} color="primary">
                                                <p className={'dialogBtn'}>
                                                    بله
                                                </p>
                                            </Button>
                                        </div>
                                    </DialogActions>

                                </Dialog>

                                <Dialog

                                    open={supporter}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    fullWidth={true}
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {/*<img aria-label="close" className={closeButton} onClick={onClose}>*/}
                                        {/*    <CloseIcon className={closeButton}/>*/}
                                        {/*</img>*/}
                                        <p className={'dialogTitle'}>
                                            ارتباط با پشتیبانی
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className={"contactDialogCase"}>
                                                <div className={"contactDialogT"}>
                                                    <img className="contactIcon" src={email}/>
                                                    <p>:&nbsp;ایمیل</p>
                                                </div>
                                                <a className={"contactDialogA"} target="_blank"
                                                   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                                                    support@molino.ir </a>
                                            </div>
                                            <div className={"contactDialogCase"}>
                                                <div className={"contactDialogT"}>
                                                    <img className="contactIcon" src={telegram}/>
                                                    <p>:&nbsp;تلگرام</p>
                                                </div>
                                                <a className={"contactDialogA"} target="_blank"
                                                   href="https://t.me/Jobaccount">
                                                    @Jobaccount </a>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={"dialogActionS"}>
                                        <div className={"dialogBtnContainer"}>
                                            <Button onClick={this.handleClose} color="primary">
                                                <p className={'dialogBtn'}>
                                                    بستن
                                                </p>
                                            </Button>
                                        </div>
                                        {/*<div style={{margin: 'auto'}}>*/}
                                        {/*    <Button onClick={this.logout} color="primary">*/}
                                        {/*        <p className={'dialogBtn'}>*/}
                                        {/*            بله*/}
                                        {/*        </p>*/}
                                        {/*    </Button>*/}
                                        {/*</div>*/}
                                    </DialogActions>

                                </Dialog>
                                <Dialog

                                    open={aboutUs}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    fullWidth={true}
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {/*<img aria-label="close" className={closeButton} onClick={onClose}>*/}
                                        {/*    <CloseIcon className={closeButton}/>*/}
                                        {/*</img>*/}
                                        <p className={'dialogTitle'}>
                                            درباره ما
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <p className={"insideAbout"} style={{color: "black"}}>حسابدار شخصی مالینو
                                                فعالیت خود را با هدف رضایتمندی کاربران از تابستان 1399 آغاز کرد.
                                                امروزه در این شرایط اقتصادی خوب است بدانیم در حال خرج کردن برای چه چیز
                                                هایی هستنیم و به چه کسانی
                                                بدهکاریم و از چه کسانی طلبکاریم.
                                                هدف اصلی مالینو جلب رضایت کاربران و فراهم آوردن محیطی دوستانه و ساده
                                                برای کار با برنامه است.
                                                مالینو هم اکنون فقط روی سیستم عامل اندروید در دسترس است به زودی از طریق
                                                همین سایت قابل دسترسی
                                                خواهد بود.
                                                شما عزیزان می توانید نظرات انتقادات و پیشنهادات خود را جهت بهتر شدن
                                                برنامه از طریق&nbsp;
                                                <a className={"aboutA"} target="_blank"
                                                   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                                                    ایمیل</a>
                                                ،&nbsp;
                                                <a className={"aboutA"} target="_blank" href="https://t.me/Jobaccount">
                                                    تلگرام</a>
                                                &nbsp;و یا
                                                صفحه&nbsp;
                                                <a className={"aboutA"} target="_blank"
                                                   href="https://instagram.com/molino_ir?igshid=kv98nyyd4z8c">
                                                    اینستاگرام</a>
                                                &nbsp;مالینو با ما در میان بگذارید.
                                                فاز های بعدی برنامه با امکاناتی بیشتر از قبل در حال توسعه می باشد با
                                                تشکر از همراهی شما.</p>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={"dialogActionS"}>
                                        <div className={"dialogBtnContainer"}>
                                            <Button onClick={this.handleClose} color="primary">
                                                <p className={'dialogBtn'}>
                                                    بستن
                                                </p>
                                            </Button>
                                        </div>
                                    </DialogActions>

                                </Dialog>
                                <Dialog
                                    open={currencyIsOPen}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    fullWidth={true}
                                    maxWidth={'xs'}
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        <p className={'dialogTitle'}>
                                            واحد پول
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        {/*<DialogContentText id="alert-dialog-description">*/}
                                        {/*    <p className={'DialogContentText'}>*/}
                                        {/*        واحد پول مورد نظر خود را انتخاب کنید*/}
                                        {/*    </p>*/}
                                        {/*</DialogContentText>*/}

                                        <Form.Group className={'formGroup'} style={{direction: "rtl"}}>
                                            <Form.Label className={"homeLabel"}>واحد پول:</Form.Label>
                                            <Form.Control className={"homeInput"} as="select"
                                                          name="currency"
                                                          onChange={this.handleChange}
                                                          value={currency}
                                                          disabled={loading}
                                                          style={{flex: 4}}>

                                                <option className={"homeOptions"}>تومان</option>
                                                <option className={"homeOptions"}>ریال</option>


                                            </Form.Control>
                                            {/*{alert(currency)}*/}
                                        </Form.Group>
                                        <div className={"spinnerContainer"} style={{height: 90}}>
                                            {dialogLoading ?
                                                <Loader className={"spinnerCh"} type="ThreeDots" color="#09C8AB"
                                                        height="80"
                                                        width="80"/> : ''}
                                        </div>
                                    </DialogContent>

                                    <DialogActions>
                                        <div style={{margin: 'auto'}}>
                                            <Button onClick={this.handleClose} color="primary">
                                                <p className={'dialogBtn'}>
                                                    انصراف
                                                </p>
                                            </Button>
                                        </div>
                                        <div style={{margin: 'auto'}}>
                                            <Button onClick={this.changeCurrency} color="primary">
                                                <p className={'dialogBtn'}>
                                                    تایید
                                                </p>
                                            </Button>
                                        </div>
                                    </DialogActions>

                                </Dialog>


                            </div>
                            :
                            <div>
                                <Dialog

                                    open={reLogin}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{fontSize: 10}}
                                    transitionDuration={1000}

                                >
                                    <DialogTitle id="alert-dialog-title">
                                        <p className={'dialogTitle'}>
                                            پایان زمان
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <p className={'DialogContentText'}>
                                                {message}
                                            </p>
                                        </DialogContentText>
                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    open={charge}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{fontSize: 10}}
                                    transitionDuration={1000}
                                    fullWidth={true}
                                    // maxWidth={'sm'}

                                >
                                    <DialogTitle id="alert-dialog-title">
                                        <p className={'dialogTitle'}>
                                            پایان اشتراک
                                        </p>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <p className={'DialogContentText'}>
                                                {message}
                                            </p>
                                        </DialogContentText>
                                    </DialogContent>

                                </Dialog>
                                <Timer
                                    initialTime={time}
                                    checkpoints={[
                                        {
                                            time: 2000,
                                            callback: () => this.redirect(),

                                        },

                                    ]}
                                >
                                </Timer>
                            </div>



                            // <FlashMessage duration={5000}>
                            //     <strong>{message}</strong>
                            // </FlashMessage>
                        }

                    </div>

                }
            </div>
        )
    }
}


export default Inside;