import React from 'react';
import './profileStyle.css';
// import 'react-bootstrap';
import 'react-bootstrap'
import logo from "../../assets/logoAndIcons/logo.png"
import phone from "../../assets/logoAndIcons/phone.png"
import user from "../../assets/logoAndIcons/user3.png"
import time from "../../assets/logoAndIcons/time.png"
import support from "../../assets/logoAndIcons/support.png"
import cash from "../../assets/logoAndIcons/cash.png"
import logout from "../../assets/logoAndIcons/logout.png"
import recharge from "../../assets/logoAndIcons/hand.png"
import list from "../../assets/logoAndIcons/bList.png";
import aboutUs from "../../assets/logoAndIcons/aboutUs.png";
import CSSTransitionGroup from "react-addons-css-transition-group";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: false
        }
    };

    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        if (window.innerWidth > 800) {
            this.setState({profile: true});
        }else {
            this.setState({profile: false});
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    logout = () => {

        this.logOutCallback(true);
    }

    logOutCallback = (e) => {
        this.props.parentCallbackLogout(e);
    }

    supporter = () => {

        this.supporterCallback(true);
    }

    supporterCallback = (e) => {
        this.props.parentCallbackSupporter(e);
    }

    currency = () => {
        this.currencyCallback(true);
    }
    currencyCallback = (e) => {
        this.props.parentCallbackCurrency(e);
    }

    charge = () => {
        this.currencyCallback();
    }
    chargeCallback = () => {
        this.props.parentCallbackcharge();
    }
    manageCat=()=>{
        this.manageCatCallback();
    }

    manageCatCallback = () => {
        this.props.parentCallbackManageCat();
    }
    aboutUs=()=>{
        this.aboutUsCallback();
    }
    aboutUsCallback = () => {
        this.props.parentCallbackAboutUs();
    }
    toggle = () => {
        this.setState({profile: !this.state.profile})

    }

    render() {
        const {name, phoneNum, credit} = this.props;
        const {profile} = this.state;
        // let timestamp = Date.now().valueOf() / 1000;
        //
        // const newCredit = Math.ceil((credit - timestamp) / (86400));
        const show = (profile) ? "show" : "";
        // const show = (this.state.menu) ? "show" : "";

        let profileContent=(profile)?
            <div className={"profileContainer "+show}>

                <div className={"profileHeader"}>
                    <div className={"profileLogoContainer"}>
                        <img
                        className={"profileLogo"}
                        src={logo}
                    />
                    </div>

                    <p className={"pLogo"}> حسابداری شخصی مالینو </p>

                    <div className={"infoList"}>
                        <div
                            className={"infoItem"}>
                            <img
                                className={"infoLogo"}
                                src={phone}
                            />
                            <p className={"infoText"}> تلفن
                                همراه:</p>
                            <p className={"infoData"}> {phoneNum} </p>
                        </div>
                        <div className={"infoItem"}>
                            <img className={"infoLogo"} src={user}/>
                            <p className={"infoText"}> نام
                                و
                                نام
                                خانوادگی:</p>
                            <p className={"infoData"}> {name} </p>
                        </div>
                        <div className={"infoItem"}>
                            <img className={"infoLogo"}
                                // style={{flex: 2}}
                                 src={time}
                            />
                            <p className={"infoText"}> اعتبار
                                حساب:</p>
                            <p className={"infoData"}> {credit+' '}
                                روز </p>

                        </div>
                    </div>
                </div>
                <div className={"profileBody"}>
                    <lu className={"optionList"}>

                        <li className={"optionItem yellow"} onClick={this.chargeCallback}
                            // style={{backgroundColor: 'yellow', backgroundColorHover:'#909090'}}
                        >
                            <img className={"optionLogo"} src={recharge}/>
                            <p
                                className={"optionText"}> خرید
                                اشتراک </p>
                        </li>
                        <li className={"optionItem"} onClick={this.currency}>
                            <img className={"optionLogo"} src={cash}/>
                            <p className={"optionText"}> تغییر واحد پول </p>
                        </li>
                        {/*<li className={"optionItem"} onClick={this.manageCat} >*/}
                        {/*    <img*/}
                        {/*        className={"optionLogo"}*/}
                        {/*        // src={bell}*/}
                        {/*    />*/}
                        {/*    <p className={"optionText"}> مدیریت دسته بندی ها </p>*/}
                        {/*</li>*/}
                        <li className={"optionItem"} onClick={this.aboutUs}>
                            <img
                                className={"optionLogo"}
                                src={aboutUs}
                            />
                            <p className={"optionText"}> درباره ما </p>
                        </li>
                        <li className={"optionItem"} onClick={this.supporter}>
                            <img
                                className={"optionLogo"}
                                src={support}
                            />
                            <p
                                className={"optionText"}>پشتیبانی </p>
                        </li>
                        <li
                            className={"optionItem"}
                            onClick={this.logout}>
                            <img
                                className={"optionLogo"}
                                src={logout}
                            />
                            <p className={"optionText"} >
                                خروج از حساب </p>
                        </li>

                    </lu>

                </div>
            </div>
            :'';



        return (
            <div className={"profileRoot"}>
                <div
                    // style={{direction: 'rtl', textAlign: 'right'}}
                    style={{ textAlign: 'left'}}
                >

                    <button className="toggle"
                            type="button" onClick={this.toggle}>
                        <img src={list} className="toggleIcon"/>
                    </button>
                    <a className="brandText">پروفایل</a>
                </div>

                <CSSTransitionGroup transitionName="Profile" transitionEnterTimeout={1000}
                                    transitionLeaveTimeout={1000}>
                    {profileContent}
                </CSSTransitionGroup>

            </div>


        )

    }
}

export default Profile;
