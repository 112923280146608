import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import "./successPayStyle.css"
import support from "../../assets/logoAndIcons/support.png"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import email from "../../assets/logoAndIcons/emailBlack.png";
import telegram from "../../assets/logoAndIcons/telegramBlack.png";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import back from "../../assets/logoAndIcons/left-arrow.png";
import tick from "../../assets/logoAndIcons/tick.png"


export default function FailedPay(props) {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false);
    }
    let history = useHistory();

    function handleClick() {
        history.push("/profile");
    }


    return (
            <div className={"successRoot"}>
                <div className={"failContainer"}>
                    <div className={"successHeaderContainer"}>
                        <img className={"successHeaderIcon"} src={tick}/>
                        <h1 className={"successH1"}>پرداخت موفق</h1>
                    </div>
                    <p>پرداخت با موفقیت انجام شد.</p>
                    <br/>
                    <div className={"failSupporterContainer"}>
                        <img className={"failSupporterImg"} src={support}/>
                        <p className={"failSupporter"} onClick={handleOpen}>ارتباط با پشتیبانی</p>
                    </div>
                </div>
                <button className={"failButton"} onClick={handleClick}>
                    <img className={"failButtonIcon"} src={back}/>
                    <p className={"failButtonP"}>بازگشت به پنل کاربری</p>

                </button>
                <Dialog

                    open={isOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">
                        {/*<img aria-label="close" className={closeButton} onClick={onClose}>*/}
                        {/*    <CloseIcon className={closeButton}/>*/}
                        {/*</img>*/}
                        <p className={'dialogTitle'}>
                            ارتباط با پشتیبانی
                        </p>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {/*<p className={'DialogContentText'}>*/}
                            {/*    آیا میخواهید از حساب خود خارج شوید؟*/}
                            {/*</p>*/}
                            <div className={"contactDialogCase"}>
                                <div className={"contactDialogT"}>
                                    <img className="contactIcon" src={email}/>
                                    <p>:&nbsp;ایمیل</p>
                                </div>
                                <a className={"contactDialogA"} target="_blank"
                                   href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                                    support@molino.ir </a>
                            </div>
                            <div className={"contactDialogCase"}>
                                <div className={"contactDialogT"}>
                                    <img className="contactIcon" src={telegram}/>
                                    <p>:&nbsp;تلگرام</p>
                                </div>
                                <a className={"contactDialogA"} target="_blank"
                                   href="https://t.me/Jobaccount">
                                    @Jobaccount </a>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={"dialogActionS"}>
                        <div className={"dialogBtnContainer"}>
                            <Button onClick={handleClose} color="primary">
                                <p className={'dialogBtn'}>
                                    بستن
                                </p>
                            </Button>
                        </div>
                    </DialogActions>

                </Dialog>
            </div>

    );
}
