import React from 'react'
import './qAndAnsStyle.css'
import arrowDown from "../../assets/logoAndIcons/arrowDown.png"
import arrowUp from "../../assets/logoAndIcons/arrowUp.png"

import 'react-slidedown/lib/slidedown.css'
import CSSTransitionGroup from 'react-addons-css-transition-group';


class QAndAns extends React.Component {
    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.state = {
            menuActive: false
        };
    }

    toggleMenu() {
        let menuState = !this.state.menuActive;
        this.setState({
            menuActive: menuState
        });
    }

    render() {
        let menu;
        if (this.state.menuActive) {
            menu = <div className={"QAndAnsContainer"}>
                <ul className={"QAndAnsUl"}>
                    <li className={"QAndAnsLi"}>
                        <b>1. با خرید اشتراک قادر به استفاده از چه قابلیت هایی هستیم؟</b><br/>
                        کاربران محترم با خرید اشتراک می توانند به صورت نامحدود و تا پایان زمان اتمام اشتراکشان از تمامی امکانات حسابدار شخصی مالینو مانند ثبت مخارج و دریافتی ها و طلب ها و بدهی ها و نمودار ها و ... استفاده کنند.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>2. آیا امکان لغو اشتراک وجود دارد؟</b>
                        <br/>
                        خیر مالینو هیچ گونه اقداماتی برای لغو اشتراک کاربران و بازگشت وجه انجام نمی دهد لذا کاربران باید در خرید اشتراک دقت کنند.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>3. آیا امکان استفاده همزمان از یک حساب کاربری در گوشی های مختلف وجود دارد؟</b>
                        <br/>
                        خیر، هر حساب کاربری حداکثر توسط یک گوشی قابل دسترسی می باشد و امکان استفاده از یک حساب در چند وسیله وجود ندارد. در صورتی که ورود بر روی گوشی دیگری صورت بگیرد کاربر قبلی به صورت اتوماتیک از برنامه خارج می شود.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>4. آیا اطلاعات من بر روی  سرور به صورت آنلاین ذخیره می شود؟</b>
                        <br/>
                        اطلاعات کاربران بر روی گوشیشان ذخیره می شود و با زدن دکمه پشتیبان گیری از اطلاعات در بخش پروفایل، این اطلاعات به سرور انتقال پیدا می کند. لذا اگر میخواهید گوشی خود را تعویض کنید یا برنامه را پاک کنید و مجدد نصب کنید، حتما باید از اطلاعات خود پشتیبان بگیرید تا برای همیشه روی سرورهای آنلاین ذخیره شود، در غیر این صورت اطلاعات ازبین خواهند رفت.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>5. آیا با وارد کردن شماره تلفن در ابتدای برنامه هزینه ای از سیم کارت کم می شود؟</b>
                        <br/>
                        به هیچ عنوان هیچ گونه هزینه اضافی و یا سرویس های به اختصار وس که از سیم کارت اعتبار کم می کنند در این برنامه وجود ندارد و این تضمین به کاربران محترم داده می شود.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>6. در هر روز چند بار توانایی پشتیبان گیری داریم؟</b>
                        <br/>
                        حداکثر تعداد دفعات مجاز برای پشتیبان گیری 3 بار در هر 24 ساعت است و اگر بیشتر از این مقدار باشد، مدیریت سیستم صاحب اختیار است تا کاربر خاطی را تعلیق کند.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>7. قصد خرید اشتراک دارم اما با خطای اشتراک فعال است از سمت بازار مواجه می شوم در این حالت چه اقدامی انجام دهم؟</b>
                        <br/>
                        طبق قوانین کافه بازار، تا زمانی که اشتراک جاری کاربر تمام نشده باشد وی نمی تواند اشتراک جدیدی بخرد، اما اگر در این حالت اجازه ورود به برنامه داده نشد حتما با پشتیبانی ارتباط برقرار کنید.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>8. مدت زمان استفاده رایگان از برنامه چقدر است؟</b>
                        <br/>
                        حساب کاربران جدید پس از ثبت نام به مدت 3 روز شارژ می شود، همچنین کاربران قبلی نیز به هنگام به روز رسانی حسابشان 3 روز شارژ می شود.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>9. اگر از حسابم خارج شوم و مجدد وارد شوم باید مجددا اشتراک خریداری کنم؟</b>
                        <br/>
                        خیر اشتراک های خریداری شده به حساب متصل به شماره تلفن شما اضافه می شود لذا با هر چند بار ورود، خروج ، نصب یا حذف برنامه از اعتبار شما کاسته نمی شود.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>10. رمز عبوری که برای ورود تنظیم کرده بودم را فراموش کرده ام باید چه کار کنم؟</b>
                        <br/>
                        به دلایل امنیتی بازیابی رمز عبور فقط توسط تیم پشتیبانی و با پروتکل های خاص اجرایی است و فرآیند ساده ای نمی باشد لذا در حفظ آن کوشا باشید و اگر رمز عبور را فراموش کردید با پشتیبانی ارتباط برقرار کنید.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>11. یادآوری به چه صورت انجام می شود؟</b>
                        <br/>
                        یادآوری توسط ارسال sms و نوتیفیکیشن در روز موعد یادآور و همچنین یک روز قبل از موعد به کاربر انجام می شود.
                    </li>
                    <li className={"QAndAnsLi"}>
                        <b>12. یادآوری در چه ساعتی انجام می شود؟</b>
                        <br/>
                        یادآوری در ساعت 6 صبح شروع می شود و با توجه به این که در صف ارسال قرار می گیرید، ممکن است نوتیفیکیشن یادآوری کمی بعد از ساعت 6 صبح به دست شما برسد.
                    </li>
                </ul>
            </div>
        } else {
            menu = "";
        }
        return (
            <div className="QAndAns">
                <div className={"qAndAnsHeader"}>
                    <img className="qAndAnsIcon"
                         src={this.state.menuActive?arrowUp:arrowDown}
                         onClick={this.toggleMenu}/>
                         <p className={"qAndAnsHeaderText"}>
                             سوالات متداول
                         </p>
                </div>
                <CSSTransitionGroup transitionName="QAndAns" transitionEnterTimeout={1000}
                                    transitionLeaveTimeout={1000}>
                    {menu}
                </CSSTransitionGroup>
            </div>
        )
    }
}


export default QAndAns;

// // let CSSTransitionGroup = React.addons.CSSTransitionGroup;
// export default function QAndAns(props) {
//     return (
//         <SlideDown className={'my-dropdown-slidedown'}>
//             {props.open ? props.children : null}
//         </SlideDown>
//     )
// }