import React from 'react';
import './termsStyle.css';
import 'react-bootstrap';
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";

class Terms extends React.Component {

    render() {
        return (
            <div className={"termsRoot"}>
                {/*<Menu history={this.props.history}  page={'about'}/>*/}
                <div className={"faqText"}>

                    <h1 className={"aboutHeader"}>قوانین و مقررات حسابداری شخصی مالینو</h1>
                    <div className={"aboutTextP"}>
                        <div className={"termB"}>
                            حتما قبل از استفاده از حسابداری شخصی مالینو قوانین
                                زیر را با دقت مطالعه کنید، مسئولیت عدم مطالعه قوانین قبل از استفاده از مالینو بر عهده
                                کاربر است.
                        </div>
                        <dl className={"termsDl"}>
                            <dt className={"termsDt"}>
                                <b>ماده 1 : مقدمه و تعاریف</b>
                            </dt>
                            <dd>
                                الف- حسابداری شخصی مالینو: حسابداری شخصی مالینو ابزاری است برای مدیریت مخارج و طلب ها و
                                بدهی های روزمره و ... که از این پس با نام مالینو شناخته می شود.
                            </dd>
                            <dd>
                                ب- کاربر: اشخاصی که از خدمات حسابداری شخصی مالینو استفاده می کنند.
                            </dd>
                            <dd>
                                ج- خدمات: عبارت است از خدمات مربوط به مدیریت مخارج و طلب ها و بدهی های روزمره و ... که
                                در ازای خرید اشتراک به کاربر ارائه میگردد.
                            </dd>
                            <dd>
                                ه- اشتراک : مدت زمانی است که کاربر میتواند از تمام امکانات حسابداری شخصی مالینو استفاده
                                کند.
                            </dd>
                            <dt className={"termsDt"}>
                                <b>ماده2 : نحوه استفاده از خدمات:</b>
                            </dt>
                            <dd>
                                1. کاربران تنها می توانند در تنها یک دستگاه در حساب کاربری
                                خود لاگین کرده باشند و نمی توانند از دو یا بیشتر دستگاه از یک حساب با تلفن همراه
                                یکتا استفاده کنند و در صورتی که این اتفاق بیفتد دیگر اجازه ورود به مالینو در دستگاه
                                اولی را نخواهند داشت و به صورت اتوماتیک از آن دستگاه به بیرون رانده می شوند،
                                همچنین در صورتی که در دستگاه اولی از اطلاعات خود پشتیبان نگرفته باشند اطلاعتشان
                                نیز از بین خواهد رفت، گرچه این امر در بدو ورود در دستگاه دوم به عنوان اخطار گفته می شود.
                            </dd>
                            <dd>
                                2. مبالغ دریافتی جهت خرید اشتراک به هیچ وجه از سمت حسابداری شخصی مالینو عودت داده نمی
                                شوند لذا در خرید اشتراک دقت لازم را به عمل آورید.
                            </dd>
                            <dd>
                                3. مخارج و دریافتی هایی که تاریخشان قبل تر از 3 سال شمسی پیش از امروز باشد، به صورت
                                اتوماتیک از دسترس خارج شده و حذف می شوند و کاربر قادر به مشاهده آن ها نخواهد بود. (به
                                عنوان مثال اگر امروز 10 بهمن سال 1399 باشد، اگر دریافتی یا خرجی قبل تر از 10 بهمن 1396
                                داشته باشید، به صورت اتوماتیک حذف خواهد شد.)
                            </dd>
                            <dd>
                                4. یادآور هایی که تاریخ موعدشان قبل تر از 1 سال شمسی پیش از امروز باشد، به صورت اتوماتیک
                                از دسترس خارج شده و حذف می شوند و کاربر قادر به مشاهده آن ها نخواهد بود.
                            </dd>
                            <dd>
                                5. طلب و بدهی هایی که تاریخ شان قبل تر از 5 سال شمسی پیش از امروز باشد، به همراه
                                اقساطشان و به صورت اتوماتیک از دسترس خارج شده و حذف می شوند و کاربر قادر به مشاهده آن ها
                                نخواهد بود.
                            </dd>
                            <dd>
                                6. اشتراک های خریداری شده به حساب کاربری که با شماره موبایل شناخته می شوند اختصاص می
                                یابد و کاربران می توانند تا پایان مدت اشتراک حساب کاربری شان، حتی با وجود با هر چند بار
                                نصب یا حذف برنامه و ورود و خروج از آن استفاده کنند، همچنین خروج و ورود مجدد به حساب
                                کاربری و یا اقداماتی همچون حذف و نصب مجدد اپلیکیشن حسابداری شخصی مالینو تغییری در میزان
                                اشتراک شما حاصل نمی کند چرا که اشتراکات به تلفن همراه اختصاص یافته می شوند.
                            </dd>
                            <dd>
                                7. کاربران با ثبت نام در حسابداری
                                شخصی مالینو، 3 روز اعتبار رایگان دریافت می کنند و پس از آن می توانند با خرید اشتراک از تمامی امکانات
                                حسابداری شخصی مالینو استفاده کنند و در صورتی که اعتبار زمانی حسابشان پایان یابد، تا شارژ مجدد حسابشان و
                                خرید یا تمدید اشتراک قادر به ورود و یا استفاده از هر کدام از امکانات حسابداری شخصی مالینو نخواهند بود.
                            </dd>
                            <dd>
                                8. حسابداری شخصی مالینو تضمین می دهد از هیچ گونه سرویس های پرداخت سیم کارت یا به اختصار وس استفاده نمی کند.
                            </dd>
                            <dd>
                                9. تضمینی برای ثابت بودن قیمت اشتراک ها در طول زمان وجود ندارد.
                            </dd>
                            <dd>
                                10. کاربرانی که از اپلیکیشن حسابداری شخصی مالینو استفاده می کنند چنانچه می خواهند
                                اطلاعاتشان نزد حسابداری شخصی مالینو ذخیره شود تا بتوانند با ورود های بعدی به آن دسترسی
                                داشته باشند، باید قبل از تعویض تلفن همراه خود و یا خروج از اپلیکیشن، اقدام به پشتیبان
                                گیری از اطلاعات کنند و این عملیات با موفقیت انجام شود، در غیر این صورت حسابداری شخصی
                                مالینو هیچ گونه مسوولیتی در قبال حفظ اطلاعات شخصی کاربران نخواهد داشت.
                            </dd>
                            <dd>
                                11. بخش مدیریت صاحب اختیار است تا کاربرانی که فعالیت هایی انجام می دهند که ایجاد مزاحمت
                                در سامانه تلقی می شود، خلع دسترسی کنند و در این صورت اجازه استفاده از برنامه با آن شماره
                                همراه را نخواهند داشت.
                            </dd>
                            <dd>
                                12. تیم پشتیبانی مسوولیتی در قبال فراموشی رمز عبور ورود به برنامه توسط کاربر ندارد و
                                بازیابی درصورت صلاحدید پشتیبانی انجام می گیرد لذا کاربران باید در حفظ آن کوشا باشند.
                            </dd>
                            <dd>
                                13. پشتیبان گیری بیش از حد مجاز (3 مرتبه در روز)، توهین به تیم پشتیبانی و حمله به سامانه
                                مزاحمت تلقی می شود.
                            </dd>
                            <dd>
                                14. امکان تغییر قوانین وجود دارد و کاربر موظف است همیشه هنگام استفاده از حسابداری شخصی
                                مالینو بر شرایط و قوانین حسابداری شخصی مالینو واقف باشد و استفاده از حسابداری شخصی
                                مالینو در هرلحظه به معنای پذیرش توضیحات و قوانین است.
                            </dd>
                            <dd>
                                15. اطلاعات شخصی کاربران نزد حسابداری شخصی مالینو محفوظ است.
                            </dd>

                        </dl>
                    </div>
                </div>

                {/*<Footer/>*/}

            </div>
        )
    }
}

export default Terms;
