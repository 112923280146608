import React from 'react';
import './aboutStyle.css';
import 'react-bootstrap';
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";

class About extends React.Component {



    render() {
        return (
            <div className={"aboutRoot"}>
                <Menu history={this.props.history}  page={'about'}/>
                <div className={"aboutText"}>
                    <h1 className={"aboutHeader"}>درباره حسابدار شخصی مالینو</h1>
                    <p className={"aboutTextP"}>حسابدار شخصی مالینو فعالیت خود را با هدف رضایتمندی کاربران از تابستان 1399 آغاز کرد.<br/>
                        امروزه در این شرایط اقتصادی خوب است بدانیم در حال خرج کردن برای چه چیز هایی هستنیم و به چه کسانی
                        بدهکاریم و از چه کسانی طلبکاریم.<br/>
                        هدف اصلی مالینو جلب رضایت کاربران و فراهم آوردن محیطی دوستانه و ساده برای کار با برنامه است.
                        مالینو هم اکنون روی سیستم عامل اندروید و از طریق همین سایت در دسترس است.<br/>
                        شما عزیزان می توانید نظرات انتقادات و پیشنهادات خود را جهت بهتر شدن برنامه از طریق&nbsp;
                        <a className={"aboutA"} target="_blank" href="mailto:support@molino.ir?subject=Feedback%20for%20Molino">
                             ایمیل</a>
                        ،&nbsp;
                        <a className={"aboutA"} target="_blank" href="https://t.me/Jobaccount">
                            تلگرام</a>
                        &nbsp;و یا
                        صفحه&nbsp;
                        <a className={"aboutA"} target="_blank" href="https://instagram.com/molino_ir?igshid=kv98nyyd4z8c">
                            اینستاگرام</a>
                        &nbsp;مالینو با ما در میان بگذارید.<br/>
                        فاز های بعدی برنامه با امکاناتی بیشتر از قبل در حال توسعه می باشد با تشکر از همراهی شما.</p>
                </div>

                <Footer history={this.props.history}/>

            </div>
        )
    }
}

export default About;
